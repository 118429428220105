<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" v-show="$route.params.fromFindCode === 'true'" @click="$router.push({ name: 'FindCode' })"><i class="icon-back"></i></button>
      <button type="button" class="btn btn-back" v-show="$route.params.fromMypage === 'true'" @click="$router.push({ name: 'PhoneNumberChange' })"><i class="icon-back"></i></button>
      <button type="button" class="btn btn-back" v-show="$route.params.firstNice === 'true'" @click="$router.push({ name: 'Login' })"><i class="icon-back"></i></button>
      <p>휴대폰 인증</p>
    </header>
    <div class="auth-form d-table">
      <h3>휴대폰 인증</h3>
      <p class="mb-20">
        * 회원님의 명의로 등록된 휴대폰으로 본인확인 후<br/>
        &nbsp;&nbsp;&nbsp;휴대폰으로 매장코드를 전송합니다.
      </p>
      <button class="btn btn-pink" @click="certify">본인명의 휴대폰으로 인증</button>
    </div>
    <form name="form_chk" method="post" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb">
      <input type="hidden" name="m" value="checkplusSerivce">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "certification",
  data() {
    return {
      niceCode: '',
      encData: '',
      phone: '',
      shopCode: '',
    }
  },
  methods: {

    fnPopup(encData) {
      window.removeEventListener('message', this.niceDone)

      window.name ="Parent_window"
      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no')
      document.getElementById('nice-enc').value = encData
      document.form_chk.target = "popupChk"
      document.form_chk.submit()

      window.addEventListener('message', this.niceDone);
    },

    certify() {
      axios.post("https://nice.reappay.net/check-pay-plus/request", null, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
      }).then(response => {
        console.log(response)
        if (response.data.success === true) {
          this.encData = response.data.enc_data
          this.fnPopup(this.encData)
        } else {
          console.log(response.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    },

    niceDone (e) {
      if(e.origin === 'https://nice.reappay.net') {
        this.phone = e.data.phone_no
        // this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.niceCode = e.data.payload
        window.removeEventListener('message', this.niceDone)
        if(this.$route.params.fromMypage === 'true' || this.$route.params.firstNice === 'true') {
          this.firstNice()
        } else {
          this.findCode()
        }
      }
    },

    firstNice() {
      let formData = new FormData
      formData.append('niceCode', this.niceCode)
      this.$post(this.$SHOP_AUTH_NICE, 'authNice_certification', formData, true, (result) => {
        console.log('reset pwd or first nice', result)
        this.notify('success', '본인 인증 성공')
        localStorage.setItem('auth', 'true')
        this.$router.push({ name: 'Dashboard' }).catch(()=>{})
      }, (result) => {
        console.log(result)
      })
    },

    findCode() {
      let formData = new FormData
      formData.append('niceCode', this.niceCode)

      this.$post(this.$SHOP_FIND_CODE, 'findCode_Certification',  formData, false,(result) => {
        console.log(result)
        this.shopCode = result.body.data.code
        this.notify('success', '본인 인증 성공')
        localStorage.setItem('auth', 'true')
        this.$router.push({ name: 'FindCodeResult', params: { shopCode: this.shopCode }}).catch(()=>{})
      }, (result) => {
        console.log(result)
      })
    }

  }
}
</script>

<style scoped>

</style>
