<template>
  <div class="all-content">
    <Lnb v-show="showLnb" @closeLnb="showLnb = false"></Lnb>
    <AlarmList v-show="showAlarm" @closeAlarm="showAlarm = false"></AlarmList>
    <Header title="상품관리" @openLnb="this.openLnb" @openAlarm="this.openAlarm"></Header>
    <!--    상품이 없을 때-->
    <NoContent v-show="noContent" title="상품이 없습니다!"></NoContent>
    <!--    상품이 있을 때-->
    <div class="dashboard-wrap product-dashboard scroll" v-show="showOrderRequest">
      <Modal :askConfirmTxt=askConfirmTxt v-if="openModal" @close="openModal = false" @confirm = "confirm"></Modal>
      <div class="product-content-list">
        <div class="content-box" v-for="(item, idx) in productsArr" :key="item.id"
             @click="goDetail(productsArr[idx].id)">
          <div class="checks product-box-name" @click.stop="colorBorder($event, item)">
            <input type="checkbox">
            <label>{{ item.name }}</label>
          </div>
          <div class="product-box">
            <span>판매여부</span>
            <p class="toggle-group">
              <span class="btn-toggle" :class="{ on: item.state === true, off: item.state === false }" @click.stop="toggle($event, item.id)"></span>
            </p>
          </div>
          <div class="product-box">
            <span>재고수량</span>
            <p>{{ item.quantity === null ? 0 : item.quantity | numberWithCommas }}개</p>
          </div>
          <div class="product-box">
            <span>가격</span>
            <p>{{ item.price | numberWithCommas }}원</p>
          </div>
          <div class="product-box">
            <span>카테고리</span>
            <p>
              <span v-show="item.categories.length === 1" class="badge" :class="{
                'badge-food': item.categories[0] === '먹거리',
                'badge-restaurant': item.categories[0] === '음식점',
                'badge-livestock': item.categories[0] === '축산',
                'badge-vegetable': item.categories[0] === '야채',
                'badge-fruit': item.categories[0] === '과일',
                'badge-fish': item.categories[0] === '수산',
                'badge-ricecake': item.categories[0] === '떡/방앗간',
                'badge-driedfish': item.categories[0] === '건어물',
                'badge-sidedish': item.categories[0] === '반찬',
                'badge-household-goods': item.categories[0] === '생활용품',
                'badge-healthfood': item.categories[0] === '건강식품',
                'badge-flower': item.categories[0] === '화훼',
                'badge-garden': item.categories[0] === '원예'}
               ">{{ item.categories[0] }}</span>
              <span v-show="item.categories.length === 2" class="badge" v-for="(cate, cateIdx) in item.categories" :key="cateIdx" :class="{
                'badge-food': cate === '먹거리',
                'badge-restaurant': cate === '음식점',
                'badge-livestock': cate === '축산',
                'badge-vegetable': cate === '야채',
                'badge-fruit': cate === '과일',
                'badge-fish': cate === '수산',
                'badge-ricecake': cate === '떡/방앗간',
                'badge-driedfish': cate === '건어물',
                'badge-sidedish': cate === '반찬',
                'badge-household-goods': cate === '생활용품',
                'badge-healthfood': cate === '건강식품',
                'badge-flower': cate === '화훼',
                'badge-garden': cate === '원예',
                'badge-sinnago': cate === '신나고'}
               ">{{ cate }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="fnb-btn-group">
      <button class="darkgray" @click="openModal = true" v-show="!noContent">상품삭제</button>
      <button @click="goDetail(null)" :class="{ 'full-width': noContent }">상품추가</button>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/commonjs/mixin"

export default {
  name: "ProductSetup",
  mixins: [mixin],
  data() {
    return {
      noContent: false,
      showOrderRequest: true,
      showLnb: false,
      showAlarm: false,
      productsArr: '',
      productId: [],
      askConfirmTxt: '선택하신 상품을 삭제하시겠습니까?',
      openModal: false,
    }
  },

  mounted() {
    this.getProduct()
  },

  methods: {
    getProduct() {
      this.$get(this.$SHOP_PRODUCT, 'product_productSetup', true,(result) => {
        this.productsArr = result.data
        console.log('productArr', this.productsArr)
        if(this.productsArr.length === 0) {
          this.noContent = true
          this.showOrderRequest = false
        }
      }, (result) => {
        console.log(result)
      })
    },

    confirm() {
      let productIdStr = this.productId.join()
      console.log(productIdStr)
      this.$axios_delete(this.$SHOP_PRODUCT +'?productIds='+ productIdStr, 'product_productSetup', true, result => {
        console.log('delete product', result)
        this.openModal = false
        this.getProduct()
        this.productId = []
      }, (result) => {
        console.log(result)
        this.openModal = false
        this.notify('error', '삭제할 상품을 체크해주세요.')
      })
    },

    colorBorder(e, item) {
      if(e.currentTarget.parentElement.classList.contains('content-box-checked')) {
        e.currentTarget.parentElement.classList.remove('content-box-checked')
        e.currentTarget.firstChild.removeAttribute('checked')
        this.productId.splice(this.productId.indexOf(item.id), 1)
      } else {
        e.currentTarget.parentElement.classList.add('content-box-checked')
        e.currentTarget.firstChild.setAttribute('checked', true)
        this.productId.push(item.id)
      }
    },

    toggle(e, _idx) {
      let targetClassList = e.currentTarget.classList
      if(targetClassList.contains('on')) {
        let formData = new FormData()
        formData.append('state', 'false')
        this.$put(this.$SHOP_PRODUCT_STATE + '/' + _idx, 'productState_productSetup', formData, true, (result) =>{
          console.log(result)
          targetClassList.remove('on')
          targetClassList.add('off')
        }, (result) => {
          console.log(result)
        })

      } else {
        let formData = new FormData()
        formData.append('state', 'true')
        this.$put(this.$SHOP_PRODUCT_STATE + '/' + _idx, 'productState_productSetup', formData, true, (result) =>{
          console.log(result)
          targetClassList.remove('off')
          targetClassList.add('on')
        }, (result) => {
          console.log(result)
        })
      }
    },

    goDetail(_idx) {
      if (_idx) {
        this.$router.push({ name: 'ProductDetail', params: { productIdx: _idx, randomVal1 : Math.random().toString() }})
      } else {
        this.$router.push({ name: 'ProductDetail', params: { randomVal1 : Math.random().toString()}})
      }
    }
  },

}
</script>

<style scoped>

</style>
