<template>
  <transition name="alarm" appear>
    <div class="all-content bg-white alarm-content full-width">
      <header class="header-wrap">
        <button type="button" class="btn btn-back" @click="$emit('closeAlarm'); $root.$emit('refreshNoti')" >
          <i class="icon-arrow-left"></i>
        </button>
        <p>알림</p>

      </header>
      <!--      알람 없을 때-->
      <noContent v-show="noContent" title="알림이 없습니다!"></noContent>
      <!--      알람 있을 때-->
      <div style="padding-top: 50px" v-show="showAlarm">
        <div class="alram-wrap">
          <div class="alram-list" v-for="(item, index) in alarms" :key="index">
            <p class="alram-title">{{ item.title }}</p>
            <p class="alram-content">
              <strong>{{ /\[.*\]/.test(item.content) ? /\[.*\]/.exec(item.content)[0] : ''}}</strong>
              {{  /\[.*\]/.test(item.content) ? item.content.replace(/\[.*\]/.exec(item.content)[0], "") : item.content }}
            </p>
            <p class="alram-date">{{ item.date }}</p>
          </div>
        </div>
      </div>

    </div>
  </transition>

</template>

<script>

export default {
  name: "AlarmList.vue",

  data() {
    return {
      noContent: false,
      showAlarm: true,
      alarms: '',
    }
  },

  watch: {
    '$parent.showAlarm': {
      handler() {
        if(this.$parent.showAlarm === true) {
          if(window.document.querySelector('.scroll') !== null) {
            window.document.querySelector('.scroll').classList.add('no-scroll')
          }
          this.$get(this.$SHOP_NOTIFICATION +'?page=1', 'notification_alarmList', true, (result) => {
            console.log('notification', result)
            this.alarms = result.data
            if(this.alarms.length !== 0) {
              this.noContent = false
              this.showAlarm = true
            } else {
              this.noContent = true
              this.showAlarm = false
            }
            console.log('this.alarms.length', this.noContent)
          }, (result) => {
            console.log('fail', result)
          })
        } else {
          if(window.document.querySelector('.scroll') !== null) {
            window.document.querySelector('.scroll').classList.remove('no-scroll')
          }
        }

      }
    }

  }
}
</script>

<style scoped>

</style>
