<template>
  <div class="intro-wrap">
    <router-link :to="{name: 'IntroLogin'}">
    <div class="intro-bi">
      <img src="/images/jangboda_shop_bi.svg"  alt="마켓나우 - 가맹점"/>
    </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Intro",
  methods: {
    setFCMToken() {
      let fcmToken = window.getFCMToken()
      let deviceType = window.getDeviceType()
      if(fcmToken && fcmToken !== '' && deviceType) {
        let formData = new FormData()
        formData.append('device', deviceType)
        formData.append('fcmToken', fcmToken)
        this.$put(this.$SHOP_FCM_UPDATE, 'fcmUpdate_intro', formData, true, () => {
        }, () => {
          this.notify('error', 'FCM 토큰 업데이트 실패')
        })
      }
    },
  },
  mounted() {
    let passToken = localStorage.getItem('passToken')
    if (passToken && passToken.length > 0) {
      let formData = new FormData()
      formData.append('passToken', passToken)
      this.$post(this.$SHOP_AUTO_LOGIN, 'autoLogin_intro', formData, false, (result) => {
        localStorage.setItem('accessToken', result.data.accessToken)
        localStorage.setItem('expires',result.data.expires)
        localStorage.setItem('passToken',result.data.passToken)
        localStorage.setItem('refreshToken',result.data.refreshToken)
        this.setFCMToken()
        setTimeout(() => {
          this.$router.push({ name: 'Dashboard' })
        }, 500)
        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)
      }, (result)=> {
        console.log(result)
        setTimeout(() => {
          this.$router.push({ name: 'IntroLogin' })
        }, 500)
      })
    } else {
      setTimeout(() => {
        this.$router.push({ name: 'IntroLogin' })
      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>
