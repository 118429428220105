<template>
  <div>
<!--    픽업 요청 없을 때-->
    <noContent v-show="pickups.length === 0" title="픽업요청이 없습니다!"></noContent>

    <!--    픽업 요청 있을 때-->
    <div class="dashboard-content cursor" v-show="pickups.length > 0">
      <div class="content-box" v-for="pickup in pickups" :key="pickup.orderDetailIdx"
           @click="$router.push({ name: 'OrderDetail', params: { orderDetailIdx: pickup.orderDetailIdx, backToPickup: 'true' } })">
        <p class="badge badge-classify">{{ getOrderState(pickup.orderState) }}</p>
        <p class="order-code">{{ pickup.orderCode }}</p>
        <p class="order-time">
          <span class="order-time-title">주문시간</span><span>{{ pickup.regDate }}</span><br>
          <span class="order-time-title">배달시간</span><span>{{ pickup.deliveryTime }}</span>
        </p>
        <div>
          <p class="product-set" v-for="(menu, idx) in pickup.list" :key="idx">
            <span class="product">{{ menu.orderProductName }}</span>
            <span class="option" v-for="(option, idx) in menu.options" :key="idx">{{ option.optionName }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "DashboardPickup",

  data() {
    return {
      pickups: [],
    }
  },

  methods: {
    getData() {
      this.$get(this.$SHOP_ORDER_PICKUP, 'orderPickup_dashboardPickup', true, (result) => {
        console.log('get pickups', result.data)
        this.pickups = result.data
        this.$mount()
      }, (result) => {
        console.log(result)
      })
    }
  },

  mounted() {
    this.getData()
  },

  watch: {
    '$parent.isPickupActive': {
      handler() {
        if(this.$parent.isPickupActive === true) {
          this.getData()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
