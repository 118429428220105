<template>
  <transition name="lnb" appear class="lnb">
    <div class="all-content lnb-content">
      <header class="header-wrap header-lnb">
        <button type="button" class="btn btn-close" @click="$emit('closeLnb')">
          <i class="icon-close"></i>
        </button>
      </header>
      <div class="lnb-wrap">
        <div class="lnb-shop">
          <div class="lnb-shop-set">
            <div>
              <img class="lnb-photo" :src="logoImg" alt="로고 사진"/>
            </div>
            <div>
              <p class="lnb-shop-name">{{ shopInfo.shopInfoName }}</p>
            </div>
            <p class="lnb-market-name">{{ shopInfo.marketName }}</p>
          </div>
        </div>
        <div class="lnb-btn">
          <div @click="$emit('closeLnb')">
            <router-link :to="{name: 'Dashboard'}" class="lnb-btn-home cursor">
            <span class="icon">
              <img src="/images/jangboda_shop_icon_home.svg" alt="홈(주문관리)" />
            </span>
              <span>홈 (주문관리)</span>
            </router-link>
          </div>

          <div class="lnb-btn-set">
            <a class="lnb-btn-sales" @click="goPath('sales')">
              <span class="icon">
                <img src="/images/jangboda_shop_icon_sales.svg" alt="매출관리" />
              </span>
              <span>매출관리</span>
            </a>

            <a class="lnb-btn-calculate" @click="goPath('calculate')">
<!--        <a class="lnb-btn-calculate" @click="goPath('payment')">-->
              <span class="icon">
<!--                <img src="/images/jangboda_shop_icon_payment.svg" alt="결제관리" />-->
                <img src="/images/jangboda_shop_icon_calculate.svg" alt="정산관리" />
              </span>
<!--              <span>결제관리</span>-->
              <span>정산관리</span>
            </a>

            <a class="lnb-btn-product" @click="goPath('product')">
              <span class="icon">
              <span class="icon"><img src="/images/jangboda_shop_icon_product.svg" alt="상품관리" /></span>
              </span>
              <span>상품관리</span>
            </a>

            <a class="lnb-btn-mypage" @click="goPath('mypage')">
              <span class="icon">
                <img src="/images/jangboda_shop_icon_mypage.svg" alt="마이페이지" />
              </span>
              <span>마이페이지</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Lnb",
  data() {
    return {
      shopInfo: {
        shopInfoName: '',
        marketName: '',
      },
      logoImg: '',
    }
  },
  methods: {
    goPath(page) {
      if(page === 'sales') {this.$route.path === '/sales/details' ? this.$emit('closeLnb') : this.$router.push({ name: 'SalesDetails' })}
      if(page === 'calculate') {this.$route.path === '/calculate/management' ? this.$emit('closeLnb') : this.$router.push({ name: 'Management' })}
      // if(page === 'payment') {this.$route.path === '/payment/setup' ? this.$emit('closeLnb') : this.$router.push({ name: 'PaymentSetup' })}
      if(page === 'product') {this.$route.path === '/product/setup' ? this.$emit('closeLnb') : this.$router.push({ name: 'ProductSetup' })}
      if(page === 'mypage') {this.$route.path === '/mypage/main' ? this.$emit('closeLnb') : this.$router.push({ name: 'MypageMain' })}
    },
  },

  mounted() {
    this.$get(this.$SHOP, 'shop_lnb', true, result => {
      console.log('shop', result)
      this.shopInfo = result.data
      this.shopInfo.logoImg !== "nullnull" ? this.logoImg = this.shopInfo.logoImg : this.logoImg = '/images/jangboda_shop_lnb_no_image.svg'

      window.subscribedTopic('notice')
      window.subscribedTopic('market_all')
      window.subscribedTopic( `market_${this.shopInfo.marketIdx}`)

      if(this.shopInfo.auth === false) {
        this.notify('error', '본인 인증을 해주세요')
        this.$router.push({ name:'Certification', params: { firstNice: 'true' }}).catch(()=>{})
      }
    }, result => {
      console.log(result)
    })
  },

}
</script>

<style scoped>

</style>
