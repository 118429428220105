<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'MypageSetup' })"><i class="icon-back"></i></button>
      <p>비밀번호 변경</p>
    </header>
    <div class="mypage-wrap">
      <div class="mypage-password">
        <div class="password-input-group">
          <label for="Password">기존 비밀번호 입력</label>
          <input id="Password" type="password" placeholder="기존 비밀번호를 입력하세요." v-model="pwd">
        </div>
        <div class="password-input-group">
          <label for="newPassword">새 비밀번호 입력</label>
          <input id="newPassword" type="password" placeholder="새 비밀번호 입력(8~16자의 영문대/소문자, 숫자, 특수문자 사용)" v-model="newPwd">
        </div>
        <div class="password-input-group">
          <label for="newPasswordConfirm">새 비밀번호 확인</label>
          <input id="newPasswordConfirm" type="password" placeholder="비밀번호를 한번 더 입력하세요." v-model="confirmNewPwd">
        </div>
        <div class="password-btn">
          <button type="button" class="btn btn-pink mt-0" @click="changePwd">비밀번호 변경</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageResetPassword",
  data() {
    return {
      pwd: '',
      newPwd: '',
      confirmNewPwd: ''
    }
  },
  methods: {
    changePwd() {
      let formData = new FormData
      formData.append('newPassword', this.newPwd)
      formData.append('newPasswordConfirm', this.confirmNewPwd)
      formData.append('password', this.pwd)

      this.$put(this.$SHOP_CHANGE_PASSWORD, 'changePassword_mypageResetPassword', formData, true, result => {
        console.log(result)
        this.notify('success', result.data)
        this.$router.push({ name: 'Login' }).catch(()=>{})
      }, result => {
        console.log(result.error)
        this.notify('error', result.error)
      })
    }
  }
}
</script>

<style scoped>

</style>
