<template>
  <div class="all-content">
    <Lnb v-show="showLnb" @closeLnb="showLnb = false"></Lnb>
    <AlarmList v-show="showAlarm" @closeAlarm="this.closeAlarm"></AlarmList>
    <div>
      <Header title="마이페이지" @openLnb="this.openLnb" @openAlarm="this.openAlarm"></Header>
      <div class="dashboard-wrap sub-main">
        <div class="mypage-content">
          <div class="mypage-title bg-white">
            <span class="shop-name">{{ shopInfo.shopInfoName }}</span>
            <router-link :to="{name: 'MypageSetup'}" class="btn btn-setup">
              <img src="/images/jangboda_shop_icon_setup.svg" alt="Button 설정" />
            </router-link>
          </div>
          <div class="mypage-content-list mypage-content-main bg-white">
            <div class="mypage-box" @click="$router.push({name: 'MypageAgreement'})">
              <span>약관</span>
              <span class="btn-arrow-right">
                  <i class="icon-arrow-right"></i>
              </span>
            </div>
            <div class="mypage-box">
              <span>버전정보</span>
              <span class="mypage-version">{{ version }}</span>
            </div>
            <div class="mypage-box" @click="$router.push({name: 'Notice'})">
              <span>공지사항</span>
              <span class="btn-arrow-right">
                <i class="icon-arrow-right"></i>
              </span>
            </div>
            <div class="mypage-box" @click="$router.push({name: 'Support'})">
              <span>1:1 문의 답변</span>
              <span class="btn-arrow-right">
                <i class="icon-arrow-right"></i>
              </span>
            </div>
            <div class="mypage-box" @click="$router.push({name: 'Review'})">
              <span>리뷰관리</span>
              <span class="btn-arrow-right">
                <i class="icon-arrow-right"></i>
              </span>
            </div>
            <div class="mypage-box" @click="$router.push({name: 'PaymentSetup'})">
              <span>결제관리</span>
              <span class="btn-arrow-right">
                <i class="icon-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from "@/commonjs/mixin"

export default {
  name: "MypageMain",
  mixins: [mixin],

  data() {
    return {
      showLnb: false,
      showAlarm: false,
      shopInfo: {
        shopInfoName: '',
      },
      version: '1',
    }
  },

  mounted() {
    this.$get(this.$SHOP, 'shop_mypageMain', true, result => {
      console.log('mypage main', result)
      this.shopInfo = result.data
    }, result => {
      console.log(result)
    })
    this.version = window.getVersion()
  },
}
</script>

<style scoped>

</style>
