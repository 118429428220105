<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back">
        <router-link :to="{name: 'IntroLogin'}"><i class="icon-back"></i></router-link>
      </button>
      <p>로그인</p>
    </header>
    <div class="auth-form">
      <input type="text" class="input-default" placeholder="매장코드를 입력하세요." v-model="code">
      <input type="password" class="input-default" placeholder="비밀번호를 입력하세요." v-model="password" @keyup.enter="login()">
      <div class="checks auth-login" @click="remember = !remember">
        <input type="checkbox" v-model="remember">
        <label>자동로그인</label>
      </div>
      <button class="btn btn-pink" @click="login">로그인</button>
      <div class="auth-link">
        <router-link :to="{name: 'FindCode'}" class="btn">매장코드 찾기</router-link>
        <router-link :to="{name: 'FindPassword'}" class="btn">비밀번호 찾기</router-link>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: "Login",

  data() {
    return {
      code: '',
      password: '',
      remember: false,
    }
  },
  methods: {
    setFCMToken() {
      let fcmToken = window.getFCMToken()
      let deviceType = window.getDeviceType()
      if(fcmToken && fcmToken !== '' && deviceType) {
        let formData = new FormData()
        formData.append('device', deviceType)
        formData.append('fcmToken', fcmToken)
        this.$put(this.$SHOP_FCM_UPDATE, 'fcmUpdate_login', formData, true, () => {
        }, () => {
          this.notify('error', 'FCM 토큰 업데이트 실패')
        })
      }
    },

    login() {
      let formData = new FormData()
      formData.append('code', this.code)
      formData.append('password', this.password)
      formData.append('remember', this.remember)

      this.$post(this.$SHOP_LOGIN, 'login_login', formData, false, result => {
        console.log('success', result)
        localStorage.setItem('accessToken', result.data.accessToken)
        localStorage.setItem('expires',result.data.expires)
        localStorage.setItem('refreshToken',result.data.refreshToken)

        if (result.data.passToken) {
          localStorage.setItem('passToken',result.data.passToken)
        } else {
          localStorage.setItem('passToken','')
        }
        this.setFCMToken()

        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)

        this.$router.push({ name: 'Dashboard' }).catch(()=>{})

      }, result => {
        console.log('fail', result)
        if(this.code === '' && this.password === '') {
          this.notify('error', result.data.error[0].message + result.data.error[1].message)
        } else if (this.code === '') {
          this.notify('error', result.data.error[0].message)
        } else if (this.password === '') {
          this.notify('error', result.data.error[1].message)
        } else {
          this.notify('error', result.data.error)
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
