<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'AddressChangeStep1' })"><i class="icon-back"></i></button>
      <p>주소 변경</p>
    </header>
    <div class="map-wrap">
      <div id="map"></div>
    </div>
    <div class="auth-form h-unset location-wrap bg-white">
      <button type="button" class="btn btn-round_location my-location">
        <img src="/images/jangboda_shop_icon_my_location.svg" alt="나의 위치" />
      </button>
      <strong></strong>
      <span>도로명</span> <small></small>
      <div class="input-group">
        <input type="text" class="input-default" placeholder="상세주소를 입력하세요. (건물명, 동/호수 등)" v-model="detail">
      </div>
      <button type="button" class="btn btn-pink" @click="setAddress()">이 위치로 주소 설정</button>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "AddressChangeStep2",
  data() {
    return {
      map: null,
      delay: 300,
      timer: null,
      geocoder: null,
      address: null,
      detail: '',
      lat: 37.567676,
      lng: 126.9754816,
      $roadAddress: '',
      $address: '',
      marker: null,
    }
  },
  mounted() {
    if(typeof this.$route.params.address !== 'undefined') {
      this.address = this.$route.params.address
      this.lat = this.address.y
      this.lng = this.address.x
      this.initMap()
      this.setPosition()
    } else {
      navigator.geolocation.getCurrentPosition(this.setLocation, (e) => {
        console.log(e)
        this.$get(this.$SHOP_SHOPINFO_DETAIL, 'shopInfoDetail_addressChangeStep2', true, (result) => {
          this.lat = result.data.lat
          this.lng = result.data.lng
          this.initMap()
          this.setPosition()
        }, () => {
        })
      })
    }
  },
  methods: {
    setLocation(position) {
      this.lat = position.coords.latitude
      this.lng = position.coords.longitude
      this.initMap()
      //this.setPosition()
    },

    setAddress() {
      this.$router.push({ name: 'ShopInfo', params: { roadAddress: this.$roadAddress, address: this.$address, detail: this.detail, lat: this.lat.toString(), lng: this.lng.toString() }})
    },

    setPosition() {
      let position = this.map.getCenter()
      this.marker.setPosition(position)
      this.map.panTo(new window.kakao.maps.LatLng(position.getLat(), position.getLng()))
      this.geocoder.coord2Address(position.getLng(), position.getLat(), (result) => {
        this.$roadAddress = (result[0].road_address !== null) ? result[0].road_address.address_name : '도로명 주소가 존재하지 않습니다.'
        this.$address = result[0].address.address_name
        this.lat = position.getLat()
        this.lng = position.getLng()
        console.log(this.lat, this.lng)
        window.$('.location-wrap').children('strong').text(result[0].address.address_name).next().next().text(this.$roadAddress)

        axios.get("https://dapi.kakao.com/v2/local/search/address.json?query="+result[0].address.address_name+"&size=30", {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Authorization": "KakaoAK " + process.env.VUE_APP_KAKAO_KEY
          },
          timeout: 0
        }).then(response => {
          this.address = response.data.documents[0]
        }).catch((e) => {
          console.log(e)
        })
      });
    },
    initMap() {
      this.geocoder = new window.kakao.maps.services.Geocoder()

      let imageSrc = '/images/icon_marker_here.png',
          imageSize = new window.kakao.maps.Size(48, 60);

      let mapContainer = document.getElementById('map')
      let mapOption = {
        center: new window.kakao.maps.LatLng(this.lat, this.lng),
        level: 2
      }

      window.$('#map').css('height', window.$('.location-wrap').position().top-53)

      this.map = new window.kakao.maps.Map(mapContainer, mapOption)

      this.marker = new window.kakao.maps.Marker({
        position: new window.kakao.maps.LatLng(this.lat, this.lng),
        image: new window.kakao.maps.MarkerImage(imageSrc, imageSize)
      });

      this.marker.setMap(this.map)
      this.marker.setDraggable(false)

      window.$(window).resize(() => {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          window.$('#map').css('height', window.$('.location-wrap').position().top-53)
          if(window.$('#map').height() > 500) {
            this.map.setLevel(2)
          } else {
            this.map.setLevel(1)
          }
          this.map.relayout()
          this.setPosition()
        }, this.delay);
      });

      window.$('.my-location > img').click(() => {
        this.setPosition()
      })

      window.kakao.maps.event.addListener(this.map, 'dragend', () => {
        this.setPosition()
      })

      window.kakao.maps.event.addListener(this.map, 'drag', () => {
        let position = this.map.getCenter()
        this.marker.setPosition(position)
      })

      window.kakao.maps.event.addListener(this.map, 'zoom_changed', () => {
        this.setPosition()
      });
    }
  }
}
</script>

<style scoped>

</style>
