<template>
  <div class="all-content bg-white">
    <Modal :askConfirmTxt=askConfirmTxt v-if="openModal" @close="openModal = false" @confirm="confirm" @confirmDelete="confirmDelete" :idxDelete=idxDelete></Modal>
    <header class="header-wrap">
      <p>상품분류편집</p>
      <button type="button" class="btn btn-close" @click="closeClassification"><i class="icon-close"></i></button>
    </header>
    <div class="product-content-classify">
      <div class="form-content-set">
        <p>
          <span class="input-classify-regist">
            <input type="text" class="input-box" placeholder="분류명을 입력하세요." v-model="itemName" @keyup="goAdd($event)">
          </span>
          <button type="button" class="btn btn-classify btn-outline-pink" @click="addClassification">등록</button>
        </p>
      </div>
      <div class="form-content-set">
        <Draggable @end="drag" class="classification-items">
          <p v-for="classification in classifications" :key=classification.orderNumber class="drag-item">
            <input type="text" class="input-box" v-model=classification.name @change="modifyClassification(classification)">
            <span @click="deleteItem(classification.idx)"><button type="button" class="btn input-delete"><i class="icon-close"></i></button></span>
          </p>
        </Draggable>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  name: "ProductClassify",
  components: {
    'Draggable': Draggable,
  },
  data() {
    return {
      itemName: '',
      classifications: [],
      orderNoStr: '',
      askConfirmTxt: '',
      openModal: false,
      idxDelete: null,
    }
  },
  methods: {
    goAdd(e) {
      if(e.keyCode === 13) {
        e.preventDefault()
        this.addClassification()
      }
    },

    closeClassification() {
      this.idxDelete = null
      this.askConfirmTxt = '상품 분류를 완료하시겠습니까?'
      this.openModal = true
    },

    deleteItem(idx) {
      this.idxDelete = idx
      this.askConfirmTxt = '선택하신 상품 분류를 삭제하시겠습니까?'
      this.openModal = true
    },

    confirm() {
      this.$router.push({ name: 'ProductDetail', params: { productIdx: this.$route.params.productIdx, randomVal: Math.random().toString(), randomVal1: this.$route.params.randomVal1 }})
      this.openModal = false
    },

    confirmDelete(idx) {
      let formData = new FormData
      formData.append('idx', idx.toString())

      this.$axios_delete(this.$SHOP_PRODUCT_CLASSIFICATION +'/'+ idx, 'productClassification_productClassify', true, result => {
        console.log('delete', result)
        this.getData()
      }, result => {
        console.log('fail', result)
      })
      this.openModal = false
    },

    modifyClassification(classification) {
      let formData = new FormData
      let classificationIdx = classification.idx
      formData.append('idx', classificationIdx)
      formData.append('name', classification.name)

      this.$put(this.$SHOP_PRODUCT_CLASSIFICATION +'/'+ classificationIdx, 'productClassification_productClassify', formData, true, result => {
        console.log(result)
      },result => {
        console.log(result)
      })
    },

    drag(e) {
      Array.prototype.move = function(from, to) {
        this.splice(to, 0, this.splice(from, 1)[0])
      }
      this.classifications.move(e.oldIndex, e.newIndex)
      this.classifications.forEach(classification => {
        this.orderNoStr += classification.idx + ','
      })
      this.orderNoStr = this.withoutLastComma(this.orderNoStr)
      console.log(this.orderNoStr)

      let formData = new FormData
      formData.append('ids', this.orderNoStr)

      this.$put(this.$SHOP_PRODUCT_CLASSIFICATION_SORT, 'productClassificationSort_productClassify', formData, true, result => {
        console.log('post classi', result)
      }, result => {
        console.log(result)
      })
      this.orderNoStr = ''
    },

    addClassification() {
      if(this.itemName !== '') {
        let formData = new FormData
        formData.append('classification', 'true')
        formData.append('name', this.itemName)
        this.$post(this.$SHOP_PRODUCT_CLASSIFICATION, 'productClassification_productClassify', formData, true, result => {
          console.log('post classification', result)
          this.getData()
          this.itemName = ''
        }, result => {
          console.log(result)
        })
      } else {
        this.notify('error', '분류명을 입력하세요.')
      }
    },

    getData() {
      this.$get(this.$SHOP_PRODUCT_CLASSIFICATION, 'productClassification_productClassify', true, result => {
        this.classifications = result.data
      }, result => {
        console.log(result)
      })
    }
  },

  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
