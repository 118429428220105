import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./commonjs/store"

import datePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
Vue.component('datePicker', datePicker)

import NoContent from "@/components/cmp/no_content"
Vue.component('NoContent', NoContent)

import Lnb from '@/components/dashboard/lnb'
Vue.component('Lnb', Lnb)

import AlarmList from '@/components/dashboard/alarm_list'
Vue.component('AlarmList', AlarmList)

import Header from '@/components/header'
Vue.component('Header', Header)

import Modal from '@/components/modal/confirm'
Vue.component('Modal', Modal)

Vue.config.productionTip = false

import Vuex from 'vuex'
Vue.use(Vuex)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import api from './commonjs/api';
Vue.use(api)

import common from "@/commonjs/common";
Vue.use(common)

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
