import moment from "moment";

export default Object.freeze({
    install(Vue) {
        // 토스트 스타일 알림
        Vue.prototype.notify = function (type, msg, is_alert=false) {
            if(is_alert) {
                alert(msg)
            } else {
                if(type === 'error') {
                    window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-alert-fail\"></i> <span>"+msg+"</span></div>")
                } else {
                    window.$('#alert-wrap').append("<div class=\"alert-content\"><i class=\"icon-check\"></i> <span>"+msg+"</span></div>")
                }
                setTimeout(function() {
                    window.$('#alert-wrap div:first-child').remove()
                }, 3000)
            }
        }

        Vue.prototype.withoutLastComma = function(str) {
            return str.replace(/,\s*$/, "")
        }

        Vue.filter('numberWithCommas', function (x) {
            return (x+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })

        Vue.prototype.numberWithCommas = function(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        Vue.filter('phoneNumber', function(number) {
            return number.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3")
        })

        Vue.prototype.getOrderState = function (value) {
            if(value === '0') {
                return '주문완료'
            } else if(value === '1') {
                return '접수완료'
            } else if(value === '2') {
                return '픽업요청'
            } else if(value === '3') {
                return '픽업완료'
            } else if(value === '4') {
                return '분류완료'
            } else if(value === '5') {
                return '배달완료'
            } else if(value === '6') {
                return '주문취소'
            } else if(value === '7') {
                return '접수취소'
            }
        }

        Vue.prototype.calendarOptions = {
            format: 'YYYY-MM-DD',
            locale: moment.locale('ko'),
            icons: {
                time: 'glyphicon glyphicon-time',
                date: 'glyphicon glyphicon-calendar',
                up: 'glyphicon glyphicon-chevron-up',
                down: 'glyphicon glyphicon-chevron-down',
                previous: 'glyphicon glyphicon-chevron-left',
                next: 'glyphicon glyphicon-chevron-right',
                today: 'glyphicon glyphicon-screenshot',
                clear: 'glyphicon glyphicon-trash',
                close: 'glyphicon glyphicon-remove'
            },
        }

        // 토큰 갱신
        Vue.prototype.refreshTokenRate = 5*60*1000
        Vue.prototype.refreshToken = function () {
            let refreshTokan = localStorage.getItem('refreshToken')
            if(!refreshTokan) {
                return
            }

            let formData = new FormData()
            formData.append('refreshToken ', refreshTokan)

            this.$post(this.$SHOP_REFRESH_TOKEN, 'refreshToken_commonJS', formData, true, (result) => {
                localStorage.setItem('accessToken',result.data.accessToken)
                localStorage.setItem('expires',result.data.expires)
                localStorage.setItem('passToken',result.data.passToken)
                localStorage.setItem('refreshToken',result.data.refreshToken)

                setTimeout(() => {
                    this.refreshToken()
                }, this.refreshTokenRate)
            }, (result) => {
                if (result.data.state === 401) {
                    this.$router.push({ name: 'Login' }).catch(()=>{})
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('expires')
                    localStorage.removeItem('passToken')
                }
            })
        }
    }
})
