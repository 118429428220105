<template>
  <div class="all-content bg-white slide-up">
    <header class="header-wrap">
      <p>이용악관</p>
      <button type="button" class="btn btn-close" onclick="history.back();">
        <i class="icon-close"></i>
      </button>
    </header>
    <div class="auth-form d-table policy">
      <p>"마켓나우 서비스를 이용해 주셔서 감사합니다."</p>
      <p>마켓나우 가맹점 서비스에 대한 이용약관(이하 “본 약관”이라 합니다) 안내입니다. 마켓나우 서비스는 다음과 같은 내용을 담고 있습니다.</p>

      <dl>
        <dt>제 1조 (목적)</dt>
        <dd>본 약관은 주식회사 모스트피엔에스(이하 ‘회사’)가 제공하는 마켓나우 서비스(이하 ‘마켓나우’)에 판매자로 가입하여 회사가 제공하는 전자상거래 관련 서비스 및 기타 서비스(이하 ‘서비스’)를 이용하는 자(이하 ‘판매자’) 간의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</dd>

        <dt>제 2조 (용어의 정의)</dt>
        <dd>
          <ol>
            <li>
              ‘가맹점 앱’이란 회사가 판매자에게 제공하는 판매 운영관리 서비스로, 판매자는 가맹점 앱에서 주문관리, 매출관리, 결제관리, 상품관리 등의 기능을 사용할 수 있습니다.
            </li>
            <li>
              ‘이용자’란 ‘마켓나우’에 접속하여 이 약관에 따라 ‘마켓나우’가 제공하는 서비스를 받는 회원을 말합니다.
            </li>
            <li>
              ‘API 서비스’는 회사와 제휴 관계의 외부 전자상거래 솔루션을 통해 ‘카카오톡 마켓나우’의 상품과 주문을 관리할 수 있도록 상품등록, 상품수정, 주문 등을 연동하는 API를 제공하고 운영하는 것을 말합니다.
            </li>
            <li>
              ‘API 인증키’란 관련 시스템이 API 이용 승낙을 받은 판매자임을 식별할 수 있도록 회사가 판매자별로 할당하는 고유한 값을 말합니다.
            </li>
            <li>‘센터관리자’란 시장 내의 가맹점과 주문, 물류 등을 관리하는 관리자를 말합니다.</li>
            <li>‘매장 코드’란 판매자가 가맹점 앱을 사용하기 위해 센터관리자로부터 부여받는 ID를 말합니다.</li>
            <li>본 약관에서 사용되는 용어의 정의는 본 약관에서 별도로 규정하는 경우를 제외하고 &lt;마켓나우 이용약관&gt; 제2조에서 정한 바에 따릅니다.</li>
          </ol>
        </dd>

        <dt>제 3조 (약관 등의 명시와 설명 및 개정)</dt>
        <dd>
          <ol>
            <li>회사는 본 약관의 내용, 상호, 대표자 성명, 연락처(전화, 전자우편주소 등), 사업자등록번호 등을 판매자가 쉽게 알 수 있도록 가맹점 앱에 게시합니다.</li>
            <li>회사는 “전자상거래 등에서의 소비자보호에 관한 법률(이하 “전상법”)”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률”, “소비자보호법” 등 관계 법령에 위배 되지 않는 범위에서 본 약관을 개정할 수 있습니다.</li>
            <li>회사가 본 약관을 개정할 경우에는 적용일자 및 변경사유를 명시하여 현행 약관과 함께 서비스 내의 적절한 장소에 개정약관의 적용 일자 칠(7)일 전부터 적용일자 이후 상당한 기간 동안 공지합니다. 다만, 변경 내용이 판매자에게 불리한 경우에는 적용일자 삼십(30)일 전부터 적용일 이후 상당한 기간 동안 공지하고, 판매자가 가입 시 이메일 주소를 기재한 경우 해당 이메일 주소로 개정약관을 발송하여 고지합니다.</li>
            <li>회사가 전항에 따라 개정약관을 공지 또는 통지하면서 판매자에게 약관 변경 적용일까지 거부의사를 표시하지 아니할 경우, 약관의 변경에 동의한 것으로 간주한다는 뜻을 명확하게 공지 또는 통지하였음에도 판매자가 명시적으로 거부의사를 표명하지 아니한 경우 판매자가 개정약관에 동의한 것으로 봅니다.</li>
            <li>판매자가 개정약관의 내용에 동의하지 않는 경우 회사는 해당 판매자에 대하여 개정약관의 내용을 적용할 수 없으며, 이 경우 판매자는 이용계약을 해지할 수 있습니다. 다만, 회사가 개정약관에 동의하지 않은 판매자에게 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 해당 판매자와의 이용계약을 해지할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제4조 (약관의 효력)</dt>
        <dd>
          <ol>
            <li>
              회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 해당 내용을 &lt;<a href="http://jangboda.com" target="_blank">http://jangboda.com</a>&gt; 혹은 ‘가맹점 앱’을 통하여 게시합니다.
            </li>
            <li>본 약관에 의해 판매회원으로 가입하고자 하는 자는 &lt;마켓나우 이용약관&gt;의 내용을 숙지하고 마켓나우 회원과 회사 간의 권리∙의무관계에 대해 동의함을 확인합니다.</li>
            <li>제1항에 따른 ‘게시 내용’은 본 약관에서 정하지 않은 사항에 대해 &lt;마켓나우 이용약관&gt;을 따르며 &lt;마켓나우 이용약관&gt; 에서 정하지 않은 사항은 관련 법령과 상관례에 따릅니다.</li>
            <li>본 약관과 관련하여 당사자가 서로 합의하여 추가로 작성한 계약서, 협정서, 약정서 등과 회사의 정책 변경, 법령의 제·개정 또는 공공기관의 고시·지침 등에 따라 회사가 판매자센터 등을 통해 판매자에게 공지하는 내용도 이용계약의 일부를 구성합니다.</li>
          </ol>
        </dd>

        <dt>제5조 (회원가입 및 이용계약의 체결)</dt>
        <dd>
          <ol>
            <li>마켓나우 판매회원이 되기 위해서는 회사가 요구하는 사업자 등록증, 영업신고증 등의 서류와 매장명, 점포주 등의 매장 정보를 센터관리자에게 제출해야 하며, 이에 대한 승인 절차가 완료되면 매장 코드를 부여받습니다.</li>
            <li>서비스의 이용 신청은 서비스를 이용하고자 하는 자가 제1항에 따라 마켓나우 계정 발급을 완료한 뒤, 본 약관에 동의하고 회사가 정한 절차에 따라 판매자 가입을 신청함으로써 성립합니다. 회사는 이용 승낙의 의사표시를 해당 서비스에 접속을 허용함으로써 할 수 있습니다.</li>
            <li>회사는 이용신청자의 이용 신청 시 별도의 증빙서류를 요청할 수 있고, 이용신청자가 이용 신청시 입력한 정보 등을 심사할 수 있습니다. 구체적인 증빙서류 및 정보 입력 세부사항은 별도 제공되는 안내서에 따릅니다.</li>
            <li>판매자 가입은 만 14세 이상의 개인사업자 혹은 법인사업자가 할 수 있으며, 만 19세 미만의 판매자 가입신청에 대해서 회사가 법정대리인의 동의서 및 입증 자료의 제출을 요구하는 경우 회원은 이에 적극 협조하여야 합니다.</li>
            <li>
              회사는 다음 각 호의 사유에 해당하는 이용신청에 대해 승낙을 거부할 수 있습니다.
              <ul>
                <li>가입 신청자가 만 14세 미만의 아동인 경우</li>
                <li>등록 내용에 허위, 기재 누락, 오기가 있는 경우</li>
                <li>신청 내용이 제5조 제3항의 기준에 부합하지 않는 경우</li>
                <li>판매자가 제22조에 따라 회사로부터 서비스 이용 제한을 받은 뒤, 이용계약을 임의 해지하고 재이용 신청을 하는 경우</li>
                <li>판매자로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                <li>기타 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우</li>
              </ul>
            </li>
            <li>이용계약의 성립 시기는 회사의 승낙이 판매자에게 도달한 시점으로 합니다.</li>
            <li>판매자는 회원가입 정보와 관련하여 주소지 또는 대금결제를 위한 통장 계좌 등의 변경이 있을 경우 즉시 가맹점 앱으로 수정하거나 센터 관리자, 혹은 회사에 이를 통지하여야 하며, 회사는 통지의 지연으로 인하여 발생한 손해에 대하여 책임을 지지 않습니다.</li>
          </ol>
        </dd>

        <dt>제6조 (서비스의 이용)</dt>
        <dd>
          <ol>
            <li>
              이 약관에 따라 회사가 제공하는 서비스는 다음과 같습니다.
              <ul>
                <li>디지털 전통시장 플랫폼 개발 및 운영 서비스</li>
                <li>판매 관련 업무 및 상품DB 유통 지원 서비스</li>
                <li>구매 및 판매 관련 지원 서비스</li>
                <li>기타 통신판매중개 관련 제반 서비스</li>
              </ul>
            </li>
            <li>회사가 제공하는 전항의 서비스는 판매자가 상품 등을 거래할 수 있도록 서비스의 이용을 허락하거나, 통신판매를 알선하는 것을 목적으로 합니다.</li>
            <li>회사는 업무상 또는 기술상 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스 제공을 원칙으로 합니다. 단, 정기점검이나 시스템의 업그레이드가 필요한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인해 서비스를 일시 중단하는 경우 가맹점 앱 등을 통해 사전에 공지합니다.</li>
          </ol>
        </dd>

        <dt>제7조 (이용에 대한 통지)</dt>
        <dd>
          <ol>
            <li>회사는 서비스를 운영함에 있어 서비스와 관련한 정보를 가맹점 앱의 공지사항에 게재하거나 전자우편을 발송하는 등의 방법으로 판매자에게 통지합니다.</li>
            <li>판매자는 회사에 실제로 연락 가능한 전자우편 주소, (휴대)전화번호 등의 정보를 제공하고 해당 정보를 최신으로 유지하여야 하며, 회사의 통지를 확인해야 합니다.</li>
            <li>판매자가 전항의 의무를 소홀히 하여 발생한 불이익에 대해서는 보호받지 못합니다.</li>
            <li>본 조에 따라 회사가 발송하는 전자우편 등이 판매자의 수신 거부 또는 판매자가 제공한 정보가 사실과 부합하지 않아 발생하는 오류 등에 대해서 회사는 책임지지 않습니다.</li>
          </ol>
        </dd>

        <dt>제8조 (서비스의 변경 및 중단)</dt>
        <dd>
          <ol>
            <li>회사는 서비스 정책 결정 등을 통해서 서비스의 전부 또는 일부를 변경하거나 새로운 서비스를 추가하여 제공할 수 있습니다. 이 경우 회사는 변경 또는 추가될 서비스의 내용 및 제공 일자 등을 제 7조에서 정한 방법으로 판매자에게 통지하거나 서비스 내 공지사항 등에 공지합니다.</li>
            <li>
              회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.
              <ul>
                <li>서비스 제공을 위한 정보통신설비의 보수 점검, 교체 및 고장, 이전, 통신의 두절 등의 사유가 발생하여 서비스의 제공을 일시적으로 중단할 경우 회사는 7일 이전에 서비스의 중단을 공지합니다. 다만 불가피하게 사전 공지를 할 수 없는 경우 회사는 사후 공지할 수 있습니다.</li>
                <li>천재지변, 전쟁, 폭동, 테러, 해킹, DDOS 등 불가항력적 사유로 마켓나우가 중단된 경우 회사는 즉시 이러한 사실을 공지하되, 만일 정보통신설비의 작동불능 등의 불가피한 사유로 인해 사전공지가 불가능한 경우에는 이러한 사정이 해소된 이후 즉시 이러한 사실을 공지합니다.</li>
                <li>회사는 파산 신청, 회생절차 개시신청 등 정상적인 영업활동이 곤란한 경우가 발생하여 마켓나우를 지속할 수 없는 사유가 발생한 때에는 판매자에게 이를 통지한 날로부터 30일이 경과한 때에 마켓나우를 중단할 수 있습니다. 이 때 회사는 판매자에게 손해가 발생하지 아니 하도록 가능한 노력을 다합니다.</li>
              </ul>
            </li>
            <li>회사는 본 조 제1항 내지 제2항의 사유가 발생한 경우 최대한 빠른 시간 내에 서비스를 재개하도록 최선의 노력을 다합니다.</li>
            <li>회사는 무료로 제공되는 서비스의 추가, 변경 또는 중단 등으로 발생하는 문제에 대해서 어떠한 책임도 지지 않습니다.</li>
          </ol>
        </dd>

        <dt>제9조 (서비스 이용 요금)</dt>
        <dd>
          <ol>
            <li>
              회사는 판매자에게 마켓나우 및 각종 부가 서비스를 이용함에 따른 대가로 서비스 이용료를 부과할 수 있으며, 회사는 서비스 제공 비용, 시장상황, 거래방식 등을 고려하여 판매자에게 제공하는 서비스에 대한 이용료를 정합니다. 서비스 이용료는 기본 서비스 이용료(결제수수료)와 각종 부가서비스 이용료(판매자 추가할인, 마켓나우 추가할인 등)를 각각 산정하여 합산합니다.
              <ul>
                <li>기본 서비스 이용료는 결제 수수료율에 따라 산정됩니다.</li>
                <li>상품 판매가격 및 최종 서비스 이용요율은 판매자 추가할인, 시장 추가할인 등의 할인수단으로 인하여 변경될 수 있으나, 판매자 정산대금은 판매자 즉시할인이 적용된 경우를 제외하고는 변경되지 아니합니다.</li>
              </ul>
            </li>
            <li>회사는 서비스 이용료의 구체적인 사항 및 요율 등을 가맹점 앱 등을 통해 공지합니다. 회사는 필요한 경우 서비스 수수료를 신설, 변경할 수 있으며, 신설 또는 변경사항은 가맹점 앱 등을 통하여 공지합니다.</li>
            <li>회사는 판매 활성화 등을 위하여 기본 서비스 이용료 범위 내에서 특정 상품에 대한 기본 서비스 이용료를 할인할 수 있습니다. 또한 회사는 특정 판매자에 대한 서비스 이용료를 회사의 내부적인 정책 또는 회사와 판매자 간의 합의에 따라 다르게 정할 수 있습니다. 특정 판매자에 대한 서비스 이용료의 조정 또는 면제 시 회사는 미리 그에 관한 주요 사항을 대상자에게 고지 또는 약정할 수 있습니다.</li>
            <li>회사는 마켓나우 이용료를 판매자에게 지급해야 하는 정산대금에서 공제하는 등의 방식으로 징수할 수 있으며, 회사와 판매자 간의 협의 또는 회사의 내부적인 정책 등에 따라 수수료 및 수수료 징수 방법을 다르게 정할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제10조 (마켓나우 서비스의 이용 및 관리)</dt>
        <dd>
          <ol>
            <li>마켓나우를 통한 상품 등의 등록 및 판매는 회사의 판매자 가입 승인 이후에 가능하며, 판매자는 상품 등에 대한 정보를 가맹점 앱을 통하여 직접 등록, 관리하여야 합니다. 이때 판매하는 상품 등의 종류와 단위, 판매 가격, 거래조건은 판매자가 스스로 결정하고, 회사는 이에 부당하게 관여하지 아니합니다. 다만 회사는 상품대금 결제 방식, 상품 배송 방식, 취소 반품 교환 방식 등 구매자 피해방지에 관한 사항을 약관 등으로 정할 수 있습니다.</li>
            <li>판매자는 상품 등을 등록 시 품목별 정보, 거래조건에 관한 정보 등 ‘전자상거래 등에서의 상품 등의 정보제공에 관한 고시’(이하 ‘상품정보제공 고시’)에서 정한 정보를 입력하여야 하고, 상품 등을 등록 후 상품정보제공 고시가 변경되는 경우 그에 맞추어 관련 정보를 수정, 보완하여야 합니다.</li>
            <li>판매자는 등록 상품에 특별한 거래조건이 있거나 추가되는 비용이 있는 경우 구매자가 이를 알 수 있도록 명확하게 기재하여야 합니다.</li>
          </ol>
        </dd>

        <dt>제11조 (지적재산권)</dt>
        <dd>
          <ol>
            <li>회사는 판매자가 등록한 상품 등을 판매 촉진을 위하여 국내 외 포털, 회사의 계열사, 회사 및 계열사가 동의한 제휴사가 운영하는 사이트 등에 노출할 수 있습니다. 단, 노출에 대하여 비용이 발생하는 경우 회사는 판매자에게 노출에 대한 동의를 받으며 별도의 입점 기준이 적용될 수 있습니다.</li>
            <li>판매자가 등록한 상품 등의 정보는 이용자가 이용자의 제3자의 사이트 등에 공유 또는 게시할 수 있습니다.</li>
            <li>판매자는 제3자의 지식 재산권 등을 사용할 때에는 정당한 권리자로부터 사용 허락을 받은 후에 사용해야 하며, 마켓나우의 상품 등의 등록 및 판매와 서비스의 이용과 관련하여 제3자의 상표권, 특허권, 저작권, 성명권, 초상권 등 제반 지식 재산권 등을 침해하지 않아야 합니다.</li>
            <li>판매자가 등록·사용한 제반 정보(초상, 성명 포함)에 대하여 제3자가 지식 재산권 등과 관련한 권리침해를 주장하면 회사는 판매자가 제3자의 권리침해가 아님을 입증(법원의 판결 또는 검찰의 불기소처분 등)할 때까지 해당 상품 등의 판매와 상품 등의 등록을 중지할 수 있습니다.</li>
            <li>판매자는 마켓나우에 등록·사용한 정보와 관련하여 제3자로부터 소송 또는 소송 이외의 방법 등으로 이의제기를 받게 되는 경우 회사를 면책시켜야 하며, 면책에 실패한 경우 그로 인해 회사가 입은 모든 손해를 배상하여야 합니다.</li>
            <li>회사는 판매자가 상품 등의 정보를 등록하면서 관련 가이드를 미준수한 경우 해당 상품을 수정, 삭제 또는 판매 중단하거나, 재등록을 제한하는 등의 조치를 할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제12조 (상품의 배송)</dt>
        <dd>
          <ol>
            <li>구매자의 주문에 따른 결제가 완료되면, 회사는 판매자가 주문 정보를 확인할 수 있도록 조치를 취하고, 판매자는 당해 주문 정보에 따라 배송을 하여야 합니다.</li>
            <li>판매자는 주문내역을 확인하고 배송 중 상품이 파손되지 않도록 적절한 포장을 한 후 배송의 증명이나 추적이 가능한 물류대행(택배) 업체에 배송을 위탁하여야 합니다.</li>
            <li>판매자는 구매자의 결제 완료 일의 배송 요청 시간에 맞춰 발송을 완료하여야 합니다. 단, 구매자와 판매자 간에 상품 등의 공급시기에 관하여 따로 합의한 경우는 제외합니다.</li>
            <li>판매자가 전항의 기한 내에 발송하지 못할 경우에는 구매자에게 연락하여 배송지연사유 및 배송 예정일을 알려야 하며, 배송지연 안내를 하지 않거나, 배송지연 안내시 안내한 배송 예정기간을 초과하는 경우 회사는 별도의 개별 정책으로 구매자에게 자동으로 환불 처리하는 등의 조치를 할 수 있습니다.</li>
            <li>구매자의 귀책사유 없이, 판매자의 오류로 인하여 구매자가 상품을 정상적으로 수령하지 못한 경우 또는 상품을 배송 기사에게 전달하는 과정에서 하자가 발생한 경우, 판매자는 그에 관한 모든 책임을 부담하여야 합니다.</li>
            <li>상품의 배송과 관련하여 구매자와 분쟁이 발생한 경우 회사는 판매자에게 주문 접수와 포장, 픽업 요청에 해당하는 증빙을 요청할 수 있으며, 판매자는 회사의 요청이 있은 날로부터 7일 이내에 해당 자료를 제출하여야 합니다.</li>
            <li>회사가 구매자의 주문에 따른 결제 확인을 통지한 후 상당 기간 주문확인을 하지 않거나, 주문확인을 한 이후라도 배송 등 후속 절차를 이행하지 않으면 회사는 별도의 개별 정책으로 구매자에게 자동으로 환불 처리하는 등의 조치를 할 수 있습니다.</li>
            <li>회사는 제 3자와 업무 제휴를 통해 배송상태 조회, 반품 수거 자동 예약 서비스 등을 실시할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제13조 (상품 등의 취소, 반품, 교환, 환불)</dt>
        <dd>
          <ol>
            <li>판매자는 구매자가 주문한 상품을 공급하기 곤란하다는 것을 알았을 때, 즉시 해당 사실을 구매자 및 회사에 통보하고 구매자의 동의를 얻은 후 취소를 하여야 하며, 회사가 구매자의 상품 등의 대금 결제일로부터 3 영업일 이내에 상품대금 환불 및 환불에 필요한 조치를 취할 수 있도록 하여야 합니다.</li>
            <li>구매자의 결제일로부터 1일 이내에 상품 발송 처리를 완료하지 않은 경우 해당 주문 건은 자동 환불처리됩니다. 단, 구매자와 판매자 간에 상품 등의 공급시기에 관하여 따로 합의한 경우는 제외됩니다.</li>
            <li>③ 구매자는 상품 배송완료일로부터 7일 이내에 반품요청을 할 수 있으며, 판매자는 구매자가 이 기간 내 반품이나 교환을 요청하는 경우 구매자의 요청에 따라 반품 또는 교환을 해 주어야 합니다. 단, 구매자의 귀책사유로 상품이 훼손된 경우, 시용이나 일부 소비로 인해 상품의 가치가 현저히 감소한 경우, 복제 가능한 상품의 포장을 훼손한 경우, 구매자의 주문에 따라 개별적으로 생산되는 상품 등으로서 상품 판매시 반품이나 교환의 제한을 명시적으로 고지하고 구매자의 동의를 받은 경우 및 기타 법령에 의하여 반품이 제한되어 있는 경우는 예외로 합니다. 또한 상품이 표시 또는 광고 내용과 다를 경우에는 상품 수령 후 90일 이내 또는 그 사실을 알게 된 날 또는 알 수 있었던 날로부터 30일 이내에 구매자가 반품 또는 교환을 요청하는 경우, 판매자는 반품 또는 교환을 해주어야 합니다.</li>
            <li>반품 또는 교환 시 추가로 발생하는 비용은 반품이나 교환의 책임이 있는 측에서 부담합니다. 상품 하자나 오배송 등 구매자의 귀책사유 없는 반품 또는 교환의 경우 판매자가 해당 배송비를 부담해야 합니다.</li>
            <li>판매자가 구매자에게 자체 배송한 상품에 대하여 구매자가 반품을 원할 경우, 판매자는 구매자에게 반품 절차를 정확히 안내한 후 반품을 진행하여야 합니다.</li>
            <li>판매자는 상품의 하자 또는 사용상의 안전성에 결함이 있으면 전량 교환 및 환불하여야 하며, 이에 따른 모든 비용을 부담하여야 합니다.</li>
            <li>판매자는 전자상거래 등에서의 소비자보호에 관한 법률 제 17조 제 2항에 따라 청약철회가 제한되는 사유에 해당되는 경우, 같은 법 제17조 제6항에 따라 청약철회가 불가능하다는 사실을 상품의 포장 기타 소비자가 쉽게 알 수 있는 곳에 명기하여야 합니다.</li>
            <li>판매자는 제7항에 따른 청약철회 제한 사유에 해당하는 등 정당한 사유가 있으면 반품을 거부할 수 있습니다. 판매자가 반품 상품 수령일로부터 3영업일 이내에 환불 또는 환불에 필요한 조치를 취하지 않거나 특별한 의사를 표시하지 않으면 회사는 환불에 필요한 조치를 할 수 있으며, 그 구체적인 사항에 대하여 회사는 합리적인 기준에 따라 정책을 정할 수 있습니다. 다만, 판매자가 구매자의 청약철회를 제한하고자 그 사유를 증빙하여 환불보류를 요청하면 회사는 환불 처리를 보류할 수 있습니다.</li>
            <li>구매자가 반품∙교환 요청 시 구매자 편의성 증진을 위해 회사가 지정하는 배송사가 반품∙교환 택배사로 설정될 수 있습니다. 해당 비용은 판매자정산금액 등에서 차감되며, 판매자는 언제든지 반품∙교환 택배사를 선택하여 변경할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제14조 (정산)</dt>
        <dd>
          <ol>
            <li>서비스를 통해 판매된 상품 등이 구매 결정되는 경우, 회사는 회사의 정산 일정 및 정책에 따라 판매대금에서 서비스 이용 수수료, 회사에 대한 미납금 및 기타채무금을 공제한 나머지 금액(이하, “정산대금”)을 판매자에게 지급합니다.</li>
            <li>판매자는 정산대금을 지정한 계좌로 출금할 수 있으며, 지정한 입금계좌의 예금주는 판매자와 동일인임을 원칙으로 합니다.</li>
            <li>회사는 구매결정된 정산대금을 지정한 판매자의 계좌로 구매결정일 익일 기준 2영업일 내에 자동 송금합니다.</li>
            <li>판매자는 구매자가 상품수령일에 반품, 환불 등의 의사표시가 없는 경우 회사는 회사의 정책에 따른 자동구매결정 절차를 진행할 수 있습니다.</li>
            <li>판매자가 기타의 배송방법을 통하여 배송한 경우 판매자가 배송 당일 회사에 증빙자료를 첨부하여 배송완료를 입증할 수 있으며, 이 경우 회사는 회사의 정책에 따른 자동구매결정 절차를 진행할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제15조 (정산의 보류)</dt>
        <dd>
          <ol>
            <li>회사는 판매자의 귀책사유로 인해 회사 및 구매자에게 발생한 손해 등의 비용을 판매대금 정산 시 공제할 수 있으며, 판매자와의 이용계약 종료 후 당해 판매자의 판매대금의 일정 비율에 해당하는 금액을 이용계약 종료일로부터 일정기간 동안 예치하여 동 기간 동안 구매자로부터의 환불, 교환 등 클레임 제기 시 관련 비용의 지급에 사용할 수 있습니다.</li>
            <li>회사는 자동구매결정 절차에 따라 주문이 종결되는 경우에도, 판매대금의 정산은 향후 구매자의 환불 요청에 대비하여 일정기간 유보할 수 있습니다.</li>
            <li>판매자의 채권자의 신청에 의한 판매대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을 경우, 회사는 판매자와 채권자 간의 합의 또는 채무 액의 변제 등으로 동 결정이 해제될 때까지 판매대금의 정산을 중지할 수 있습니다.</li>
            <li>판매자가 정산대금 수령방법을 계좌입금으로 지정하였으나 계좌번호 오류, 시스템 오류 등으로 인하여 이체에 실패하는 경우 회사는 정상 지급이 가능한 시점까지 정산대금을 보유할 수 있습니다.</li>
            <li>판매자가 본 약관의 규정을 위반하거나 위반한 것으로 의심될 만한 타당한 정황이 있는 경우 거래에 대한 처리, 취소 등을 위해 판매대금의 정산을 일정기간 보류할 수 있습니다.</li>
            <li>본 조에 정한 외에도 법률의 규정에 의하거나 합리적인 사유가 있는 경우에는 회사는 판매자에게 통지하고 판매대금의 전부 또는 일부에 대한 정산을 일정 기간 유보하거나 회사가 판매자에게 가지는 채권과 상계할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제16조 (매매 부적합 상품)</dt>
        <dd>
          <ol>
            <li>회사는 판매자가 등록한 상품 등이 본 조 제3항에 따른 매매 부적합 상품 등 온라인상에서의 매매, 유통에 적합하지 않은 상품인 경우에는 직권, 권리주장자, 신고자, 관할 기관 등의 요청에 따라 해당 상품을 수정, 삭제 또는 판매 중단하거나 재등록을 제한하는 조치 등을 취할 수 있고, 진행 중인 주문이 있는 경우 취소할 수 있습니다.</li>
            <li>회사는 등록된 상품 등이 법령 또는 약관에 위반되거나, 공서양속을 저해하거나 기타 탈법행위와 관련되거나 그러한 목적이 있는 경우 또는 회사의 정책상 필요에 의한 경우에는 직권으로 이를 삭제할 수 있습니다.</li>
            <li>
              다음 각 호의 매매 부적합 상품은 판매를 금지하며, 매매 부적합 상품을 판매함에 따른 모든 책임은 해당 상품 등을 등록한 판매자가 부담합니다.
              <ul>
                <li>허위 또는 과장 광고를 통한 상품 등</li>
                <li>지식재산권(상표권, 저작권 등) 등 타인의 권리를 침해하는 상품 등</li>
                <li>관련 법령에서 유통을 금지하는 음란물, 마약, 혈액 등</li>
                <li>인증검사 등을 통과하지 못한 공산품 또는 전기용품 및 생활용품, 통신용품</li>
                <li>심의되지 않거나 불법 복제된 영상물, 음반, 게임물 등</li>
                <li>장물이나 습득한 유실물</li>
                <li>주류, 담배, 도수가 있는 안경류, 콘택트렌즈, 군수품, 의약품 등 관련 법령에 의하여 통신판매가 금지되는 상품 등</li>
                <li>청소년유해매체물, 청소년유해약물, 청소년유해물건</li>
                <li>신고 · 허가 · 인증 또는 판매자격 요건을 갖추지 않은 식품, 건강기능식품, 친환경농산물, 화장품, 의료기기, 출판물, 의약외품, 유해화학물질 등</li>
                <li>관계기관으로부터 판매중지 또는 유보 결정(요청)이 있는 상품 등</li>
                <li>기타 회사의 정책에 따라 취급이 금지되거나 공서양속에 반하는 상품 등</li>
              </ul>
            </li>
          </ol>
        </dd>

        <dt>제17조 (판매자의 금지행위)</dt>
        <dd>
          <ol>
            <li>
              판매자는 다음 각 호에 해당하는 행위를 하여서는 안됩니다.
              <ul>
                <li>판매자가 매출증대를 위해 본인의 ID 또는 타인의 ID를 사용하여 거짓 정보를 직접 조작하는 행위</li>
                <li>배송상품이 아닌 특정 서비스 가입조건 판매상품, 음란정보, 거짓 정보 등 유해한 정보를 게재하거나 링크하는 행위</li>
                <li>배송상품이 아닌 특정 서비스 가입조건 판매상품, 음란정보, 거짓 정보 등 유해한 정보를 게재하거나 링크하는 행위</li>
                <li>상품 등과 상관없는 브랜드명 및 상표명을 사용하는 행위</li>
                <li>판매자가 악의적으로 동일한 상품을 중복하여 등록하거나 동일 상품이 게재되는 동안 다른 동일 상품을 등록하는 행위</li>
                <li>해당 상품과 관계없는 카테고리에 상품을 등록하는 행위</li>
                <li>매장명 등록 시 회사의 서비스와 혼동 여지를 줄 수 있는 행위</li>
                <li>타 판매자의 매장명, 유사 매장명을 등록하거나 타 판매자의 매장명을 포함하여 등록하는 행위</li>
                <li>상품등록 시 원산지 등 정보를 잘못 등록하는 행위</li>
                <li>타인의 상표나 로고를 허가 없이 사용하거나 상표를 무단으로 도용한 상품을 판매하는 등 제11조를 위반하는 행위</li>
                <li>타인이 촬영한 사진, 타인이 창작한 이미지나 문구 등 타인의 창작물을 무단으로 사용하는 행위</li>
                <li>연예인의 사진 및 동영상을 소속 매니지먼트 회사 등에 허가 없이 무단으로 사용하는 행위</li>
                <li>범죄행위, 해킹, 컴퓨터바이러스유포, 서버 공격 등 타인에게 해를 주는 행위</li>
                <li>구매자의 개인정보를 무단으로 수집하여 활용하거나 제3자에게 제공하는 행위</li>
                <li>회원가입 시 등록한 유선 또는 이메일 등의 방법을 통하여 구매자가 연락 시 의도적으로 피하는 행위</li>
              </ul>
            </li>
            <li>회사는 판매자가 본 조의 금지행위를 하는 경우 본 약관 제19조에 따라 서비스의 일부 또는 전부를 이용정지 하거나 서비스 이용계약을 임의로 해지할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제18조 (회사의 권리와 의무)</dt>
        <dd>
          <ol>
            <li>회사는 본 약관에 따라 지속적이고 안정적인 서비스를 제공하는데 최우선의 노력을 다합니다.</li>
            <li>회사는 판매자로부터 제기되는 불편사항 및 서비스의 문제점을 해결하기 위해 노력합니다.</li>
            <li>회사는 판매자가 서비스를 이용하여 발생하는 매출효과 등의 정보를 통계자료 작성 및 판매자가 이용하는 스토어의 다른 서비스에 적용하는 등의 목적으로 활용할 수 있습니다.</li>
            <li>회사는 판매자의 서비스 이용과 관련한 자료를 수사기관의 수사 목적의 요청 및 기타 공공기관이 관련 법률에 따른 절차를 통해 요청하는 경우 판매자의 동의 없이 해당 기관에 제공할 수 있습니다.</li>
            <li>회사는 판매자가 서비스에 등록한 상품 등 및 그 정보 등이 불법정보에 해당한다고 판단되거나, 방송통신심의위원회 등 관련기관으로부터 요청이 있는 경우 또는 판매자가 서비스를 이 약관 이 외의 목적으로 사용한다고 판단되는 경우 판매자에 대한 사전 통보 없이 해당 정보를 삭제할 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제19조 (판매자의 의무)</dt>
        <dd>
          <ol>
            <li>판매자는 회사가 정하는 절차에 따라 판매자 정보 등록, 상품의 등록, 주문처리 등의 사후처리를 수행하여야 합니다.</li>
            <li>판매자는 상품 등록 시 상품 등록 서비스화면 상의 등록양식에 따라야 합니다.</li>
            <li>판매자는 마켓나우를 이용하고 관리하는데 있어 본 약관 제10조의 규정을 준수해야 합니다.</li>
            <li>판매자는 구매자의 정당한 권리(청약철회권 등)를 제한하는 등 &lt;마켓나우 이용약관&gt; 및 기타 관계법령에 위배되는 행위를 할 수 없고, 구매자의 주문을 처리하는 등의 과정에서 본 약관 제12조 및 제13조의 규정을 준수해야 합니다.</li>
            <li>판매자는 구매자 정보 등 서비스 이용과 관련하여 취득한 일체의 정보를 회사의 서면 동의 없이 외부에 유출하거나 이 약관 이외의 목적으로 사용할 수 없습니다. 이는 이용계약이 종료된 후에도 유지됩니다.</li>
            <li>판매자는 마켓나우 가맹점 앱의 로그인 정보(매장코드 및 비밀번호)를 관리할 책임이 있으며, 회사는 판매자의 귀책사유에 따른 로그인 정보의 분실 및 제3자 사용 등과 관련하여 발생하는 손해에 대해 책임지지 않습니다.</li>
            <li>판매자는 회사의 사전 서면 동의 없이 이 약관에 따른 일체의 권리와 의무를 제3자에게 양도하거나 담보의 목적으로 제공할 수 없습니다.</li>
            <li>판매자는 회사가 서비스 운영에 필요한 정보 및 증빙자료의 제공을 요청할 경우 이에 성실히 응하여야 합니다.</li>
            <li>판매자는 회사가 판매자센터 등을 통해 게시 및 안내하는 서비스 운영 정책 및 공지사항 등을 정확히 숙지해야 하며, 이를 확인하지 않거나 오인하여 발생하는 손해에 대해서는 회사는 어떠한 책임도 부담하지 않습니다.</li>
          </ol>
        </dd>

        <dt>제20조 (서비스 이용제한 및 이용중지 등)</dt>
        <dd>
          <ol>
            <li>
              회사는 다음 각호에 하나라도 해당하는 경우 해당 판매자에 대한 서비스의 일부 또는 전부를 제한할 수 있습니다.
              <ul>
                <li>판매자가 본 약관 제10조 제2항을 위반하여 상품정보제공 고시에 따른 정보를 입력하지 않거나, 상품 등록 후 변경된 상품정보제공고시에 따라 정보를 수정, 보완하지 않는 경우</li>
                <li>판매자가 본 약관 제10조 제5항을 위반하여 상품 등록 제한 횟수를 초과하여 상품을 등록하는 경우</li>
                <li>판매자가 제17조에 해당하는 행위를 하거나 제19조를 위반하는 경우</li>
                <li>기타 본 약관에 명시된 회원의 의무, &lt;마켓나우 이용약관&gt;을 위반하는 경우</li>
              </ul>
            </li>
            <li>전항에 따라 회사가 판매자의 서비스 이용을 제한하는 경우, 위반 행위 경중 및 횟수에 따라 소명 요청, 경고, 서비스 이용제한(판매 중지 또는 금지/ 신규 상품 등록 중지 또는 금지/ 제재 대상과 동일한 상품의 등록 중지 또는 금지/ 상품 전시 차단 또는 삭제/ 검색 제한/ 광고 사용 중지 또는 광고 입찰 제한), 거래 취소 또는 계약 해지 등의 조치를 취할 수 있습니다.</li>
            <li>회사가 제2항에 따라 판매자의 서비스 이용을 제한하는 경우 회사는 그 사유 및 제한기간 등을 판매자에게 알려야 합니다.</li>
            <li>회사는 필요하다고 판단되는 경우 제2항의 조치 전 해당 판매자에 대하여 각 위반행위에 따라 1일부터 최대 3일까지 소명기간을 부여하여 의견 청취 절차를 진행할 수 있으며, 해당 판매자는 소명 기간 내에 소명해야 합니다.</li>
            <li>회사는 제4항에 따라 판매자의 법령 및 약관 위반사실이 없음이 소명된 경우 이미 시행한 조치를 해제하여 원상 회복합니다.</li>
            <li>제2항의 이용 제한은 위반 활동의 누적 정도에 따라 한시적 제한에서 영구적 제한으로 단계적 제한하는 것을 원칙으로 하지만, 위조품 판매, 음란한 내용의 게시와 유포 및 사행성 도박 홍보 등 관련 법령에서 금지하는 명백한 불법행위나 타인의 권리침해로서 긴급한 위험 또는 피해 차단이 요구되는 사안에 대해서는 위반 활동 횟수의 누적 정도와 관계 없이 즉시 영구적으로 이용이 제한될 수 있습니다.</li>
            <li>판매자가 제2항에 따른 서비스 이용 제한 기간 중 제21조에 따른 이용계약 해지 후 서비스에 재가입을 신청하는 경우 제5조에 따라 회원 가입이 제한되거나 본조에 따라 서비스 이용에 제한이 따를 수 있습니다.</li>
          </ol>
        </dd>

        <dt>제21조 (이용 계약의 해지)</dt>
        <dd>
          <ol>
            <li>판매자가 본 약관에 의한 이용계약을 해지하고자 하는 경우, 센터 관리자 혹은 회사로 연락하여 판매자 탈퇴를 요청하여야 합니다. 만약 현재 진행 중인 거래가 있는 경우에는 탈퇴 신청이 불가능하며, 판매자가 해당 사항을 처리 완료한 후 탈퇴 및 이용계약 해지가 가능합니다.</li>
            <li>
              판매자가 다음 각호의 사유에 해당하는 경우 회사는 판매자와의 이용계약을 해지할 수 있습니다.
              <ul>
                <li>판매자가 이 약관에 위배되는 행위를 하거나 이 약관 및 운영원칙에 따른 해지사유가 발생한 경우</li>
                <li>판매자에게 제 5조에서 정한 이용계약의 승낙거부사유가 있음이 확인된 경우</li>
                <li>관련 법령을 위반하거나 판매자의 책임 있는 사유로 인해 회사가 명예 실추 등 유/무형적 손해를 입은 경우</li>
                <li>기타 회사가 합리적인 판단에 의하여 판매자에 대한 서비스의 제공을 거부할 필요가 있다고 인정할 경우</li>
              </ul>
            </li>
            <li>이용계약의 해지에도 불구하고 판매자는 해지 시까지 완결되지 않은 주문건의 배송, 교환, 환불에 필요한 조치를 취하여야 하며, 해지 이전에 이미 판매한 상품과 관련하여 발생한 판매자의 책임과 관련된 조항은 그 효력을 유지합니다.</li>
            <li>이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 판매자가 책임을 부담하여야 하고, 회사는 관련 법령에 규정이 없는 한 책임을 지지 않습니다.</li>
          </ol>
        </dd>

        <dt>제22조 (개인정보)</dt>
        <dd>
          <ol>
            <li>판매자는 서비스의 이용에 따라 취득한 구매자 등 타인의 개인정보를 법률, 본 약관 또는 회사의 개인정보처리방침에서 정한 목적 이외의 용도로 사용하거나 제 3자에게 제공하는 등 외부에 유출할 수 없으며, 관련 법령 등에 따라 철저히 보호하여야 합니다.</li>
            <li>판매자는 회사가 제공하는 구매자의 정보에 대해 기본 계약의 제공 목적에 직접 해당하는 업무 외에 다른 용도로 사용할 수 없으며, 제3자에게 임의로 제공하거나 누설하지 않습니다.</li>
            <li>회사는 주문처리를 목적으로 판매자에게 공개되어 있는 구매자의 개인정보를 회사의 정책에 따라 상당 기간이 경과한 후 비공개 조치할 수 있습니다.</li>
            <li>회사는 관련 법령에 따라 판매자가 1년 동안 서비스를 이용(로그인)하지 않으면 해당 판매자의 계정을 휴면 계정으로 전환하고 개인정보를 분리하여 보관합니다. 개인정보 분리 보관 이후에도 판매자가 4년 동안 서비스에 로그인 하지 않으면 해당 판매자의 개인정보를 일체 파기하고 회원 탈퇴 처리합니다.</li>
            <li>판매자가 본 조를 위반하여 구매자 등으로부터 분쟁이 발생하는 경우 자신의 노력과 비용으로 회사를 면책시켜야 하며, 민/형사 상 일체의 법적 책임을 부담하여야 합니다.</li>
          </ol>
        </dd>

        <dt>제23조 (대리 및 보증의 부인)</dt>
        <dd>
          <ol>
            <li>회사는 구매자와 판매자 간의 자유로운 상품 또는 서비스의 거래를 위한 시스템을 운영 및 관리, 제공할 뿐이며, 판매자 또는 구매자를 대리하지 않습니다.</li>
            <li>회사는 마켓나우를 통해 이루어지는 판매자와 구매자 간의 거래와 관련하여 판매 의사 또는 구매 의사의 존부 및 진정성, 상품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비침해성, 구매자 또는 판매자가 입력한 정보의 진실성 또는 적법성 등 일체에 대해 보증하지 아니하며, 이와 관련한 일체의 위험은 해당 판매자 또는 구매자가 전적으로 부담합니다.</li>
            <li>회사는 구매자에게 재화 또는 서비스를 판매하거나 판매자로부터 상품을 구매하지 않으며, 단지 판매자와 구매자간의 상품의 거래의 편의성을 증진시키는 도구만을 개발 및 제공합니다.</li>
            <li>회사는 판매자가 서비스를 통해 취급하는 상품의 판매효과에 대하여 어떠한 보증도 제공하지 아니합니다.</li>
          </ol>
        </dd>

        <dt>제24조 (면책조항)</dt>
        <dd>
          <ol>
            <li>회사는 구매자와 판매자간의 통신판매를 위한 거래시스템만을 제공할 뿐, 판매자가 등록한 상품에 관한 정보 또는 구매자와의 거래에 관하여 분쟁이 발생한 경우 회사는 그 분쟁에 개입하지 않으며 그 분쟁의 결과로 인한 모든 책임은 판매자가 부담합니다. 또한 제3자가 회사를 상대로 민•형사상 등의 문제를 제기하는 경우 판매자는 해당 문제해결을 위해 적극 협조하여야 하며, 이와 관련하여 회사에 손해가 발생한 경우 손해를 배상합니다.</li>
            <li>회사는 적법한 권리자의 요구가 있는 경우에는 해당 상품에 관한 정보를 삭제하거나 수정할 수 있으며, 판매자는 이로 인한 손해배상을 회사에 청구할 수 없습니다.</li>
            <li>회사는 관련 법령에 의거하여 판매자의 정보를 열람할 수 있는 방법을 구매자에게 제공할 수 있으며, 판매자는 해당 정보를 기재하지 아니하거나, 허위로 기재함으로써 발생하는 모든 책임을 부담하여야 합니다.</li>
            <li>판매자가 자신의 개인정보 또는 가맹점 앱의 로그인 정보를 타인에게 유출 또는 제공함으로써 발생하는 피해에 대해 회사는 책임을 지지 않습니다.</li>
            <li>기타 관련 법령 및 회사에서 제공한 이용계약의 변경, 판매자 공지사항 등의 확인의무를 게을리하여 발생한 판매자의 피해에 대해서 회사는 책임을 지지 않습니다.</li>
          </ol>
        </dd>

        <dt>제25조 (비밀유지)</dt>
        <dd>
          <ol>
            <li>‘판매자는 법령상 요구되는 경우를 제외하고는 구매자 정보 등 서비스 이용과 관련하여 취득한 일체의 정보 및 회사의 기술정보, 생산 및 판매계획, 노하우 등 비밀로 관리되는 정보를 제3자에게 누설하거나 이용계약 이외의 목적으로 사용할 수 없습니다.</li>
            <li>전항의 의무는 이용계약의 종료 후에도 존속합니다.</li>
          </ol>
        </dd>

        <dt>제26조 (분쟁의 해결)</dt>
        <dd>
          <ol>
            <li>본 약관은 대한민국 법령에 의하여 규정되고 이행되며, 마켓나우와 관련하여 회사와 판매자 간에 발생한 분쟁의 제1심 관할법원은 회사의 주된 사무소를 관할하는 법원으로 합니다.</li>
          </ol>
        </dd>

      </dl>

      <p>[부칙]</p>

      <p>이 약관은 2021년 02월 08일부터 적용됩니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermUse"
}
</script>

<style scoped>

</style>