<template>
  <transition name="modal" class="all-content" appear>
    <div class="confirm-wrap modal-confirm-overlay" @click.self="$emit('close')">
      <div class="modal-confirm">
        <p class="confirm-layer-btn">
          <button type="button" class="btn btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
        </p>
        <div class="confirm-layer-box">
          <p>{{ askConfirmTxt }}</p>
          <p class="sub-txt" v-show="askConfirmTxt === '해당 댓글을 신고하시겠습니까?'">
            {{ askSubTxt }}
          </p>
          <div class="content-btn">
            <button type="button" class="btn-left btn-confirm btn-outline-pink" @click="$emit('close')">취소</button>
            <button type="button" class="btn-right btn btn-confirm btn-pink" v-show="askConfirmTxt === '주문 확인하시겠습니까?'"
                    @click="confirm($event, 'order')">
              확인
            </button>
            <button type="button" class="btn-right btn btn-confirm btn-pink" v-show="askConfirmTxt === '픽업 요청하시겠습니까?'"
                    @click="confirm($event, 'pickup')">
              확인
            </button>
            <button type="button" class="btn-right btn btn-confirm btn-pink" v-show="askConfirmTxt === '선택하신 상품을 삭제하시겠습니까?'
            || askConfirmTxt === '작성하신 상품을 저장하시겠습니까?'
            || askConfirmTxt === '주문 취소하시겠습니까?'
            || askConfirmTxt === '상품 분류를 완료하시겠습니까?'
            || askConfirmTxt === '해당 댓글을 신고하시겠습니까?'"
                    @click="confirm($event, 'many')">
              확인
            </button>
            <button type="button" class="btn-right btn btn-confirm btn-pink" v-show="askConfirmTxt === '선택하신 상품 분류를 삭제하시겠습니까?'
            || askConfirmTxt === '1:1 문의 답변을 삭제하시겠습니까?'"
                    @click="confirm($event, 'delete')">
              확인
            </button>
            <button type="button" class="btn-right btn btn-confirm btn-pink" v-show="askConfirmTxt === '1:1 문의 답변을 등록하시겠습니까?'"
                    @click="confirm($event, 'register')">
              확인
            </button>
            <button type="button" class="btn-right btn btn-confirm btn-pink" v-show="askConfirmTxt === '답글을 삭제 하시겠습니까?'"
                    @click="confirm($event, 'deleteReview')">
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
export default {
  name: "Confirm",
  props: {
    askConfirmTxt: String,
    askSubTxt: String,
    boxIndex: Number,
    orderDetailIdx: Number,
    idxDelete: Number,
    question: Object,
    answer: String,
    reviewIdx: Number,
    listIdx: Number,
  },

  methods: {
    async confirm(e, str) {
      await e.currentTarget.setAttribute('disabled', true)
      if(str === 'pickup') {
        this.$emit('checkPickupConfirm', this.boxIndex, this.orderDetailIdx)
      } else if (str === 'order') {
        this.$emit('checkOrderConfirm', this.boxIndex, this.orderDetailIdx)
      } else if (str === 'many') {
        this.$emit('confirm')
      } else if (str === 'delete') {
        this.$emit('confirmDelete', this.idxDelete)
      } else if(str === 'register') {
        this.$emit('confirmSend', this.question, this.answer)
      } else if(str === 'deleteReview') {
        this.$emit('confirmDeleteReview', this.reviewIdx, this.listIdx)
      }
    }
  }
}
</script>

<style scoped>

</style>
