<template>
  <div id="app">

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <div id="alert-wrap" class="alert-wrap"></div>

  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    let token = localStorage.getItem('accessToken')
    if(token) {
      this.refreshToken()
    }
  },

  beforeCreate() {
    let console = (function(oldCons){
      return {
        log: function(){
          if (process.env.VUE_APP_DEBUG === "1") {
            oldCons.log.apply(console, arguments)
          }
        }
      };
    }(window.console));
    window.console = console
  }

}
</script>

<style>

</style>
