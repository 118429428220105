<template>
  <div>
    <!--    주문 내역 없을 때-->
    <noContent v-show="orders.length === 0" title="주문요청이 없습니다!"></noContent>
    <!--    주문 내역 있을 때-->
    <div class="dashboard-content" v-show="orders.length > 0">
      <Modal :askConfirmTxt=askConfirmTxt v-if="openModal" :boxIndex=boxIndex :orderDetailIdx="orderDetailIdx"
             @close="openModal = false" @checkOrderConfirm = checkOrderConfirm @checkPickupConfirm = checkPickupConfirm></Modal>
      <div class="content-box cursor" v-for="(order, idxOrder) in orders" :key="order.orderDetailIdx"
           @click="$router.push({ name: 'OrderDetail', params: { orderDetailIdx: order.orderDetailIdx } })">
        <p class="order-code">{{ order.orderCode }}</p>
        <p class="order-time">
          <span class="order-time-title">주문시간</span><span>{{ order.regDate }}</span><br>
          <span class="order-time-title">배달시간</span><span>{{ order.deliveryTime }}</span>
        </p>
        <div>
          <router-link :to="{name: 'OrderCancel'}" class="btn-round_del" v-show="order.orderState !== '1'">
            <i class="icon-close" @click.stop.prevent="$router.push({ name: 'OrderCancel', params: { orderDetailIdx: order.orderDetailIdx }})"></i>
          </router-link>
          <p class="product-set product-set-order" v-for="(list, idxList) in orders[idxOrder].list" :key="idxList">
            <span class="product">{{ list.orderProductName }}</span>
            <span class="option" v-for="(option, idxOption) in orders[idxOrder].list[idxList].options" :key="idxOption">
              {{ option.optionName }}
            </span>
          </p>
        </div>
        <div class="content-btn dynamic-btn">
          <button type="button" class="btn-left btn" :class="{ 'btn-dgray': order.orderState === '1', 'btn-outline-pink': order.orderState === '0'}"
                  @click.stop.prevent="order.orderState === '0' ? openModal = true : openModal = false;
                  askConfirmTxt = '주문 확인하시겠습니까?'; boxIndex=idxOrder; orderDetailIdx=order.orderDetailIdx">
            {{ order.orderState === '0' ? '주문요청' : '주문요청완료' }}
          </button>
          <button type="button" class="btn-right btn" :class="{ 'btn-gray': order.orderState === '0', 'btn-pink': order.orderState === '1'}"
                  @click.stop.prevent="order.orderState === '1' ? openModal = true : openModal = false;
                   askConfirmTxt = '픽업 요청하시겠습니까?'; boxIndex=idxOrder; orderDetailIdx=order.orderDetailIdx">
            픽업요청
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DashboardOrder",

  data() {
    return {
      openModal: false,
      askConfirmTxt: '',
      boxIndex: '',
      orders: [],
      menus: '',
      orderDetailIdx: '',
    }
  },
  methods: {
    checkOrderConfirm(boxIndex, orderDetailIdx) {
      let indexStr = orderDetailIdx.toString()

      if(this.askConfirmTxt === '주문 확인하시겠습니까?') {
        if(boxIndex === this.boxIndex) {

          this.$post(this.$SHOP_ORDER +'/'+ indexStr, 'order_dashboardOrder', null, true,(result)=> {
            console.log('order request', result)
            this.getData()
            this.openModal = false
            this.notify('success', '주문 요청이 완료되었습니다')
            let cancelBtns = document.querySelectorAll('.btn-round_del')
            cancelBtns[boxIndex].classList.add('inactive')
          }, (result) => {
            console.log('fail',result)
            this.openModal = false
            this.notify('error', result.data.error)
          })
        }
      }
    },

    checkPickupConfirm(boxIndex, orderDetailIdx) {
      let indexStr = orderDetailIdx.toString()
      if(this.askConfirmTxt === '픽업 요청하시겠습니까?') {
        if(boxIndex === this.boxIndex) {
          let formData = new FormData()
          formData.append('orderDetailIdx', indexStr)
          this.$post(this.$SHOP_ORDER + '/' + indexStr + '/pickup', 'orderPickup_dashboardOrder', formData, true, result => {
            console.log(result)
            this.getData()
            this.openModal = false
            this.notify('success', '픽업 요청이 완료되었습니다')
          }, (result) => {
            console.log(result.data.error)
            this.openModal = false
            this.notify('error', result.data.error)
          })
        }
      }
    },

    getData() {
      this.$get(this.$SHOP_ORDER, 'order_dashboardOrder', true,result => {
        console.log('orders', result)
        this.orders = result.data
        this.$mount()
      }, (result) => {
        console.log('fail', result)
      })
    }
  },

  mounted() {
    this.getData()
  },

  watch: {
    '$parent.isOrderActive': {
      handler() {
        if(this.$parent.isOrderActive === true) {
          this.getData()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
