<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'MypageMain' })"><i class="icon-back"></i></button>
      <p>매장정보설정</p>
    </header>
    <div class="mypage-content">
      <div class="mypage-shop-info">
        <div class="mypage-setup-box">
          <span class="subject">매장운영</span>
          <div>
            <span class="info">휴무일 경우 Off로 설정</span>
            <p class="toggle-group" @click="operationToggle"><span class="btn-toggle" :class="{ on: isShopOpen, off: !isShopOpen }"></span></p>
          </div>
        </div>
<!--        <div class="mypage-setup-box">-->
<!--          <span class="subject">임시휴무</span>-->
<!--          <div>-->
<!--            <span class="info">임시휴무일 경우 Off로 설정</span>-->
<!--            <p class="toggle-group" @click="tempOffToggle"><span class="btn-toggle" :class="{on: isTempOpen, off: !isTempOpen}"></span></p>-->
<!--          </div>-->
<!--        </div>-->
        <div class="mypage-setup-box">
          <span class="subject">매장코드</span>
          <span class="shop-code">{{ shopConfig.code }}</span>
        </div>
        <div class="mypage-setup-box">
          <span class="subject">휴대폰 번호</span>
          <div>
            <span class="phone-number">{{ phone | phoneNumber }}</span>
            <router-link :to="{ name: 'PhoneNumberChange' }" class="btn btn-mini btn-outline-pink">
              변경 인증
            </router-link>
          </div>
        </div>
      </div>
      <div class="mypage-content-list mypage-content-setup bg-white">
        <div class="mypage-box" @click="$router.push({ name: 'ShopInfo', params: { randomVal: Math.random().toString() }})">
          <span>매장정보 변경</span>
          <span class="btn-arrow-right">
            <i class="icon-arrow-right"></i>
          </span>
        </div>
        <div class="mypage-box" @click="$router.push({name: 'MypageResetPassword'})">
          <span>비밀번호 변경</span>
          <span class="btn-arrow-right">
            <i class="icon-arrow-right"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="fnb-btn-group">
      <button class="full-button" @click="$router.push({name: 'Login'})">로그아웃</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MypageSetup",
  data() {
    return{
      isShopOpen: true,
      // isTempOpen: true,
      shopConfig: {
        code: '',
      },
      phone: '',
    }
  },
  mounted() {
    this.$get(this.$SHOP_CONFIG, 'config_mypageSetup', true, result => {
      console.log('get SHOP_CONFIG', result)
      this.shopConfig = result.data
      this.isShopOpen = this.shopConfig.open
      // this.isTempOpen = this.shopConfig.open
      if(this.shopConfig.phone !== null) {
        this.phone = this.shopConfig.phone
      }
    }, result => {
      console.log(result)
    })
  },

  methods: {
    operationToggle() {
      this.isShopOpen = !this.isShopOpen

      let formData = new FormData
      formData.append('state', this.isShopOpen)

      this.$put(this.$SHOP_STATE, 'state_mypageSetup', formData, true, result => {
        this.notify('success', result.data)
      }, result => {
        console.log(result)
      })
    },

    tempOffToggle() {
      // this.isTempOpen = !this.isTempOpen
      //
      // let formData = new FormData
      // formData.append('state', this.isTempOpen)

      // this.$put(this.$SHOP_STATE, formData, true, result => {
      //   this.notify('success', result.data)
      // }, result => {
      //   console.log(result)
      // })
    }
  },

}
</script>

<style scoped>

</style>
