<template>
  <div class="all-content">
    <Lnb v-show="showLnb" @closeLnb="showLnb = false"></Lnb>
    <AlarmList v-show="showAlarm" @closeAlarm="showAlarm = false"></AlarmList>
      <Header title="주문관리" @openLnb="this.openLnb" @openAlarm="this.openAlarm"></Header>
      <div class="dashboard-wrap scroll">
        <div class="tab-wrap">
          <div class="tab-menu">
            <span class='cursor' :class="{ 'active': isOrderActive }" @click="isOrderActive = true; isPickupActive = false"><a>주문요청</a></span>
            <span class='cursor' :class="{ 'active': isPickupActive }" @click="isOrderActive = false; isPickupActive = true"><a>픽업요청</a></span>
          </div>
        </div>
        <Order v-show="isOrderActive"></Order>
        <Pickup v-show="isPickupActive"></Pickup>
      </div>
  </div>
</template>

<script>
import Order from './dashboard_order'
import Pickup from './dashboard_pickup'
import { mixin } from "@/commonjs/mixin"

export default {
  name: "Dashboard",
  mixins: [mixin],
  components: {
    'Order': Order,
    'Pickup': Pickup,
  },

  data() {
    return {
      isOrderActive: true,
      isPickupActive: false,
      showLnb: false,
      showAlarm: false,
    }
  },

  mounted() {
    if(this.$route.params.backToPickup === 'true') {
      this.isPickupActive = true
      this.isOrderActive = false
    }
  }
}
</script>

<style scoped>

</style>
