<template>
  <div class="all-content">
    <Lnb v-show="showLnb" @closeLnb="showLnb = false"></Lnb>
    <AlarmList v-show="showAlarm" @closeAlarm="this.closeAlarm"></AlarmList>
      <Header title="매출관리" @openLnb="this.openLnb" @openAlarm="this.openAlarm"></Header>
      <div class="dashboard-wrap sales-manage scroll">
        <div class="tab-wrap">
          <div class="tab-menu">
            <span class='cursor' :class="{ 'active': isSalesListActive }" @click="isSalesListActive = true; isOrderListActive = false"><a>매출내역</a></span>
            <span class='cursor' :class="{ 'active': isOrderListActive }" @click="isSalesListActive = false; isOrderListActive = true"><a>주문내역</a></span>
          </div>
        </div>
        <SalesList v-show="isSalesListActive"></SalesList>
        <OrderList v-show="isOrderListActive"></OrderList>
      </div>
  </div>
</template>

<script>
import SalesList from './sales_details_sales_list'
import OrderList from './sales_details_order_list'
import {mixin} from "@/commonjs/mixin"

export default {

  name: "SalesDetails",
  mixins: [mixin],
  components: {
    'SalesList': SalesList,
    'OrderList': OrderList,
  },
  data() {
    return {
      noSales: false,
      showOpenBtn: false,
      showSearch: true,
      showLnb: false,
      showAlarm: false,
      isSalesListActive: true,
      isOrderListActive: false,
    }
  },


}
</script>

<style scoped>

</style>
