<template>
  <div>
    <div class="sales-content-open cursor" v-show="showOpenBtn" @click="toggleSearch">
      <a>
        <img src="/images/jangboda_shop_btn_sales_content_open.svg" alt="총 매출 검색 열기" />
      </a>
    </div>
    <div>
      <div class="sales-search01">
        <p class="sales-content-set">
          <span class="sales-input01"><date-picker class="input-box" v-model="previousMonth" :config="this.calendarOptions"></date-picker></span>
          <span class="sales-date-line">-</span>
          <span class="sales-input01"><date-picker class="input-box" v-model="today" :config="this.calendarOptions"></date-picker></span>
          <a class="btn btn-search btn-outline-pink" @click="search">검색</a>
        </p>
        <p class="sales-content-set sales-content-set01">
          <label class="sales-select-label">결제수단</label>
          <span class="select-wrap sales-select">
            <select class="select" @change="search" v-model="paymentNo">
              <option value="">전체</option>
              <option v-for="(item, idx) in payments" :key="idx" :value="item.type">{{ item.name }}</option>
            </select>
          </span>
        </p>
        <div class="sales-content-set total-sales" >
          <p class="sales-total">
            <span class="sales-total-title">총 매출</span>
            <span class="sales-total-pay">{{ this.totalSales | numberWithCommas }}원</span>
          </p>
          <p class="sales-sub-pay-box">
            <span>카드 결제</span>
            <span class="sales-sub-pay">{{ this.cardSales | numberWithCommas}}원</span>
          </p>
          <p class="sales-sub-pay-box">
            <span>계좌 이체</span>
            <span class="sales-sub-pay">{{ this.transferSales | numberWithCommas}}원</span>
          </p>
          <p class="sales-sub-pay-box">
            <span>온누리 모바일상품권 결제</span>
            <span class="sales-sub-pay">{{ this.giftCardSales | numberWithCommas}}원</span>
          </p>
          <p class="sales-sub-pay-box">
            <span>만나서 현금 결제</span>
            <span class="sales-sub-pay">{{ this.cashSales | numberWithCommas}}원</span>
          </p>
        </div>
        <div class="sales-content-close" @click="toggleSearch">
          <a class="cursor">
            <img src="/images/jangboda_shop_btn_sales_content_close.svg" alt="총 매출 검색 닫기" />
          </a>
        </div>
      </div>
      <div class="dashboard-content sales-content01">
        <div class="content-box" v-for="(order, idx) in orderList" :key="idx">
          <p class="order-code">{{ order.orderCode }}</p>
          <p class="order-time">
            <span class="order-time-title">주문시간</span><span>{{ order.orderTime }}</span>
          </p>
          <div>
            <p class="product-set">
              <span class="sales-product">{{ order.productName }}</span>
              <span class="sales-pay">{{ order.total | numberWithCommas}}원</span>
              <span class="sales-option">결제수단 : {{ order.paymentType }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SalesDetails",

  data() {
    return {
      showOpenBtn: false,
      showSalesDetails: true,
      showLnb: false,
      showAlarm: false,
      isSalesListActive: true,
      isOrderListActive: false,
      today: '',
      previousMonth: '',
      payments: '',
      paymentNo: '',
      cardSales: '',
      cashSales: '',
      giftCardSales: '',
      transferSales: '',
      totalSales: '',
      orderList: '',
      date: new Date(),
    }
  },

  mounted() {
    this.getDates()
    this.getPayments()
    this.search()
  },

  methods: {
    toggleSearch() {
      if(this.showOpenBtn) {
        this.showOpenBtn = !this.showOpenBtn
        window.$('.sales-search01').slideDown()
      } else {
        setTimeout(() => this.showOpenBtn = !this.showOpenBtn, 300)
        window.$('.sales-search01').slideUp()
      }
    },

    getDates() {
      this.today = this.date.getFullYear() + "-" + (this.date.getMonth() + 1) + '-' + this.date.getDate()
      let minusMonth = this.date.setMonth(this.date.getMonth() - 1)
      let monthBefore = new Date(minusMonth)
      this.previousMonth = monthBefore.getFullYear() + "-" + (monthBefore.getMonth() + 1) + "-" + monthBefore.getDate()
    },

    getPayments() {
      this.$get(this.$SHOP_SALES_PAYMENTS, 'salesPayments_salesList', true, (result) => {
        console.log('payments', result.data)
        this.payments = result.data
      }, (result) => {
        console.log(result)
      })
    },

    search() {
      let url = this.$SHOP_SALES + '?startDate=' +this.previousMonth+ '&endDate=' +this.today
      if (this.paymentNo !== '') {
        url += '&paymentType=' + this.paymentNo
      }

      this.$get(url, 'sales_salesList', true, (result) => {
        console.log('sales2', result.body.data)
        let data = result.body.data
        this.orderList = result.body.data.list
        this.cardSales = data.card
        this.cashSales = data.cash
        this.giftCardSales = data.giftCard
        this.transferSales = data.transfer
        this.totalSales = data.total
      },(result) => {
        console.log(result)
      })
    },

  }
}
</script>

<style scoped>

</style>
