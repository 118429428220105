<template>
  <div>
    <Modal :askConfirmTxt=askConfirmTxt v-if="openModal" @close="openModal = false" @confirm="confirm"></Modal>
    <div class="all-content" style="overflow: unset">
      <header class="header-wrap">
        <button type="button" class="btn btn-back" @click="$router.push({ name: 'ProductSetup' })"><i class="icon-back"></i></button>
        <p>상품상세</p>
      </header>
      <div class="product-content">
        <div class="form-content">
          <div class="form-content-set">
            <label class="title">상품명</label>
            <p>
              <input type="text" class="input-box" v-model="productName">
            </p>
          </div>
          <div class="form-content-set">
            <label class="title">상품사진</label>
            <!-- 기존 업로드 부분: -->
            <div class="photo-container mb-10" v-for="(item, idx) in productImg" :key="idx">
              <img class="photo img-box" v-show="showPhoto" :src="item.image" alt="상품 이미지">
              <p class="photo">이미지 미리보기</p>
              <p>
                <span class="input_file">
                  <input type="text" class="input-box file-name-box" :value="item.imageName" disabled>
                  <button type="button" class="btn input-delete" @click="deleteFile($event, item, idx)">
                    <i class="icon-close"></i>
                  </button>
                </span>
              </p>
            </div>
            <!-- :기존 업로드 부분 -->
            <!-- 신규 업로드 부분: -->
            <div class="photo-container mb-10 photo-container-new" v-for="(item, idx) in addImgs" :key="'new' + idx">
              <img class="photo img-box" v-show="files[idx]" :src="item.image" alt="상품 이미지">
              <p class="photo">이미지 미리보기</p>
              <p>
                <span class="input_file">
                  <input type="text" class="input-box file-name-box" :value="item.imageName" disabled>
                  <button type="button" class="btn input-delete" @click="deleteFile1($event, idx)">
                    <i class="icon-close"></i>
                  </button>
                </span>
                <span class="upload-icon" v-show="!files[idx]">
                  <button type="button" class="form-content-button">
                    <input type="file" class="input-box custom-input input-file" accept="image/*"
                           @change.once="upload($event, idx)">
                    <img src="/images/jangboda_shop_icon_file.svg" alt="파일 아이콘" />
                  </button>
                </span>
              </p>
            </div>
            <!-- :신규 업로드 부분 -->
            <p @click="addImg" v-show="(this.productImg.length + this.addImgs.length) < 5">
              <button type="button" class="btn btn-outline-pink mt-5">+ 상품사진 추가</button>
            </p>
          </div>
          <div class="form-content-set">
            <label class="title">간단설명</label>
            <p><textarea class="textarea-line-2" placeholder="공백 포함 52자 이내" v-model="memo"></textarea></p>
          </div>
          <div class="form-content-set">
            <label class="title">판매가격</label>
            <p>
              <span class="input-pay-full"><input type="number" class="input-box" v-model="price"></span>
              <span class="input-pay-unit">원</span>
            </p>
          </div>
          <div class="form-content-set">
            <div class="toggle-group-title">
              <label class="toggle-group-label">상품 옵션</label>
              <p class="toggle-group" @click="toggleOption"><span class="btn-toggle" :class="{ on: isOptionOn, off: !isOptionOn }"></span></p>
            </div>
            <div v-show="isOptionOn">
              <p v-for="(item, idx) in productOption" :key="idx">
                <span class="input-item">
                  <input type="text" class="input-box" v-model="item.option" @change="inputOptionName($event, idx)">
                  <button type="button" class="btn input-delete" @click="productOption.splice(idx, 1)"><i class="icon-close"></i></button>
                </span>
                <span class="input-pay">
                  <span>+</span>
                  <input type="number" class="input-box" v-model="item.optionPrice" @change="inputOptionPrice($event, idx)">
                </span>
                <span class="input-pay-unit">원</span>
              </p>
              <p @click="productOption.push({ option: '', optionPrice: 0 })"><button type="button" class="btn btn-outline-pink mt-5">+ 항목 추가</button></p>
            </div>
          </div>
          <div class="form-content-set">
            <div class="toggle-group-title">
              <label class="toggle-group-label">추가 옵션</label>
              <p class="toggle-group" @click="isAddOptionOn = !isAddOptionOn"><span class="btn-toggle" :class="{on: isAddOptionOn, off: !isAddOptionOn}"></span></p>
            </div>
            <div v-show="isAddOptionOn">
              <p v-for="(item, index) in productAddOption" :key="index">
                <span class="input-item">
                  <input type="text" class="input-box" v-model="item.option" @change="inputAddOptionName($event, index)">
                  <button type="button" class="btn input-delete" @click="productAddOption.splice(index, 1)"><i class="icon-close"></i></button>
                </span>
                <span class="input-pay">
                  <span>+</span>
                  <input type="number" class="input-box" v-model="item.optionPrice" @change="inputAddOptionPrice($event, index)"></span>
                <span class="input-pay-unit">원</span>
              </p>

              <p @click="productAddOption.push({ option: '', optionPrice: 0 })"><button type="button" class="btn btn-outline-pink mt-5">+ 항목 추가</button></p>
            </div>

          </div>

          <div class="form-content-set">
            <div class="toggle-group-title">
              <label class="toggle-group-label">상품분류</label>
              <p class="toggle-group"><span class="btn-toggle on"></span></p>
            </div>
            <div>
              <p>
              <span class="select-wrap product-select">
                <select class="select" v-model="productClassification">
                  <option v-for="(classification, idx) in classifications" :key="idx" :value="classification.idx">{{ classification.name }}</option>
                </select>
              </span>
              </p>
              <p><keep-alive>
                <router-link :to="{name: 'ProductClassify', params: { productIdx: productIdx, randomVal1: $route.params.randomVal1 }}" class="btn btn-outline-pink mt-5">분류 편집</router-link>
              </keep-alive></p>
            </div>

          </div>
        </div>
        <div class="form-content">
          <div class="form-content-set">
            <div class="toggle-group-title">
              <label class="toggle-group-label">상품수량</label>
              <p class="toggle-group" @click="quantityBool = !quantityBool">
                <span class="btn-toggle" :class="{ on: quantityBool === true, off: quantityBool === false }"></span>
              </p>
            </div>
            <p>
              <label>
                <input type="number" class="input-box quantity" v-model="quantity" :disabled="quantityBool === false">
              </label>
            </p>
          </div>
          <div class="form-content-set">
            <label class="title">원산지</label>
            <p>
              <label>
                <textarea class="textarea-line-2" v-model="countryOfOrigin"></textarea>
              </label>
            </p>
          </div>
          <div class="form-content-set">
            <label class="toggle-group-label">카테고리</label>
            <label v-show="categoryArr.length === 2">
              <input type="text" class="input-box" disabled v-model="categoryOwnBrand">
            </label>
            <p>
              <span class="select-wrap product-select">
                <label>
                  <select class="select" v-model="category">
                    <option v-for="(item, idx) in categories" :key="idx" :value="item.idx">{{ item.name }}</option>
                  </select>
                </label>
              </span>
            </p>
<!--            <p><button type="button" class="btn btn-outline-pink mt-5">+카테고리 추가</button></p>-->
          </div>
        </div>
        <div class="form-content">
          <div class="form-content-set">
            <label class="title">상세설명</label>
            <p><textarea class="textarea-line-3" v-model="description"></textarea></p>
          </div>
          <div class="form-content-set">
            <label class="toggle-group-label">공급가</label>
            <p>
              <span class="input-pay-full"><input type="number" class="input-box" v-model="supplyPrice"></span>
              <span class="input-pay-unit">원</span>
            </p>
          </div>
          <div class="form-content-set">
            <label class="toggle-group-label">소비자가</label>
            <p>
              <span class="input-pay-full"><input type="number" class="input-box" v-model="consumerPrice"></span>
              <span class="input-pay-unit">원</span>
            </p>
          </div>
          <div class="form-content-set faq-container">
            <label class="title">FAQ</label>
            <div v-for="(faq, faqIdx) in faqs" :key="faqIdx" class="mb-13">
              <label>
                <input type="text" class="input-box" v-model="faq.faqTitle" @change="inputFaqTitle($event, faqIdx)" placeholder="질문">
              </label>
              <button type="button" class="btn input-delete" @click="deleteFaq(faq, faqIdx)">
                <i class="icon-close"></i>
              </button>
              <label>
                <textarea class="textarea-line-3" v-model="faq.faqContent" @change="inputFaqContent($event, faqIdx)" placeholder="답변"></textarea>
              </label>
            </div>
            <p @click="faqs.push({ faqTitle: '', faqContent: '', faqIdx: '' })"><button type="button" class="btn btn-outline-pink">+FAQ 추가</button></p>
          </div>
        </div>
      </div>
    </div>
    <div class="fnb-btn-group">
      <button type="button" class="darkgray" @click="$router.push({name: 'ProductSetup'})">목록</button>
      <button type="button" @click="openModal = true">저장</button>
    </div>
  </div>

</template>

<script>

export default {
  name: "ProductDetail",

  data() {
    return {
      showPhoto: false,
      isAddOptionOn: true,
      isOptionOn: true,
      addOptionEx: true,
      category: '',
      categories: [],
      memo: '',
      optionName: '',
      addName: '',
      productDetail: '',
      productName: '',
      productImg: [],
      price: 0,
      quantity: 0,
      countryOfOrigin: '',
      description: '',
      supplyPrice: 0,
      consumerPrice: 0,
      productClassification: 0,
      productOption: [],
      productAddOption: [],
      classifications: [],
      productIdx: this.$route.params.productIdx,
      askConfirmTxt: '작성하신 상품을 저장하시겠습니까?',
      openModal: false,
      files:[],
      addImgs:[],
      isPending: false,
      quantityBool: true,
      faqs: [],
      categoryArr: [],
      categoryOwnBrand: '',
    }
  },

  methods: {
    toggleOption() {
      this.isOptionOn = !this.isOptionOn
      if(this.productOption.length === 0) {
        this.productOption.push({ 'option': '기본', 'optionPrice': 0 })
      }
    },

    addImg() {
      if(this.addImgs.length === this.files.length) {
        if((this.productImg.length + this.addImgs.length) < 5) {
        this.addImgs.push({ image: '', imageName: '' })
        }
      }
    },

    inputOptionPrice(e, idx) {
      this.productOption[idx].optionPrice = e.currentTarget.value
    },

    inputOptionName(e, idx) {
      this.productOption[idx].option = e.currentTarget.value
    },

    inputAddOptionName(e, idx) {
      this.productAddOption[idx].option = e.currentTarget.value
    },

    inputAddOptionPrice(e, idx) {
      this.productAddOption[idx].optionPrice = e.currentTarget.value
    },

    inputFaqTitle(e, idx) {
      this.faqs[idx].faqTitle = e.currentTarget.value
    },

    inputFaqContent(e, idx) {
      this.faqs[idx].faqContent = e.currentTarget.value
    },

    upload(e, idx) {
      // 파일리스트
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      let fileName = files[0].name
      let photoContainers = document.querySelectorAll('.photo-container-new')
      photoContainers[idx].querySelector('.file-name-box').setAttribute('placeholder', fileName)
      let output = photoContainers[idx].querySelector('.img-box')
      output.src = URL.createObjectURL(files[0])
      this.showPhoto = true

      output.onload = () => {
        // URL.revokeObjectURL(output.src)
        this.addImgs[this.addImgs.length - 1]['image'] = output.src
      }
      this.files.push(files[0])
      this.addImgs[this.addImgs.length - 1]['imageName'] = fileName
    },

    deleteFile(e, item, idx) {
      if(item.idx !== undefined) {
        this.$axios_delete(this.$SHOP_PRODUCT_IMAGE +'/'+ item.idx, 'productImageItem_productDetail', true, (result) => {
          console.log(result)
          this.productImg.splice(idx, 1)
        }, (result) => {
          console.log(result)
        })
      }
    },

    deleteFile1(e, idx) {
      this.files.splice(idx, 1)
      this.addImgs.splice(idx, 1)
    },

    deleteFaq(faq, idx) {
      if(faq.faqIdx !== '') {
        this.$axios_delete(this.$SHOP_FAQ +'/'+ faq.faqIdx,'faq_productDetail', true, (result) => {
          console.log('delete faq', result)
          this.faqs.splice(idx, 1)
          this.notify('success', result)
        }, (result) => {
          console.log(result)
          this.notify('success', result.error)
        })
      } else {
        this.faqs.splice(idx, 1)
      }
    },

    confirm() {
      this.openModal = false
      this.save()
    },

    async save() {
      let formData = new FormData()

      formData.append('countryOrigin', this.countryOfOrigin)
      formData.append('description', this.description)
      formData.append('memo', this.memo)
      formData.append('name', this.productName)
      formData.append('productCategory', this.category)
      formData.append('productClassification', true)
      formData.append('productClassificationIdx', this.productClassification)

      if(this.price >= 0) {
        formData.append('price', this.price)
      }

      if(this.quantity >= 0) {
        formData.append('quantity', this.quantity)
      }

      if(this.consumerPrice >= 0) {
        formData.append('consumerPrice', this.consumerPrice)
      }

      if(this.supplyPrice >= 0) {
        formData.append('supplyPrice', this.supplyPrice)
      }

      this.quantityBool === true ? formData.append('quantityUse', true) : formData.append('quantityUse', false)

      if(this.isOptionOn === true && this.productOption.length !== 0) {
        formData.append('productPriceOption', true)
        this.productOption.forEach((option, idx) => {
          if(option.optionPrice >= 0) {
            formData.append(`price_params[${idx}].priceName`, option.option)
            formData.append(`price_params[${idx}].price`, option.optionPrice)
          }
        })
      } else {
        formData.append('productPriceOption', false)
      }

      if(this.isAddOptionOn === true && this.productAddOption.length !== 0) {
        formData.append('productAddOption', true)
        this.productAddOption.forEach((option, idx) => {
          if(option.optionPrice >= 0) {
            formData.append(`option_params[${idx}].optionName`, option.option)
            formData.append(`option_params[${idx}].optionPrice`, option.optionPrice)
          }
        })
      } else {
        formData.append('productAddOption', false)
      }

      for (let i=0;i<this.files.length;i++) {
        formData.append('image', this.files[i])
      }

      this.faqs.forEach((faq, idx) => {
        formData.append(`faqs[${idx}].title`, faq.faqTitle)
        formData.append(`faqs[${idx}].content`, faq.faqContent)
        formData.append(`faqs[${idx}].idx`, faq.faqIdx)
      })

      if(this.consumerPrice !== '' && this.countryOfOrigin !== ''&& this.description !== ''&& this.memo !== ''&&
        this.productName !=='' && this.price !== '' && this.category !== ''&& this.productClassification !== ''&& this.quantity !== '' && this.supplyPrice !== '') {

        if(this.productIdx !== undefined ) {
          this.$put(this.$SHOP_PRODUCT +'/'+ this.productIdx, 'productPut_productDetail', formData, true,(result) => {
            console.log('put product', result)
            this.modal = false
            this.notify('success', result.data)
            this.$router.push({ name: 'ProductSetup' })
          }, (result) => {
            console.log(result)
            this.notify('error', result.error)
          })
        } else {
          this.$post(this.$SHOP_PRODUCT, 'productPost_productDetail', formData, true,(result) => {
            console.log('post product', result)
            this.modal = false
            this.notify('success', result.data)
            this.$router.push({ name: 'ProductSetup' })
          }, (result) => {
            console.log(result)
            this.notify('error', result.data.error)
          })
        }
      } else {
        this.notify('error', '빈 항목들을 입력해주세요')
      }
    },

    getProductDetail() {
      if(this.productIdx !== undefined ) {
        this.$get(this.$SHOP_PRODUCT +'/'+ this.productIdx, 'product_productDetail', true,(result) => {
          console.log('get product detail', result)
          this.productDetail = result.data
          this.price = this.productDetail.price
          this.supplyPrice = this.productDetail.supplyPrice
          this.consumerPrice = this.productDetail.consumerPrice
          this.productName = this.productDetail.name
          this.countryOfOrigin = this.productDetail.countryOfOrigin
          this.description = this.productDetail.description
          this.memo = this.productDetail.memo
          this.quantity = this.productDetail.quantity
          this.productDetail.quantityUse === '0' ? this.quantityBool = false : this.quantityBool = true
          this.productImg = this.productDetail.productImages
          this.productImg.length !== 0 ? this.showPhoto = true : this.showPhoto = false
          this.isOptionOn = this.productDetail.productOption
          this.isAddOptionOn = this.productDetail.addOption
          this.faqs = this.productDetail.faqs
          this.categoryArr = this.productDetail.categories

          if(this.productDetail.productClassifications.length !== 0) {
            this.productClassification = this.productDetail.productClassifications[0].productClassificationIdx
          }

          if(this.productDetail.categories.length === 0) {
            this.category = this.categories[0].idx
          } else if(this.productDetail.categories.length === 1) {
            this.category = this.productDetail.categories[0].idx
          } else {
            this.categoryOwnBrand = this.productDetail.categories[0].name
            this.category = this.productDetail.categories[1].idx
          }

          this.productOption = []
          this.productAddOption = []
          for(let i = 0; i<this.productDetail.options.length; i++) {
            if(this.productDetail.options[i].optionType === '0' || this.productDetail.options[i].optionType === '1') {
              this.productOption.push(this.productDetail.options[i])
            } else if (this.productDetail.options[i].optionType === '2') {
              this.productAddOption.push(this.productDetail.options[i])
            }
          }
        }, (result) => {
          console.log('get product', result)
        })
      } else {
        this.productOption.push({ option: '기본', optionPrice: 0 })
        this.quantity = 0
        this.price = 0
        this.supplyPrice = 0
        this.consumerPrice = 0
      }
    },
    async getCategory() {
      await this.$get(this.$SHOP_PRODUCT_CATEGORIES, 'productCategories_productDetail', true,(result) => {
        this.categories = result.data
        if(this.productIdx === undefined) {
          this.category = this.categories[0].idx
        }
      }, (result) => {
        console.log('category', result)
      })
    },
    async getClassification() {
      await this.$get(this.$SHOP_PRODUCT_CLASSIFICATION, 'productClassification_productDetail', true, (result) => {
        this.classifications = result.data
        if(this.productIdx === undefined) {
          this.productClassification = this.classifications[0].idx
        }
      }, (result) => {
        console.log('get classification fail', result)
      })
    },

    clearDetailData() {
      this.productDetail = {}
      this.price = 0
      this.supplyPrice = 0
      this.consumerPrice = 0
      this.productName = ''
      this.countryOfOrigin = ''
      this.description = ''
      this.memo = ''
      this.quantity = 0
      this.productImg = []
      this.showPhoto = false
      this.productClassification = 0
      this.category = ''
      this.productOption = []
      this.productAddOption = []
      this.isOptionOn = true
      this.isAddOptionOn = true
      this.productIdx = this.$route.params.productIdx
      this.quantityBool = true
      this.faqs = []
      this.categoryArr = []
      this.categoryOwnBrand = ''

      this.openModal = false
      this.files = []
      this.addImgs = []

      if (this.categories.length > 0) {
        this.category = this.categories[0].idx
      }
    },

    reset: async function () {
      console.log('randomVal1 : ' + this.$route.params.randomVal1)
      if(this.isPending) {
        return
      }
      this.isPending = true
      await this.clearDetailData()
      await this.getCategory()
      await this.getClassification()
      await this.getProductDetail()
      this.isPending = false
    }
  },

  mounted: async function () {
    await this.reset()
  },

  watch: {
    '$route.params.productIdx': {
      handler() {
        this.clearDetailData()
        this.getProductDetail()
      }
    },
    '$route.params.randomVal': {
      handler() {
        console.log('randomVal : ' + this.$route.params.randomVal)
        this.getClassification()
      }
    },

    '$route.params.randomVal1': {
      handler: async function() {
        await this.reset()
      }
    }
  },

}
</script>

<style scoped>

</style>
