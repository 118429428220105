import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Login from "../components/login"
import Intro from "../components/intro"
import IntroLogin from "../components/intro_login"
import FindCode from "../components/find_code"
import FindCodeResult from "../components/find_code_result"
import FindPassword from "../components/find_password"
// import Agreement from "../components/agreement"
import Certification from "../components/certification"
import ResetPassword from "../components/reset_password"
import Dashboard from "../components/dashboard/dashboard"
import OrderDetail from "../components/dashboard/order_detail"
import OrderCancel from "../components/dashboard/order_cancel"
import Electronic from "../components/terms/electronic"
import Marketing from "../components/terms/marketing"
import Privacy from "../components/terms/privacy"
import Use from "../components/terms/use"
import SalesDetails from "../components/sales/sales_details"
import PaymentSetup from "../components/payment/payment_setup"
import ProductSetup from "../components/product/product_setup"
import ProductDetail from "../components/product/product_detail"
import ProductClassify from "../components/product/product_classify"
import MypageMain from "../components/mypage/mypage_main"
import MypageSetup from "../components/mypage/mypage_setup"
import MypageAgreement from "../components/mypage/mypage_agreement"
import Notice from "../components/mypage/notice"
import Support from "../components/mypage/support"
import PhoneNumberChange from "../components/mypage/phone_number_change"
import ShopInfo from "../components/mypage/shop_info"
import MypageResetPassword from "../components/mypage/mypage_reset_password"
import AddressChangeStep1 from "../components/mypage/address_change_step1"
import AddressChangeStep2 from "../components/mypage/address_change_step2"
import Review from "../components/mypage/review"
import Management from "../components/calculate/management"
import Adjustment from "../components/calculate/adjustment"
import CalculateDetail from "../components/calculate/detail"

export default new Router({
  routes: [
      {
          path: '/',
          name: 'Intro',
          component: Intro
      },
      {
          path: '/intro',
          name: 'IntroLogin',
          component: IntroLogin
      },
      {
          path: '/login',
          name: 'Login',
          component: Login
      },
      {
          path: '/findcode',
          name: 'FindCode',
          component: FindCode
      },
      {
          path: '/findcode/result',
          name: 'FindCodeResult',
          component: FindCodeResult
      },
      {
          path: '/mypage/electronic',
          name: 'Electronic',
          component: Electronic
      },
      {
          path: '/mypage/marketing',
          name: 'Marketing',
          component: Marketing
      },
      {
          path: '/mypage/privacy',
          name: 'Privacy',
          component: Privacy
      },
      {
          path: '/mypage/use',
          name: 'Use',
          component: Use
      },
      {
          path: '/certification',
          name: 'Certification',
          component: Certification
      },
      {
          path: '/findpassword',
          name: 'FindPassword',
          component: FindPassword
      },
      {
          path: '/findpwd/resetpwd',
          name: 'ResetPassword',
          component: ResetPassword
      },
      {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
      },
      {
          path: '/dashboard/detail',
          name: 'OrderDetail',
          component: OrderDetail,
          props: true
      },
      {
          path: '/dashboard/cancel',
          name: 'OrderCancel',
          component: OrderCancel,
          props: true
      },
      {
          path: '/sales/details',
          name: 'SalesDetails',
          component: SalesDetails
      },
      {
          path: '/payment/setup',
          name: 'PaymentSetup',
          component: PaymentSetup
      },
      {
          path: '/product/setup',
          name: 'ProductSetup',
          component: ProductSetup
      },
      {
          path: '/product/detail',
          name: 'ProductDetail',
          component: ProductDetail,
          props: true,
          meta: { keepAlive: true }
      },
      {
          path: '/product/classify',
          name: 'ProductClassify',
          component: ProductClassify,
          props: true
      },
      {
          path: '/mypage/main',
          name: 'MypageMain',
          component: MypageMain
      },
      {
          path: '/mypage/setup',
          name: 'MypageSetup',
          component: MypageSetup
      },
      {
          path: '/mypage/agreement',
          name: 'MypageAgreement',
          component: MypageAgreement
      },
      {
          path: '/mypage/notice',
          name: 'Notice',
          component: Notice
      },
      {
          path: '/mypage/support',
          name: 'Support',
          component: Support
      },
      {
          path: '/mypage/phone',
          name: 'PhoneNumberChange',
          component: PhoneNumberChange
      },
      {
          path: '/mypage/shop',
          name: 'ShopInfo',
          component: ShopInfo,
          meta: { keepAlive: true }
      },
      {
          path: '/mypage/password',
          name: 'MypageResetPassword',
          component: MypageResetPassword
      },
      {
          path: '/mypage/address1',
          name: 'AddressChangeStep1',
          component: AddressChangeStep1
      },
      {
          path: '/mypage/address2',
          name: 'AddressChangeStep2',
          component: AddressChangeStep2
      },
      {
          path: '/mypage/review',
          name: 'Review',
          component: Review
      },
      {
          path: '/calculate/management',
          name: 'Management',
          component: Management
      },
      {
          path: '/calculate/adjustment',
          name: 'Adjustment',
          component: Adjustment
      },
      {
          path: '/calculate/detail',
          name: 'CalculateDetail',
          component: CalculateDetail
      },


  ]
})
