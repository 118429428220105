export const mixin = {
    data() {
        return {
        }
    },
    methods: {
        async openLnb() {
            await this.refreshLnb()
            this.showLnb = true
        },

        refreshLnb() {
            this.showLnb = null
        },

        async openAlarm() {
            await this.refreshAlarm()
            window.onscroll
            this.showAlarm = true
            // setTimeout(this.scrollTop, 1000)
        },

        refreshAlarm() {
            this.showAlarm = null
        },

        closeAlarm() {
            this.showAlarm = false
            // this.scrollTop()
        },

        // scrollTop() {
        //     window.scrollTo(0,0)
        // }

    }
}
