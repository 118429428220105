<template>
  <div class="all-content bg-white">
      <header class="header-wrap">
        <button type="button" class="btn btn-back" @click="$router.push({ name: 'MypageMain' })"><i class="icon-back"></i></button>
        <p>결제관리</p>
      </header>
      <div class="dashboard-wrap sub-main">
        <div class="payment-content">
          <h3>결제수단 설정</h3>
          <div class="payment-box" v-for="(payment, idx) in payments" :key="idx">
            <span>{{ payment.value }}</span>
            <p class="toggle-group">
              <span class="btn-toggle" :class="{ on : payment.useAt === true, off : payment.useAt === false }"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mixin} from "@/commonjs/mixin"

export default {
  name: "PaymentSetup",
  mixins: [mixin],

  data() {
    return {
      payments: [],
    }
  },
  mounted() {
    this.$get(this.$SHOP_PAYMENTS, 'payments_paymentsSetup', true, (result) => {
      console.log('get payments', result)
      this.payments = result.data
    }, (result) => {
      console.log(result)
    })
  },


}
</script>

<style scoped>

</style>
