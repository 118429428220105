<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <p>조정금액 안내</p>
      <button type="button" class="btn btn-close" @click="goBack"><i class="icon-close"></i></button>
    </header>
    <div class="calculate_txt_wrap">
      <div class="calculate_detail">
        <h5>조정금액이란?</h5>
        <p>계좌 오류나 주문 취소, 보상이나 손실 등의 금액으로 조정이 필요한 금액입니다.<br>조정금액에는 세부적으로 입금이월, 입금제외, 보정금액이 있습니다.</p>
      </div>
      <div class="calculate_detail">
        <h5>1. 입금이월</h5>
        <p>계좌 오류나 입금 보류 등 점주님께서 입금 받지 못한 금액입니다. (+)</p>
      </div>
      <div class="calculate_detail">
        <h5>2. 입금제외</h5>
        <p>고객님의 주문취소와 같은 이유로 입금 받을 수 없는 금액입니다. (-)</p>
      </div>
      <div class="calculate_detail">
        <h5>3. 보정금액</h5>
        <p>고객센터 보상 등 여러가지 보상(+)이나 손실금(-)을 더한 금액입니다.</p>
      </div>
    </div>
    <div class="fnb-btn-group">
      <button class="full-button" @click="goBack">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Adjustment",
  methods: {
    goBack() {
      if(this.$route.params.calculateIdx !== undefined) {
        this.$router.push({ name: 'CalculateDetail', params: { calculateIdx: this.$route.params.calculateIdx }}).catch(()=>{})
      } else {
        this.$router.push({ name: 'Management' }).catch(()=>{})
      }
    },
  }
}
</script>

<style scoped>

</style>
