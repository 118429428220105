<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'Management' })" >
        <i class="icon-arrow-left"></i>
      </button>
      <p>정산명세서</p>
    </header>
    <div class="dashboard-wrap adjustment">
      <div class="top-title">
        <p class="font-bold">정산 금액</p>
      </div>
      <div class="pay-date">
        <p class="font-bold">입금일</p>
        <p class="font-bold">{{ detail.depositDate !== '' ? detail.depositDate : '미정'}}</p>
      </div>
      <hr>
      <div class="adjusted-amount">
        <div>
          <p class="title font-bold">주문금액</p>
          <p class="font-bold title">{{ detail.orderPrice | numberWithCommas }}원</p>
        </div>
        <div>
          <p class="fee">서비스 중개 이용료 1% (VAT 포함)</p>
          <p class="fee">{{ detail.serviceFee | numberWithCommas }}원</p>
        </div>
        <div>
          <p class="fee">배달 대행 이용료 0%</p>
          <p class="fee">{{ detail.deliveryFee | numberWithCommas }}원</p>
        </div>
        <div>
          <p class="fee">PG 결제 이용료 2.9% (VAT 포함)</p>
          <p class="fee">{{ detail.cardFee | numberWithCommas }}원</p>
        </div>
        <div>
          <p class="fee">온누리 모바일 이용료 1.95% (VAT 포함)</p>
          <p class="fee">{{ detail.onnuriFee | numberWithCommas }}원</p>
        </div>
      </div>
      <hr>
<!--      <div class="adjusted-amount adjusted-detail">-->
<!--        <div>-->
<!--          <img src="/images/icon-question-cycle.png" alt="물음표 아이콘" @click="$router.push({ name: 'Adjustment', params: { calculateIdx: $route.params.calculateIdx }})"/>-->
<!--          <p class="title font-bold">조정금액</p>-->
<!--          <p class="font-bold">{{ detail.adjustmentPrice | numberWithCommas }}원</p>-->
<!--        </div>-->
<!--        <div class="mt-10">-->
<!--          <p class="fee">입금이월</p>-->
<!--          <p class="fee">{{ detail.carryOverDeposit | numberWithCommas }}원</p>-->
<!--        </div>-->
<!--        <div>-->
<!--          <p class="fee">입금제외</p>-->
<!--          <p class="fee">{{ detail.excludingDeposit | numberWithCommas }}원</p>-->
<!--        </div>-->
<!--        <div>-->
<!--          <p class="fee">보정금액</p>-->
<!--          <p class="fee">{{ detail.collectionDeposit | numberWithCommas }}원</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <hr>-->
      <div class="adjusted-amount">
        <div>
          <p class="title font-bold">정산금액</p>
          <p class="font-bold title">{{ detail.calculateAmount | numberWithCommas }}원</p>
        </div>
      </div>
      <hr>
    </div>
    <div class="fnb-btn-group">
      <button class="full-button" @click="$router.push({name: 'Management'})">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculateDetail",
  data() {
    return {
      detail: {
        adjustmentPrice: 0,
        calculateAmount: 0,
        cardFee: 0,
        carryOverDeposit: 0,
        collectionDeposit: 0,
        deliveryFee: 0,
        depositDate: '',
        excludingDeposit: 0,
        onnuriFee: 0,
        orderPrice: 0,
        serviceFee: 0
      },
    }
  },

  mounted() {
    if(this.$route.params.calculateIdx !== undefined) {
      this.$get(this.$SHOP_SALES_CALCULATE_DETAIL + '/' + this.$route.params.calculateIdx, 'calculateDetail_management', true, (result) => {
        console.log('calculate detail', result.data)
        this.detail = result.data
      },(result) => {
        console.log('fail calculate', result)
      })
    } else {
      this.$router.push({ name: 'Management' })
    }
  },
}
</script>

<style scoped>

</style>
