<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'Login' })"><i class="icon-back"></i></button>
      <p>비밀번호 찾기</p>
    </header>
    <div class="auth-form">
      <form name="form_chk" method="post" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb">
        <input type="text" class="input-default" placeholder="아이디를 입력하세요." v-model="shopCode">
        <input type="hidden" name="m" value="checkplusSerivce">
        <input type="hidden" name="EncodeData" id="nice-enc">
      </form>
      <button class="btn btn-pink mb-20" @click="certify">본인명의 휴대폰으로 인증</button>
      <p>
        * 회원님의 명의로 등록된 휴대폰으로 본인확인 후<br />
        &nbsp;&nbsp;&nbsp;비밀번호를 재설정합니다.
      </p>
    </div>
    <div class="alert-wrap" v-show="showError">
      <div class="alert-content">
        <i class="icon-alert-fail"></i><span>{{ errorMsg }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FindPassword",
  components: {

  },
  data() {
    return {
      shopCode: '',
      niceCode: '',
      encData: '',
      errorMsg: '',
      successMsg: '',
      showError: false,
      showSuccess: '',
      phone: '',
      identifyCode: '',
    }
  },
  methods: {
    fnPopup(encData) {
      window.removeEventListener('message', this.niceDone)

      window.name ="Parent_window"
      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no')
      document.getElementById('nice-enc').value = encData
      document.form_chk.target = "popupChk"
      document.form_chk.submit()

      window.addEventListener('message', this.niceDone);
    },
    certify() {
      axios.post("https://nice.reappay.net/check-pay-plus/request", null, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
      }).then(response => {
        console.log(response)
        if (response.data.success === true) {
          console.log('request nice success')
          console.log(response.data)
          this.encData = response.data.enc_data
          this.fnPopup(this.encData)
        } else {
          console.log(response.data)
        }
      }).catch((e) => {
        console.log(e)
      })
    },

    niceDone (e) {
      if(e.origin === 'https://nice.reappay.net') {
        console.log(e.data)
        this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.niceCode = e.data.payload

        window.removeEventListener('message', this.niceDone)
        console.log('niceDone', this.phone, this.niceCode)
        this.findPwd()
      }
    },

    findPwd() {
      let formData = new FormData
      formData.append('niceCode', this.niceCode)
      // formData.append('niceCode', 'U2FsdGVkX19LT86JQk6XoCI8GHu1TB5zmRrT9l4wKQbQ1B+nBG3zJ0Qcm7SR5Mo33JSgqYHy5Ybn2UWjhzsaTZ5hKrQANlighJPQ66byilERfW4ibXPvkKmwlD5Sl2CTpt+JgS+h8Qc0zGQ/bOeY+B+R30rkRE19k26sbrfhkomsRBCZfkk1RfAubS3OMeUEI/7bB3dfWflFLz9HVanWai15Z4nkpwjLfTaVJOWnwrQzVnfQrr+rX0Ce5piTuBgPlfO0/+3fCsfCTbxSzjlSVqf2tD7Y3tf5pVVF/b/dVgLMtHCK8/7zw6edH0BO8wWZ8jbTPKMxqKxBNJxO9qkw228WTNXg8G/Gk+YEGoukyZtNYABggNjRaKuL0BAO+cyFYeR1UyDzuVPKAlHHv+xAxeM8igy6w1KBmPP2/YPdsWrMQLJUS3DsRdUwTjoILH/COA9K/lPUFiwkTIGRX0ZlQwWxDZOUlGS3XO5eqDHBmeMY1LcORm36zg1zJ6vW1anRHJkhjUcs4VkIjNnZ3P7PeYbB7ox7ntBxUVgVHgA12bc3fa7jajv6ieqGDZYLzM0sf/BMEDNXJWudDKMEhn9DOwJJgopnptBNTxVrgSyETg8=')
      formData.append('code', this.shopCode)

      this.$post(this.$SHOP_FIND_PASSWORD, 'findPassword_findPassword', formData, false,result => {
        this.identifyCode = result.body.data.identifyCode
        this.$router.push({ name: 'ResetPassword', params: { identifyCode: this.identifyCode }}).catch(()=>{})
      }, (result) => {
        console.log('fail find pwd', result)
        this.showError = true
        this.errorMsg = result.body.data.message
      })
    }
  }
}
</script>

<style scoped>

</style>
