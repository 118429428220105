<template>
  <div>
    <div class="all-content" style="overflow: unset">
      <header class="header-wrap">
        <button type="button" class="btn btn-back" @click="$router.push({name: 'MypageSetup'})"><i class="icon-back"></i></button>
        <p>매장정보변경</p>
      </header>
      <div class="mypage-content">
        <h3>매장정보 입력</h3>
        <div>
          <div class="form-content mb-60">
            <div class="form-content-set">
              <label class="title">매장명</label>
              <p>
                <input type="text" class="input-box" v-model="marketName">
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">사업자등록증</label>
              <p>
                <span class="input_file">
                  <input type="text" class="input-box" v-model="licenseeImgName" disabled>
                  <button type="button" class="btn input-delete delete1" @click="deleteFile($event)"><i class="icon-close"></i></button>
                </span>
                <button type="button" class="form-content-button upload-icon">
                  <input type="file" class="custom-input file1" @change="upload($event)" accept="image/*">
                  <img src="/images/jangboda_shop_icon_file.svg" alt="파일 아이콘" />
                </button>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">영업신고증</label>
              <p>
                <span class="input_file">
                  <input type="text" class="input-box" v-model="reportImgName" disabled>
                  <button type="button" class="btn input-delete delete2" @click="deleteFile($event)"><i class="icon-close"></i></button>
                </span>
                <button type="button" class="form-content-button upload-icon">
                  <input type="file" class="custom-input file2" @change="upload($event)" accept="image/*">
                  <img src="/images/jangboda_shop_icon_file.svg" alt="파일 아이콘" />
                </button>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">매장로고</label>
              <img class="photo image-logo" v-show="showLogoImg" :src="logoImg" alt="매장 로고">
              <p class="photo" v-show="!showLogoImg">이미지 미리보기</p>
              <p>
                <span class="input_file">
                  <input type="text" class="input-box logo-file-name" v-model="logoImgName" disabled>
                  <button type="button" class="btn input-delete logoDelete" @click="deleteFile($event)"><i class="icon-close"></i></button>
                </span>
                <button type="button" class="form-content-button upload-icon">
                  <input type="file" class="custom-input logo-file" @change="upload($event)" accept="image/*">
                  <img src="/images/jangboda_shop_icon_file.svg" alt="파일 아이콘" />
                </button>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">매장주소</label>
              <p><input type="text" class="input-box" v-model="address" disabled></p>
              <p><input type="text" class="input-box" v-model="detailAddress" disabled></p>
              <p @click="$router.push({name: 'AddressChangeStep1'})"><button type="button" class="btn btn-outline-pink mt-5">주소 변경</button></p>
            </div>
            <div class="form-content-set" >
              <label class="title">매장사진</label>
              <!-- 기존 업로드 부분: -->
              <div class="market-img-container" v-for="(item, idx) in shopImgs" :key="idx">
                <img class="photo image-market" v-show="showShopImg" :src="item.shopImg" alt="매장 사진">
<!--                <p class="photo image-empty">이미지 미리보기</p>-->
                <p>
                  <span class="input_file">
                    <input type="text" class="input-box market-file-name" :value="item.shopImgName" disabled>
                    <button type="button" class="btn input-delete" @click="deleteFile($event, item, idx)"><i class="icon-close"></i></button>
                  </span>
                </p>
              </div>
              <!-- :기존 업로드 부분 -->
              <!-- 신규 업로드 부분: -->
              <div class="market-img-container " v-for="(item, idx) in addImgs" :key="'new' + idx" :id="'new' + idx">
                <img class="photo image-market" v-show="files[idx]" :src="item.shopImg" alt="매장 사진">
                <p class="photo image-empty" v-show="!files[idx]">이미지 미리보기</p>
                <p>
                  <span class="input_file">
                    <input type="text" class="input-box market-file-name photo-container-new" :value="item.shopImgName" disabled>
                    <button type="button" class="btn input-delete" @click="deleteFile1($event, item, idx)"><i class="icon-close"></i></button>
                  </span>
                  <button type="button" class="form-content-button upload-icon" v-show="!files[idx]">
                    <input type="file" class="custom-input market-photo-file" @change.once="upload($event, idx)" accept="image/*">
                    <img src="/images/jangboda_shop_icon_file.svg" alt="파일 아이콘" />
                  </button>
                </p>
              </div>
              <!-- :신규 업로드 부분 -->
              <p v-show="(this.shopImgs.length + this.addImgs.length) < 5">
                <button type="button" class="btn btn-outline-pink mt-5" @click="addImg">+ 매장사진 추가</button>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">매장소개</label>
              <p>
                <textarea class="textarea-line-3" v-model="shopIntro" ></textarea>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">연락처</label>
              <p>
                <input type="text" class="input-box" v-model="tel">
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">대표자</label>
              <p>
                <input type="text" class="input-box" v-model="ceo">
              </p>
            </div>
            <div class="form-content-set">
              <div class="toggle-group-title">
                <label class="toggle-group-label">온누리 모바일상품권 가맹점 여부</label>
                <span class="toggle-group-text">{{ isUseGiftCard === '1' ? '가입매장' : '미가입매장' }}</span>

              </div>
            </div>
            <div class="form-content-set">
              <label class="title">운영요일</label>
              <p>
                <button type="button" class="form-content-button day" @click="dayToggle($event)"
                        v-for="(day, index) in dayArr" :key="index">{{ day }}</button>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">운영시간</label>
              <p>
                <span class="input-time"><input type="number" class="input-box input-time" placeholder="09" min="0" max="99" v-model="orderStartHr"></span>
                <span class="time-colon">:</span>
                <span class="input-time"><input type="number" class="input-box input-time" placeholder="00" min="0" max="99" v-model="orderStartMin"></span>
                <span class="time-to">~</span>
                <span class="input-time"><input type="number" class="input-box input-time" placeholder="18" min="0" max="99" v-model="orderEndHr"></span>
                <span class="time-colon">:</span>
                <span class="input-time"><input type="number" class="input-box input-time" placeholder="00" min="0" max="99" v-model="orderEndMin"></span>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">배송지역</label>
              <p>
                <input type="text" class="input-box" v-model="deliveryArea" disabled>
              </p>
            </div>
            <div class="form-content-set">
              <label class="title">배달비용</label>
              <p>
                <input type="text" class="input-box" v-model="deliveryCost" disabled>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fnb-btn-group">
      <button class="full-button" @click="confirm">확인</button>
    </div>
  </div>

</template>

<script>
export default {
  name: "ShopInfo",
  data() {
    return {
      BusinessLicense: '',
      SalesLicense: '',
      Logo: '',
      logoName: '',
      dayArr: ['일', '월', '화', '수', '목', '금', '토'],
      marketPhoto: '',
      ceo: '',
      orderStartHr: '',
      orderStartMin: '',
      orderEndHr: '',
      orderEndMin: '',
      shopIntro: '',
      tel: '',
      dayIdx: '',
      dayCnt: 0,
      address: '',
      detailAddress: '',
      shopDetail: '',
      showShopImg: false,
      isUseGiftCard: '',
      marketName: '',
      licenseeImgName: '',
      reportImgName: '',
      logoImg: '',
      logoImgName: '',
      shopImgs: [],
      deliveryCost: '',
      deliveryArea: '',
      showLogoImg: '',
      businessImg: [],
      reportImg: [],
      shopLogoImg: [],
      addImgs:[],
      files: [],
      lat: '',
      lng: '',
      roadAddress: '',
    }
  },
  methods: {
    addImg() {
      if(this.addImgs.length === this.files.length) {
        if((this.shopImgs.length + this.addImgs.length) < 5) {
          this.addImgs.push({ shopImg: '', shopImgName: '' })
        }
      }
    },

    upload(e, idx) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if(e.currentTarget.classList.contains('file1')) {
        this.BusinessLicense = e.currentTarget.files[0]
        this.licenseeImgName = this.BusinessLicense.name
        this.businessImg.push(files[0])

      } else if(e.currentTarget.classList.contains('file2')){
        this.SalesLicense = e.currentTarget.files[0]
        this.reportImgName = this.SalesLicense.name
        this.reportImg.push(files[0])

      } else if(e.currentTarget.classList.contains('logo-file')){
        this.showLogoImg = true
        this.Logo = e.currentTarget.files[0]
        this.logoImgName = this.Logo.name
        let output = document.querySelector('.image-logo')
        this.logoImg = URL.createObjectURL(this.Logo)
        output.onload = function() {
          URL.revokeObjectURL(this.logoImg)
        }
        this.shopLogoImg.push(files[0])

      } else if(e.currentTarget.classList.contains('market-photo-file')) {
        let fileName = files[0].name
        let nameBoxes = document.querySelectorAll('.photo-container-new')
        nameBoxes[idx].value = fileName
        let outputs = nameBoxes[idx].parentElement.parentElement.parentElement.querySelector('.image-market')
        outputs.src = URL.createObjectURL(files[0])
        this.showShopImg = true
        outputs.onload = () => {
          // URL.revokeObjectURL(outputs.src)
          this.addImgs[this.addImgs.length - 1]['shopImg'] = outputs.src
        }
        this.files.push(files[0])
        this.addImgs[this.addImgs.length - 1]['shopImgName'] = fileName
      }
    },

    deleteFile1(e, item, idx) {
      this.addImgs.splice(idx, 1)
      this.files.splice(idx, 1)
    },

    deleteFile (e, item, idx) {
      if(e.currentTarget.classList.contains('delete1')) {
        if(this.licenseeImgName === null) {
          this.notify('error', '삭제할 파일이 없습니다.')
        }
        if(this.shopDetail.licenseeImgIdx !== null) {
          this.$axios_delete(this.$SHOP_SHOPINFO_IMAGE +'/'+ this.shopDetail.licenseeImgIdx, 'shopInfoImageLicenseeImg_shopInfo', true, result => {
            console.log('delete shop img', result)
            this.licenseeImgName = ''
          }, result => {
            console.log(result)
          })
        } else {
          this.licenseeImgName = ''
        }

      } else if (e.currentTarget.classList.contains('delete2')) {
        if(this.reportImgName === null) {
          this.notify('error', '삭제할 파일이 없습니다.')
        }
        if(this.shopDetail.licenseeImgIdx !== null) {
          this.$axios_delete(this.$SHOP_SHOPINFO_IMAGE +'/'+ this.shopDetail.reportImgIdx, 'shopInfoImageReportImg_shopInfo',true, result => {
            console.log('delete shop img', result)
            this.reportImgName = ''
          }, result => {
            console.log(result)
          })
        } else {
          this.reportImgName = ''
        }

      } else if (e.currentTarget.classList.contains('logoDelete')) {
        if(this.logoImgName === null) {
          this.notify('error', '삭제할 파일이 없습니다.')
        }

        if(this.shopDetail.logoImgIdx !== null) {
          this.$axios_delete(this.$SHOP_SHOPINFO_IMAGE +'/'+ this.shopDetail.logoImgIdx, 'shopInfoImageLogoImg_shopInfo', true, result => {
            console.log('delete shop img', result)
            this.logoImgName = ''
            this.showLogoImg = false
          }, result => {
            console.log(result)
          })
        } else {
          this.logoImgName = ''
          this.showLogoImg = false
        }

      } else {
        if(item.shopImgIdx !== undefined) {
          this.$axios_delete(this.$SHOP_SHOPINFO_IMAGE +'/'+ item.shopImgIdx, 'shopInfoImageShopImg_shopInfo', true, result => {
            console.log('delete shop img', result)
            this.shopImgs.splice(idx, 1)
          }, result => {
            console.log(result)
          })
        }
      }
    },

    dayToggle(e) {
      if(e.currentTarget.classList.contains('day-on')) {
        e.currentTarget.classList.remove('day-on')
      } else {
        e.currentTarget.classList.add('day-on')
      }
    },

    confirm() {

      //수정된 주소로 입력 되도록 변경 - pk
      if (this.$route.params.lat) {
        this.lat = this.$route.params.lat
      }
      if (this.$route.params.lng) {
        this.lng = this.$route.params.lng
      }

      let formData = new FormData

      for(let i=0; i<this.businessImg.length; i++) {
        formData.append('businessLicenseImg', this.businessImg[i])
      }
      for(let i=0; i<this.reportImg.length; i++) {
        formData.append('businessReportImg', this.reportImg[i])
      }

      for(let i=0; i<this.shopLogoImg.length; i++) {
        formData.append('logoImg', this.shopLogoImg[i])
      }

      for(let i=0; i<this.files.length; i++) {
        formData.append('shopImg', this.files[i])
      }

      formData.append('ceo', this.ceo)
      formData.append('name', this.marketName)
      formData.append('shopIntro', this.shopIntro)

      if(this.orderEndHr !== null || this.orderStartHr !== null) {
        if(0<=this.orderEndHr.parseInt<=24 && 0<=this.orderStartHr.parseInt<=24) {
          formData.append('orderEndTime.endHour', this.orderEndHr)
          formData.append('orderStartTime.startHour', this.orderStartHr)
        }
      }

      if(this.orderEndMin !== null || this.orderStartMin !== null) {
        if(0<=this.orderEndMin.parseInt<=59 && 0<=this.orderStartMin.parseInt<=59) {
          formData.append('orderEndTime.endMinute', this.orderEndMin)
          formData.append('orderStartTime.startMinute', this.orderStartMin)
        }
      }

      let days = document.querySelectorAll('.day')
      days.forEach(day => {
        if(day.classList.contains('day-on')) {
          this.dayIdx += (this.dayCnt + ',')
          this.dayCnt++
        } else {
          this.dayCnt++
        }
      })
      this.dayIdx = this.dayIdx.replace(/,\s*$/, "")
      formData.append('orderDay', this.dayIdx)
      this.dayCnt = 0
      this.dayIdx = ''

      formData.append('tel', this.tel)
      formData.append('parcel', this.address)
      formData.append('road', this.roadAddress)
      formData.append('subAddress', this.detailAddress)
      formData.append('lat', this.lat)
      formData.append('lng', this.lng)

      this.$put(this.$SHOP_SHOPINFO, 'shopInfo_shopInfo', formData, true, result => {
        this.getData()
        this.notify('success', result.data)
        this.$router.push({ name: 'MypageSetup' })
      }, result => {
        this.getData()
        this.notify('error', result.error)
      })
    },
    getData() {
      this.$get(this.$SHOP_SHOPINFO_DETAIL, 'shopInfoDetail_shopInfo', true, (result) => {
        console.log('get shop detail', result)
        this.shopDetail = result.data
        // this.showShopImg = this.shopDetail.shopImgs.length !== 0
        this.shopIntro = this.shopDetail.shopInfoIntro
        this.tel = this.shopDetail.tel
        this.ceo = this.shopDetail.ceo
        this.isUseGiftCard = this.shopDetail.isUseGiftCard
        this.marketName = this.shopDetail.shopInfoName
        this.licenseeImgName = this.shopDetail.licenseeImgName
        this.reportImgName = this.shopDetail.reportImgName
        this.logoImg = this.shopDetail.logoImg

        this.logoImg !== null ? this.showLogoImg = true : this.showLogoImg = false

        this.logoImgName = this.shopDetail.logoImgName
        this.shopImgs = this.shopDetail.shopImgs
        this.shopImgs.length !== 0 ? this.showShopImg = true : this.showShopImg = false
        this.deliveryArea = this.shopDetail.deliveryArea
        this.deliveryCost = this.shopDetail.deliveryCost
        this.orderStartHr = this.shopDetail.orderStartHr
        this.orderStartMin = this.shopDetail.orderStartMin
        this.orderEndHr = this.shopDetail.orderEndHr
        this.orderEndMin = this.shopDetail.orderEndMin

        if(this.shopDetail.orderDays !== null) {
          let daysOpen = this.shopDetail.orderDays
          let daysOpenArr = daysOpen.split(',')
          let days = document.querySelectorAll('.day')
          days.forEach(day => {
            if(day !== undefined) {
              for(let i=0; i<daysOpenArr.length; i++) {
                if(daysOpenArr[i] === i.toString()) {
                  days[i].classList.add('day-on')
                }
              }
            }
          })
        }

        // api로 바로 매장 주소 가져왔을 때 or 주소 변경하여 지도로 주소 가져왔을 때
        this.$route.params.address !== undefined ? this.address = this.$route.params.address : this.address = this.shopDetail.address
        this.$route.params.detail !== undefined ? this.detailAddress = this.$route.params.detail : this.detailAddress = this.shopDetail.subAddress
        this.$route.params.lat !== undefined ? this.lat = this.$route.params.lat : this.lat = this.shopDetail.lat
        this.$route.params.lng !== undefined ? this.lng = this.$route.params.lng : this.lng = this.shopDetail.lng
        this.$route.params.roadAddress !== undefined ? this.roadAddress = this.$route.params.roadAddress : this.roadAddress = this.shopDetail.road
      }, (result) => {
        console.log('fail get shop detail', result)
      })
    },

    clearData() {
      this.BusinessLicense = ''
      this.SalesLicense = ''
      this.Logo = ''
      this.logoName = ''
      this.dayArr = ['일', '월', '화', '수', '목', '금', '토']
      this.marketPhoto = ''
      this.ceo = ''
      this.orderStartHr = ''
      this.orderStartMin= ''
      this.orderEndHr= ''
      this.orderEndMin= ''
      this.shopIntro= ''
      this.tel = ''
      this.dayIdx = ''
      this.dayCnt = 0
      this.address = ''
      this.detailAddress = ''
      this.shopDetail = ''
      this.showShopImg = false
      this.isUseGiftCard = ''
      this.marketName = ''
      this.licenseeImgName = ''
      this.reportImgName = ''
      this.logoImg = ''
      this.logoImgName = ''
      this.shopImgs = []
      this.deliveryCost = ''
      this.deliveryArea = ''
      this.showLogoImg = ''
      this.businessImg = []
      this.reportImg = []
      this.shopLogoImg = []
      this.addImgs = []
      this.files = []
      this.lat = ''
      this.lng = ''
      this.roadAddress = ''
    }
  },

  mounted() {
    this.clearData()
    this.getData()
  },

  watch: {
    '$route.params.address': {
      handler() {
        this.address = this.$route.params.address
      }
    },
    '$route.params.roadAddress': {
      handler() {
        this.roadAddress = this.$route.params.roadAddress
      }
    },
    '$route.params.lat': {
      handler() {
        this.lat = this.$route.params.lat
      }
    },
    '$route.params.lng': {
      handler() {
        this.lng = this.$route.params.lng
      }
    },
    '$route.params.detail': {
      handler() {
        this.detailAddress = this.$route.params.detail
      }
    },
    '$route.params.randomVal': {
      handler() {
        console.log('watch')
        this.clearData()
        this.getData()
      }
    },
  }
}
</script>

<style scoped>

</style>
