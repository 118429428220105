<template>
  <div class="all-content">
    <Lnb v-show="showLnb" @closeLnb="showLnb = false"></Lnb>
    <AlarmList v-show="showAlarm" @closeAlarm="this.closeAlarm"></AlarmList>
    <Header title="정산관리" @openLnb="this.openLnb" @openAlarm="this.openAlarm"></Header>
    <div class="dashboard-wrap sales-manage scroll calculate">
      <div class="cal-content-set">
        <p class="sales-schedule" style="border: 0; padding: 0;">
          <span class="sales-total-title">오늘 입금 금액</span>
          <span class="sales-total-pay">{{ calculate.todayDepositAmount | numberWithCommas }}원</span>
        </p>
<!--        <p class="cal-modify">-->
<!--          <span>-->
<!--            <img src="/images/icon-question-cycle.png" alt="물음표 아이콘" @click="$router.push({ name: 'Adjustment' })"/>-->
<!--            <span>조정금액</span>-->
<!--          </span>-->
<!--          <span class="sales-sub-pay">{{ calculate.adjustmentPrice | numberWithCommas }}원</span>-->
<!--        </p>-->
      </div>
      <div class="sales-content-close" v-show="showSearch === false">
        <a>
          <img src="/images/jangboda_shop_btn_sales_content_open.svg" alt="검색창 열기 버튼" @click="toggleSearch"/>
        </a>
      </div>
      <div class="calculate-search01" v-show="showSearch">
        <div class="period-search">
          <p>
            <span class="sales-input01"><date-picker class="input-box" v-model="pastDate" :config="this.calendarOptions"></date-picker></span>
            <span class="sales-date-line">-</span>
            <span class="sales-input01"><date-picker class="input-box" v-model="today" :config="this.calendarOptions"></date-picker></span>
            <a class="btn btn-search btn-outline-pink" @click="search(false)">검색</a>
          </p>
          <p class="period">
            <button @click="togglePeriod($event)" v-for="(period, idx) in periodArr" :key="idx">{{ period }}</button>
          </p>
        </div>

        <div class="date_chart">
          <div class="tab_con">
            <div class="content-box">
              <ul class="calculate_list">
                <li><strong>총 승인</strong><em>{{ calculate.approvalCount | numberWithCommas }} 건</em><span>{{ calculate.approvalAmount | numberWithCommas }}원</span></li>
                <li><strong>총 취소</strong><em>{{ calculate.cancelCount | numberWithCommas }} 건</em><span>{{ calculate.cancelAmount | numberWithCommas }}원</span></li>
                <li><strong class="total">합계</strong><em class="total">{{ (calculate.approvalCount + calculate.cancelCount) | numberWithCommas }} 건</em><span class="calculate_total">{{ calculate.total | numberWithCommas }}원</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="sales-content-close">
          <a>
            <img src="/images/jangboda_shop_btn_sales_content_close.svg" alt="검색창 닫기 버튼" @click="toggleSearch"/>
          </a>
        </div>
      </div>
      <div class="calculate-content">
        <div class="content-box" v-for="(cal, idx) in calculate.list" :key="idx">
          <p class="calculate_date">{{ cal.date }}<span><a class="calculate_btn" @click="goDetail(cal.idx)">상세보기</a></span></p>
          <div class="calculate_con_box">
            <p class="calculate_tit">
              <span>주문금액</span>
<!--              <span>조정금액</span>-->
              <span>입금금액</span>
            </p>
            <p class="calculate_data">
              <span>{{ cal.orderPrice | numberWithCommas }}원</span>
<!--              <span>{{ cal.adjustmentPrice | numberWithCommas }}원</span>-->
              <span>{{ cal.depositPrice | numberWithCommas }}원</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mixin} from "@/commonjs/mixin"

export default {
  name: "Management",
  mixins: [mixin],
  data() {
    return {
      showLnb: false,
      showAlarm: false,
      today: '',
      pastDate: '',
      previousWeek: '',
      period: '',
      periodArr: ['7일', '14일', '한 달', '세 달'],
      calculate: {
        adjustmentPrice: 0,
        approvalAmount: 0,
        approvalCount: 0,
        cancelAmount: 0,
        cancelCount: 0,
        endDate: '',
        list: [],
        startDate: '',
        todayDepositAmount: 0,
        total: 0
      },
      showSearch: true,
    }
  },

  mounted() {
    this.getDate()
    this.pastDate = this.previousWeek
    this.search(false)

    document.querySelectorAll('.period button')[0].classList.add('on')
  },

  methods: {
    toggleSearch() {
      if(this.showSearch) {
        setTimeout(() => this.showSearch = !this.showSearch, 300)
        window.$('.calculate-search01').slideUp()
      } else {
        this.showSearch = !this.showSearch
        window.$('.calculate-search01').slideDown()
      }
    },

    goDetail(calIdx) {
      this.$router.push({ name: 'CalculateDetail', params: { calculateIdx: calIdx }})
    },

    // getDay(date) {
    //   let d = new Date(date)
    //   let day = d.getDay()
    //   if(day === 0) {
    //     return '일'
    //   } else if( day === 1) {
    //     return '월'
    //   } else if( day === 2) {
    //     return '화'
    //   } else if( day === 3) {
    //     return '수'
    //   } else if( day === 4) {
    //     return '목'
    //   } else if( day === 5) {
    //     return '금'
    //   } else if( day === 6) {
    //     return '토'
    //   }
    // },

    getDate() {
      let date = new Date()
      this.today = date.getFullYear() + "-" + (date.getMonth() + 1) + '-' + date.getDate()
      let minusWeek = date.setDate(date.getDate() - 7)
      let weekBefore = new Date(minusWeek)
      this.previousWeek = weekBefore.getFullYear() + "-" + (weekBefore.getMonth() + 1) + "-" + weekBefore.getDate()
    },

    search(isSetData) {
      if(!isSetData) {
        this.period = ''
        document.querySelectorAll('.period button').forEach((btn) => btn.classList.remove('on'))
      }
      this.$get(this.$SHOP_SALES_CALCULATE + '?startDate=' +this.pastDate+ '&endDate=' +this.today, 'salesCalculate_management', true, (result) => {
        console.log('calculate', result.data)
        this.calculate = result.data
        // this.getDay()
      },(result) => {
        console.log('fail calculate', result)
        this.notify('error', result.data.error)
      })
    },

    togglePeriod(e) {
      this.pastDate = ''

      let target = e.currentTarget
      let btns = target.parentElement.querySelectorAll('button')
      btns.forEach((btn) => btn.classList.remove('on'))
      target.classList.add('on')

      let date = new Date()
      let minusMonth = date.setMonth(date.getMonth() - 1)
      let monthBefore = new Date(minusMonth)
      let previousMonth = monthBefore.getFullYear() + "-" + (monthBefore.getMonth() + 1) + "-" + monthBefore.getDate()

      date = new Date()
      let minusTwoWeeks = date.setDate(date.getDate() - 14)
      let twoWeeksBefore = new Date(minusTwoWeeks)
      let previousTwoWeeks = twoWeeksBefore.getFullYear() + "-" + (twoWeeksBefore.getMonth() + 1) + "-" + twoWeeksBefore.getDate()

      date = new Date()
      let minusThreeMonths = date.setMonth(date.getMonth() - 3)
      let threeMonthsBefore = new Date(minusThreeMonths)
      let previousThreeMonths = threeMonthsBefore.getFullYear() + "-" + (threeMonthsBefore.getMonth() + 1) + "-" + threeMonthsBefore.getDate()

      if(target.textContent === '7일') {
        this.period = '1week'
        this.pastDate = this.previousWeek
      } else if(target.textContent === '14일') {
        this.period = '2week'
        this.pastDate = previousTwoWeeks
      } else if (target.textContent === '한 달') {
        this.period = '1month'
        this.pastDate = previousMonth
      } else {
        this.period = '3month'
        this.pastDate = previousThreeMonths
      }

      this.search(true)
    },
  }
}
</script>

<style scoped>

</style>
