<template>
  <div>
    <!--      주문 내역 없을 때-->
    <noContent v-show="noContent" title="주문내역이 없습니다!" class="sales-order-list"></noContent>
    <!--      주문 내역 있을 때-->
    <div>
      <div class="sales-search02">
        <p class="sales-content-set">
          <span class="sales-input01"><date-picker class="input-box" v-model="startDate" :config="this.calendarOptions"></date-picker></span>
          <span class="sales-date-line">-</span>
          <span class="sales-input01"><date-picker class="input-box" v-model="endDate" :config="this.calendarOptions"></date-picker></span>
          <a class="btn btn-search btn-outline-pink" @click="search">검색</a>
        </p>
      </div>
      <div class="dashboard-content sales-content02" v-show="showOrderList">
        <div class="content-box" v-for="(orderItem, idx) in orderList" :key="idx">
          <p class="order-code">{{ orderItem.orderCode }}</p>
          <p class="order-time">
            <span class="order-time-title">주문시간</span><span>{{ orderItem.list[0].regDate }}</span><br>
          </p>
          <div>
            <p class="product-set" v-for="(menu, idx) in orderItem.list" :key="idx">
              <span class="sales-product">{{ menu.orderProductName }}</span>
              <span class="sales-option" v-for="(option, idx) in menu.options" :key="idx">
                {{ option.optionName }}{{ (idx + 1) !== menu.options.length ? ',' : '' }}
              </span>
            </p>
          </div>
          <div class="order-detail-set">
            <p>
              <span class="title">접수시간</span>
              <span class="time">{{ orderItem.receptionTime }}</span>
            </p>
            <p>
              <span class="title">픽업시간</span>
              <span class="time">{{ orderItem.pickupTime }}</span>
            </p>
            <p>
              <span class="title">분류시간</span>
              <span class="time">{{ orderItem.classifyTime }}</span>
            </p>
            <p>
              <span class="title">배송시간</span>
              <span class="time">{{ orderItem.deliveryTime }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "OrderDetails",

  data() {
    return {
      noContent: false,
      showOrderList: true,
      startDate: '',
      endDate: '',
      orderList: '',
      date: new Date(),
    }
  },
  methods: {
    search(){
      this.$get(this.$SHOP_SALES_ORDER +'?endDate=' + this.endDate +'&startDate=' + this.startDate, 'salesOrder_OrderList', true, result => {
        console.log('주문내역', result)
        this.orderList = result.data
        if(this.orderList.length !== 0) {
          this.showOrderList = true
          this.noContent = false
        } else {
          this.noContent = true
          this.showOrderList = false
        }
      }, result => {
        console.log(result)
      })
    }
  },

  mounted() {
    this.startDate = this.date.getFullYear() + "-" + (this.date.getMonth() + 1) + '-' + this.date.getDate()
    this.endDate = this.date.getFullYear() + "-" + (this.date.getMonth() + 1) + '-' + this.date.getDate()
  }
}
</script>

<style scoped>

</style>
