<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'FindPassword' })"><i class="icon-back"></i></button>
      <p>비밀번호 재설정</p>
    </header>
    <div class="mypage-wrap">
      <div class="mypage-password">
        <div class="password-input-group">
          <label>새 비밀번호 입력</label>
          <input type="password" placeholder="새 비밀번호 입력(8~16자의 영문대/소문자, 숫자, 특수문자 사용)" v-model="password">
        </div>
        <div class="password-input-group">
          <label>새 비밀번호 확인</label>
          <input type="password" placeholder="비밀번호를 한번 더 입력하세요." v-model="passwordConfirm">
        </div>
        <div class="password-btn" @click="resetPwd">
          <button class="btn btn-pink">비밀번호 변경</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      password: '',
      passwordConfirm: '',
      identifyCode: '',
    }
  },
  methods: {
    resetPwd() {
      let formData = new FormData()
      if(this.password !== '' && this.passwordConfirm !== '') {
        formData.append('password', this.password)
        formData.append('passwordConfirm', this.passwordConfirm)
        formData.append('identifyCode', this.$route.params.identifyCode)

        this.$put(this.$SHOP_FIND_PASSWORD, 'findPassword_resetPassword', formData, false, result => {
          console.log(result)
          this.notify('success', '비밀번호가 변경 되었습니다.')
          this.$router.push({ name: 'Login' }).catch(()=>{})
        }, result => {
          console.log(result)
          this.notify('error', result.error)
        })
      } else {
        this.notify('error', '새 비밀번호를 입력하세요.')
      }

    }
  },
}
</script>

<style scoped>

</style>
