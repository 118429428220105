<template>
  <div class="all-content">
    <Modal :askConfirmTxt=askConfirmTxt v-if="openModal" @close="openModal = false" @confirm="confirm" ></Modal>
    <header class="header-wrap">
      <p>주문취소</p>
      <button type="button" class="btn btn-close">
        <router-link :to="{name: 'Dashboard'}"><i class="icon-close"></i></router-link>
      </button>
    </header>
    <div class="dashboard-wrap">
      <div class="order-content">
        <div class="order-content-box">
          <p class="order-code">{{ order.shopCode }}</p>
          <p class="order-time">
            <span class="order-time-title">주문시간</span><span>{{ order.regDate }}</span>
          </p>
          <div>
            <p class="product-set" v-for="(product, idx) in products" :key="idx">
              <span class="product">{{ product.productName }}</span>
              <span class="option" v-for="(option, idx) in product.optionName" :key="idx">{{ option }} </span>
            </p>
          </div>
        </div>
        <div class="order-content-box">
          <h2>취소 사유 선택</h2>
          <ul class="regist-terms-radio">
            <li class="radios">
              <input type="radio" id="radio1" name="radio_form" @click="getValue(0)">
              <label for="radio1">재고없음</label>
            </li>
            <li class="radios">
              <input type="radio" id="radio2" name="radio_form" @click="getValue(1)">
              <label for="radio2">매장휴무</label>
            </li>
            <li class="radios">
              <input type="radio" id="radio3" name="radio_form" @click="getValue(2)">
              <label for="radio3">재료부족</label>
            </li>
            <li class="radios">
              <input type="radio" id="radio4" name="radio_form" @click="getValue(3)">
              <label for="radio4">휴식시간</label>
            </li>
            <li class="radios">
              <input type="radio" id="radio5" name="radio_form" @click="getValue(4)">
              <label for="radio5">기타(직접입력)</label>
            </li>
            <li>
              <textarea class="textarea-default" placeholder="취소 사유를 작성해주세요. (최소 10자 이상)" disabled v-model="reasonTxt"></textarea>
            </li>
          </ul>
          <p>
          </p>
        </div>
      </div>
      <div class="fnb-btn-group">
        <button class="full-button" @click="openModal = true">주문취소</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "OrderCancel",

  data() {
    return {
      products: [],
      askConfirmTxt: '주문 취소하시겠습니까?',
      openModal: false,
      order: {
        shopCode: '',
        regDate: '',
      },
      reasonIdx: '',
      reasonTxt: '',
    }
  },
  methods: {
    getValue(val) {
      this.reasonIdx = val
      if(val === 4) {
        document.querySelector('.textarea-default').removeAttribute('disabled')
      } else {
        document.querySelector('.textarea-default').setAttribute('disabled', true)
        this.reasonTxt = ''
      }
    },


    confirm() {
      let formData = new FormData
      formData.append('cancelReason', this.reasonIdx)
      if(this.reasonIdx === 4) {
        formData.append('etcReason', this.reasonTxt)
      }
      this.$put(this.$SHOP_ORDER +'/'+ this.$route.params.orderDetailIdx, 'order_orderCancel', formData,true, result => {
        console.log(result)
        this.notify('success', '주문이 취소 되었습니다.')
        this.$router.push({name: 'Dashboard'})
      }, (result) => {
        console.log(result)
        this.notify('error', result.error)
      })
      this.openModal = false
    }
  },

  mounted() {
    this.$get(this.$SHOP_ORDER_CANCELFORM + '/' + this.$route.params.orderDetailIdx, 'orderCancelForm_orderCancel', true, result => {
      console.log('orderDetail', result.data)
      this.order = result.data
      this.products = result.data.productList
    }, (result) => {
      console.log(result)
    })
  },
}
</script>

<style scoped>

</style>
