import axios from 'axios';

var requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 60000
    }
}
var requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
        },
        timeout: 60000
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze  ({
    install(Vue) {
        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN
        Vue.prototype.$SHOP = "/shop"
        Vue.prototype.$SHOP_FCM_UPDATE = "/shop/fcmUpdate"
        Vue.prototype.$SHOP_CHECK = "/shop/check"
        Vue.prototype.$SHOP_PAYMENTS = "/shop/payments"
        Vue.prototype.$SHOP_NOTICE = "/shop/notice"
        Vue.prototype.$SHOP_FIND_CODE = "/shop/find/code"
        Vue.prototype.$SHOP_STATE = "/shop/state"
        Vue.prototype.$SHOP_CONFIG = "/shop/config"
        Vue.prototype.$SHOP_FIND_PASSWORD = "/shop/find/password"
        Vue.prototype.$SHOP_CHANGE_PASSWORD = "/shop/change/password"
        Vue.prototype.$SHOP_AUTH_NICE = "/shop/auth/nice"
        Vue.prototype.$SHOP_LOGIN = "/shop/login"
        Vue.prototype.$SHOP_AUTO_LOGIN = "/shop/autoLogin"
        Vue.prototype.$SHOP_ORDER = "/shop/order"
        Vue.prototype.$SHOP_ORDER_CANCELFORM = "/shop/order/cancelForm"
        Vue.prototype.$SHOP_ORDER_PICKUP = "/shop/order/pickup"
        Vue.prototype.$VSHOP_PAYMENT = "/shop/payment"
        Vue.prototype.$SHOP_PRODUCT = "/shop/product"
        Vue.prototype.$SHOP_PRODUCT_IMAGE = "/shop/product/image"
        Vue.prototype.$SHOP_PRODUCT_CATEGORIES = "/shop/product/categories"
        Vue.prototype.$SHOP_PRODUCT_CLASSIFICATION = "/shop/product/classification"
        Vue.prototype.$SHOP_PRODUCT_CLASSIFICATION_SORT = "/shop/product/classification/sort"
        Vue.prototype.$SHOP_PRODUCT_STATE = "/shop/product/state"
        Vue.prototype.$SHOP_FAQ = "/shop/faq"
        Vue.prototype.$SHOP_SHOPINFO = "/shop/shop-info"
        Vue.prototype.$SHOP_SHOPINFO_IMAGE = "/shop/shop-info/image"
        Vue.prototype.$SHOP_SHOPINFO_DETAIL = "/shop/shop-info/detail"
        Vue.prototype.$SHOP_NOTIFICATION = "/shop/notification"
        Vue.prototype.$SHOP_SALES = "/shop/sales"
        Vue.prototype.$SHOP_SALES_ORDER = "/shop/sales/order"
        Vue.prototype.$SHOP_SALES_PAYMENTS = "/shop/sales/payments"
        Vue.prototype.$SHOP_SALES_CALCULATE = "/shop/sales/calculate"
        Vue.prototype.$SHOP_SALES_CALCULATE_DETAIL = "/shop/sales/calculate/detail"
        Vue.prototype.$SHOP_QNA = "/shop/qna"
        Vue.prototype.$SHOP_REVIEWS = "/shop/reviews"
        Vue.prototype.$SHOP_REVIEW = "/shop/review"
        Vue.prototype.$SHOP_REFRESH_TOKEN = "/shop/refreshToken" //토큰 갱신

        // api 통신
        Vue.prototype.$post = async function (callUrl, caller, postData, useToken, success, fail){
            console.log(arguments[0])
            if (pending_post[arguments[0] + caller]) {
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    // this.notify('danger', 'require_login')
                    return
                }
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                success(response.data)
            }).catch(e => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
                if (e.response.data.state === 401) {
                    this.$router.push({ name: 'Login' }).catch(()=>{})
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('expires')
                    localStorage.removeItem('passToken')
                }

            })
        }
        Vue.prototype.$get = async function (callUrl, caller, useToken, success, fail){
            console.log(arguments[0])
            if (pending_get[arguments[0] + caller]) {
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    // this.notify('danger', 'require_login')
                    return
                }
            }
            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                success(response.data)
            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
                if (e.response.data.state === 401) {
                    this.$router.push({ name: 'Login' }).catch(()=>{})
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('expires')
                    localStorage.removeItem('passToken')
                }
            })
        }
        Vue.prototype.$put = async function (callUrl, caller, postData, useToken, success, fail){
            console.log(arguments[0])
            if (pending_put[arguments[0] + caller]) {
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    // this.notify('danger', 'require_login')
                    return
                }
            }
            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false
                success(response.data)
            }).catch(e => {
                pending_put[arguments[0] + caller] = false
                fail(e.response.data)
                if (e.response.data.state === 401) {
                    this.$router.push({ name: 'Login' }).catch(()=>{})
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('expires')
                    localStorage.removeItem('passToken')
                }
            })
        }

        Vue.prototype.$axios_delete = async function (callUrl, caller, useToken, success, fail){
            console.log(arguments[0])
            if (pending_delete[arguments[0] + caller]) {
                return
            }
            pending_delete[arguments[0] + caller] = true

            let _requestOption = requestOption()
            if (useToken) {
                let token = localStorage.getItem('accessToken')
                if (token && token.length > 0) {
                    _requestOption = requestOptionWithToken()
                } else {
                    // this.notify('danger', 'require_login')
                    return
                }
            }
            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false
                success(response.data.data)
            }).catch(e => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response.data)
                if (e.response.data.state === 401) {
                    this.$router.push({ name: 'Login' }).catch(()=>{})
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    localStorage.removeItem('expires')
                    localStorage.removeItem('passToken')
                }
            })
        }
    }
})
