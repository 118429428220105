<template>
  <div class="all-content">
    <Modal :askConfirmTxt=askConfirmTxt v-if="openModal" @close="openModal = false" @confirmSend="confirmSend" @confirmDelete="confirmDelete"
           :idxDelete=idxDelete :question=question :answer=answer></Modal>
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'MypageMain' })"><i class="icon-back"></i></button>
      <p>1:1 문의 답변</p>
    </header>
<!--    문의 없을 때-->
    <noContent v-show="noContent" title="1:1 문의가 없습니다!"></noContent>
<!--    문의 있을 때-->
    <div class="mypage-content bg-white" v-show="showInquiry">
      <div v-for="(question, idx) in qnas" :key="question.idx">
        <div class="mypage-box" @click="openInquiry($event, question, idx)">
          <span class="badge board-badge" :class="{ 'badge-pink': question.state === '1' }">{{ question.state === '0' ? '답변대기' : '답변완료' }}</span>
          <span class="board-title">{{ question.title }}</span>
          <span class="btn-arrow-right"><i class="icon-arrow-up"></i></span>
          <p class="board-date">{{ question.date }}</p>
        </div>
        <div>
          <div class="inquiry">
            <div class="mypage-box board-content">
              <p class="board-content-title">{{ detailTitle }}</p>
              <p class="board-content-text">{{ detailContent }}</p>
              <img :src=img.imagePath v-show="showImg" v-for="(img, idx) in inquiryImg" :key="idx" alt="문의 이미지" style="width: 100%; margin-top: 10px"/>
            </div>
            <div class="board-content board-content-answer" v-show="question.state === '1'">
              <p class="answer-title-set">
                <label class="answer-title">답변</label>
                <span class="answer-date">{{ detailDate }}</span>
              </p>
              <p class="board-content-text" v-html="detailAnswer" v-if="question.idx === detail.idx"></p>
              <p class="board-content-text" v-else></p>
              <div class="content-btn">
                <button type="button" class="btn-left btn btn-dgray" @click="deleteQna(detail.idx)">삭제</button>
                <button type="button" class="btn-right btn btn-pink" @click="rewrite($event)">수정</button>
              </div>
            </div>
          </div>
          <div class="board-content board-content-answer answer-modify">
            <p class="answer-title-set">
              <label class="answer-title">답변</label>
            </p>
            <p>
              <textarea class="textarea-default" placeholder="답변을 입력하세요." v-model="answer"></textarea>
            </p>
            <p class="content-btn"><button type="button" class="btn btn-pink" @click="sendAnswer(question, answer)">{{ detail.state === '0' ? '답변 등록' : '답변 수정' }}</button></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Support",

  data() {
    return{
      answer: '',
      qnas: '',
      detail: '',
      showInputAnswer: false,
      detailTitle: '',
      detailContent: '',
      detailDate: '',
      detailAnswer: '',
      askConfirmTxt: '',
      openModal: false,
      idxDelete: '',
      question: '',
      showInquiry: true,
      noContent: true,
      inquiryImg: [],
      showImg: false,
    }
  },
  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      this.$get(this.$SHOP_QNA, 'qna_support', true, (result) => {
        console.log('get qna', result.data)
        this.qnas = result.data
        if(this.qnas.length !== 0) {
          this.showInquiry = true
          this.noContent = false
        } else {
          this.showInquiry = false
          this.noContent = true
        }
      },(result) => {
        console.log(result)
      })
    },

    deleteQna(idx) {
      this.askConfirmTxt = '1:1 문의 답변을 삭제하시겠습니까?'
      this.idxDelete = idx
      this.question = {}
      this.answer = ''
      this.openModal = true
    },

    confirmDelete(idx) {
      this.$axios_delete(this.$SHOP_QNA +'/'+ idx, 'qna_support', true, (result) => {
        console.log('delete', result)
        this.notify('success', result)
        location.reload()
      }, (result) => {
        console.log(result)
        this.notify('error', result.error)
      })
      this.openModal = false
    },

    sendAnswer(question, answer) {
      this.askConfirmTxt = '1:1 문의 답변을 등록하시겠습니까?'
      this.question = question
      this.answer = answer
      this.idxDelete = 0
      this.openModal = true
    },

    confirmSend(question, answer) {
      let formData = new FormData
      formData.append('answer', answer)
      formData.append('qnaIdx', question.idx)

      if(question.state === '0') {
        this.$post(this.$SHOP_QNA +'/'+ question.idx, 'qnaPost_support', formData, true, result => {
          console.log('post answer', result)
          this.notify('success', result.data)
          location.reload()
        }, result => {
          console.log(result)
          this.notify('error', result.error)
        })
      } else if(question.state === '1') {
        this.$put(this.$SHOP_QNA +'/'+ question.idx, 'qnaPut_support', formData, true, result => {
          console.log('put answer', result)
          this.notify('success', result.data)
          location.reload()
        }, result => {
          console.log(result)
          this.notify('error', result.error)
        })
      }
      this.openModal = false
    },

    rewrite(e) {
      let inputModify = e.currentTarget.parentElement.parentElement.parentElement.parentElement.querySelector('.answer-modify')
      inputModify.classList.toggle('active')
      inputModify.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    },

    openInquiry(e, question, idx) {
      this.showDetail(e, question, idx)
      if(e.currentTarget.querySelector('.icon-arrow-up') !== null) {
        let inquiries = document.querySelectorAll('.inquiry')
        let answers = document.querySelectorAll('.answer-modify')
        let icons = document.querySelectorAll('.icon-arrow-down')

        for(let i=0; i<inquiries.length; i++) {
          if(inquiries[i].classList.contains('active')) {
            inquiries[i].classList.remove('active')
          }
        }

        for(let i=0; i<answers.length; i++) {
          if(answers[i].classList.contains('active')) {
            answers[i].classList.remove('active')
          }
        }

        for(let i=0; i<icons.length; i++) {
          if(icons[i].classList.contains('icon-arrow-down')) {
            icons[i].classList.remove('icon-arrow-down')
            icons[i].classList.add('icon-arrow-up')
          }
        }

        let icon = e.currentTarget.querySelector('.icon-arrow-up')
        icon.classList.remove('icon-arrow-up')
        icon.classList.add('icon-arrow-down')
        e.currentTarget.parentElement.querySelector('.inquiry').classList.add('active')
        if(question.state === '0') {
          e.currentTarget.parentElement.querySelector('.answer-modify').classList.add('active')
        }
      } else {
        let icon = e.currentTarget.querySelector('.icon-arrow-down')
        icon.classList.add('icon-arrow-up')
        icon.classList.remove('icon-arrow-down')
        e.currentTarget.parentElement.querySelector('.inquiry').classList.remove('active')
        e.currentTarget.parentElement.querySelector('.answer-modify').classList.remove('active')
      }
      this.answer = ''
    },

    showDetail(e, question, idx) {
      if(e.currentTarget.querySelector('.icon-arrow-up')) {
        this.getDetail(question, idx)
      }
      if(question.state === '0') {
        e.currentTarget.parentElement.querySelector('.answer-modify').classList.add('active')
      }
    },

    getDetail(question, idx) {
      this.$get(this.$SHOP_QNA +'/'+ question.idx, 'qnaIdx_support', true, result => {
        console.log('get qna detail', result)
        this.detail = result.data
        this.detailTitle = this.detail.title
        this.detailContent = this.detail.content
        this.detailDate = this.detail.answerDate
        this.detailAnswer = this.detail.answer
        this.inquiryImg = this.detail.image
        this.showImg = this.inquiryImg.length !== 0

        let inquiries = document.querySelectorAll('.inquiry')
        inquiries[idx].querySelectorAll('.board-content-title')[0].textContent = this.detailTitle
        inquiries[idx].querySelector('.board-content-text').textContent = this.detailContent
        inquiries[idx].querySelector('.answer-date').textContent = this.detailDate
        inquiries[idx].querySelectorAll('.board-content-text')[1].textContent = this.detailAnswer
        inquiries[idx].scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      }, result => {
        console.log(result)
      })
    }
  },
}
</script>

<style scoped>

.active {
  display: block;
}

</style>
