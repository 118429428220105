import { render, staticRenderFns } from "./dashboard_pickup.vue?vue&type=template&id=2c603a0e&scoped=true&"
import script from "./dashboard_pickup.vue?vue&type=script&lang=js&"
export * from "./dashboard_pickup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c603a0e",
  null
  
)

export default component.exports