<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({name: 'ShopInfo'})"><i class="icon-back"></i></button>
      <p>주소 변경</p>
    </header>
    <div class="auth-form">
      <div class="input-group">
        <div class="input-btn-group full-width">
          <input type="text" class="input-default" placeholder="건물명, 도로명 또는 지번으로 검색" v-model="keyword" @keyup="goSearch($event)">
          <button type="button" class="btn btn-outline-pink" @click="searchAddress()">검색</button>
        </div>
      </div>
      <button type="button" class="btn btn-outline-pink w-100 mt-5" v-show="showFindLocationBtn" @click="$router.push({name: 'AddressChangeStep2'})">현재 위치로 주소 찾기</button>
      <div id="address-wrap" class="address-wrap" v-show="showAddress">
        <div v-for="(item, idx) in result" :key="idx" class="b-line-card">
          <router-link :to="{ name: 'AddressChangeStep2', params: { address: item } }">
            <strong>{{ item.address_name }}</strong>
            <span>도로명</span> {{ item.road_address !== null ? item.road_address.address_name : '' }}
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddressChangeStep1",
  data() {
    return {
      keyword: '',
      result: '',
      address: '',
      roadAddress: '',
      showAddress: false,
      showFindLocationBtn: true,
    }
  },
  methods: {
    searchAddress() {
      if (this.keyword === '') {
        return
      }
      axios.get("https://dapi.kakao.com/v2/local/search/address.json?query="+this.keyword+"&size=30", {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Authorization": "KakaoAK fcf95c30dd23596e1e5d84c0e5ca7f39"
        },
        timeout: 0
      }).then(response => {
        this.result = response.data.documents
        this.showAddress = true
        this.showFindLocationBtn = false
        console.log(this.result)
        if(this.result.length === 0) {
          this.notify('error', '검색 결과가 없습니다.')
          this.showFindLocationBtn = true
        }
      }).catch((e) => {
        console.log(e)
      })
    },

    goSearch(e) {
      if(e.keyCode === 13) {
        e.preventDefault()
        this.searchAddress()
      }
    }
  }
}
</script>

<style scoped>

</style>