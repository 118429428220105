<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({name: 'MypageMain'})"><i class="icon-back"></i></button>
      <p>약관</p>
    </header>
    <div class="mypage-content bg-white">
      <div class="mypage-box" @click="$router.push({ name: 'Use'})">
        <span>이용약관</span>
        <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
      </div>
      <div class="mypage-box" @click="$router.push({name: 'Privacy'})">
        <span>개인정보 처리방침</span>
        <span class="btn-arrow-right" ><i class="icon-arrow-right"></i></span>
      </div>
<!--      <div class="mypage-box" @click="$router.push({name: 'Electronic'})">-->
<!--        <span>전자금융거래 약관</span>-->
<!--        <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
name: "MypageAgreement"
}
</script>

<style scoped>

</style>
