<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'Login' })"><i class="icon-back"></i></button>
      <p>매장코드 찾기</p>
    </header>
    <div class="auth-form d-table">
      <p class="mb-20">
        * 회원님의 명의로 등록된 휴대폰으로 본인확인 후<br />
        &nbsp;&nbsp;&nbsp;휴대폰으로 매장코드를 전송합니다.
      </p>
      <router-link :to="{ name: 'Certification', params: { fromFindCode: 'true' }}" class="btn btn-pink">본인명의 휴대폰으로 인증</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindCode",
  methods: {

  }
}
</script>

<style scoped>

</style>