<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="goBack">
          <i class="icon-back"></i>
      </button>
      <p>주문상세</p>
    </header>
    <div class="dashboard-wrap">
      <div class="content order-content">
        <div class="order-content-box">
          <p class="name">{{ orderDetail.name }}</p>
          <p class="phone">{{ phone | phoneNumber }}</p>
          <p class="address">{{ orderDetail.orderParcel }}</p>
          <p class="address">{{ orderDetail.orderSubAddress }}</p>
          <p class="road-name">
            <span class="address">도로명</span>{{ orderDetail.orderRoad }}
          </p>
        </div>
        <h3>주문내역</h3>
        <div class="order-box">
          <div class="order-box-set" v-for="(product, idx) in products" :key="idx">
            <p class="product-name">{{ product.productName }}</p>
            <span class="product-option">{{ product.productOptionName }}: {{ product.productPriceWithOption | numberWithCommas }}원</span>
            <div v-for="(option, index) in product.options" :key="index">
              <span class="product-option" v-if="option.optionType === '2'">
                {{ option.optionName }} {{  option.optionPrice | numberWithCommas }}원
              </span>
            </div>
            <span class="product-price">{{ product.total | numberWithCommas }}원</span>
          </div>
          <div class="order-box-set">
            <span>상품합계</span>
            <span class="product-price">{{ total | numberWithCommas }}원</span>
          </div>
          <div class="order-box-set">
            <p class="total-payment">
              <span class="payment">결제금액</span>
              <span class="payment payment-price">{{ total | numberWithCommas }}원</span>
            </p>
            <p class="total-payment">
              <span class="payment">결제방법</span>
              <span class="payment payment-method">{{ orderDetail.paymentMethod }}</span>
            </p>
          </div>
          <div class="order-box-set">
            <p class="order-request">주문 요청사항</p>
            <div class="full-width">{{ orderDetail.require }}</div>
          </div>
          <div class="order-box-set">
            <p class="order-request">배달시간</p>
            <input type="text" class="full-width" readonly v-model="orderDetail.deliveryTime">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDetail",
  props: {
    orderDetailIdx: Number,
    backToPickup: String,
  },

  data() {
    return {
      orderDetail: {
        name: '',
        orderParcel: '',
        orderSubAddress: '',
        orderRoad: '',
        paymentMethod: '',
        require: '',
        deliveryTime: '',
      },
      total: '',
      phone: '',
      products: [],
    }
  },

  methods: {
    goBack() {
      if(this.backToPickup === 'true') {
        this.$router.push({ name: 'Dashboard' , params: { backToPickup: 'true' } })
      } else {
        this.$router.push({ name: 'Dashboard' })
      }
    }
  },

  mounted() {
    if(this.orderDetailIdx === undefined) {
      this.$router.push({ name: 'Dashboard' })
      return;
    }
    this.$get(this.$SHOP_ORDER + '/' + this.orderDetailIdx, 'order_orderDetail', true, (result) => {
      console.log('order detail', result.data)
      this.orderDetail = result.data
      this.phone = this.orderDetail.tel
      this.total = this.orderDetail.total | this.numberWithCommas
      this.products = this.orderDetail.orderReceiptProducts

      for(let i=0; i<this.products.length; i++) {
        let product = this.products[i]
        let productPriceWithOption = product.productPrice
        let productOptionName = '기본'
        if(this.products[i].options.length !== 0) {
          for(let j=0; j<this.products[i].options.length; j++) {
            if(this.products[i].options[j].optionType === '0' || this.products[i].options[j].optionType === '1') {
              let option = this.products[i].options[j]
              productOptionName = option.optionName
              productPriceWithOption += option.optionPrice
              product.productOptionName = productOptionName
              product.productPriceWithOption = productPriceWithOption
              break;
            }
          }
        } else {
          product.productOptionName = productOptionName
          product.productPriceWithOption = productPriceWithOption
        }
      }
    }, (result) => {
      console.log(result)
    })
  },

}
</script>

<style scoped>

</style>
