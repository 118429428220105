<template>
  <div class="all-content bg-white">
    <Modal :askConfirmTxt=askConfirmTxt v-if="openModal" @close="openModal = false" @confirmDeleteReview="confirmDeleteReview" :reviewIdx=reviewIdx :listIdx=listIdx></Modal>
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'MypageMain' })"><i class="icon-back"></i></button>
      <p>리뷰관리</p>
    </header>
    <div class="shop-info-wrap">
      <div class="shop-info">
        <p class="shop-name">{{ reviews.shopName }}</p>
        <p class="star-score">
          <span><img :src="starImgAvg"/></span>
          <span class="star-score-text">{{ reviews.ratingAvg }}</span>
        </p>
      </div>
      <div v-for="(review, ListIdx) in reviews.list" :key="review.reviewIdx" class="review-box">
        <div class="shop-review">
          <p class="review-title-set">
            <span>{{ review.userId }}님</span>
            <span class="review-date">{{ review.regDate }}</span>
            <span class="review-star-score"><img :src="'/images/star_score_m_'+ Math.ceil(review.rating * 1) + '.svg'"/></span>
          </p>
          <div @click="showImage(ListIdx)">
            <a role="button" :class="{ 'review-photo-1': review.images.length === 1, 'review-photo-2': review.images.length === 2, 'review-photo-3': review.images.length > 2, 'oddImgs': review.images.length > 2 && review.images.length%2 === 1 }"
               v-for="(img, imgIdx) in review.images" :key="imgIdx">
              <img :src="img.image" alt="리뷰 이미지" @error="hideImg($event)">
            </a>
          </div>
          <p class="review-description" v-html="review.content"></p>
          <p>
            <span class="badge badge-review" v-for="(product, productIdx) in review.orderProducts" :key="productIdx">{{ product.orderProductName }}</span>
          </p>
          <div type="text" v-show="review.answer !== null" class="mr-10 review-content" :class="{ answer: reviewAnswers[ListIdx] !== null }"
                    v-html="reviewAnswers[ListIdx]" @DOMCharacterDataModified="limitTxtLength($event)" placeholder="80자 이하로 입력해주세요."></div>
<!--          <textarea type="text" v-show="review.answer !== null" class="mr-10" :class="{ answer: reviewAnswers[ListIdx] !== null }"-->
<!--                    v-model="reviewAnswers[ListIdx]" disabled maxlength="80"></textarea>-->
          <div class="comment-box">
            <button class="btn btn-outline-pink full-width" v-show="review.answer === null" @click="openWrite($event)">답글 달기</button>
            <div class="full-width modify-btns" v-show="review.answer !== null">
              <button class="btn btn-outline-pink" @click="openModify($event)">답글 수정</button>
              <button class="btn btn-outline-pink" @click="deleteComment(review.reviewIdx, ListIdx)">답글 삭제</button>
            </div>
            <div class="full-width confirm-post" v-show="false">
              <button class="btn btn-outline-pink" @click="cancelPost($event)">취소</button>
              <button class="btn btn-pink" @click="sendComment($event, review.reviewIdx)">확인</button>
            </div>
            <div class="full-width confirm-modify" v-show="false">
              <button class="btn btn-outline-pink" @click="cancelModify($event, ListIdx)">취소</button>
              <button class="btn btn-pink" @click="modifyComment($event, review.reviewIdx)">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    사진 swipe-->
<!--    <div class="photoSwiper">-->
      <div v-if="show">
        <div class="overlay_popup">
          <header class="header-wrap header-wrap-black">
            <!-- 페이징 -->
            <div id="photoSwiperPagination" class="swiper-pagination"></div>
            <button type="button" class="btn btn-close" @click="close"><i class="icon-close"></i></button>
          </header>
          <div class="photo-full-popup-wrap">
            <div class="photo-full-popup">
              <!-- 사진 스와이프  - 사진 최대 5장 -->
              <div id="photoSwiperContainer" class="swiper-container">
<!--                <div class="swiper-wrapper">-->
<!--                  <div class="swiper-slide"><img src="https://devfile.jangboda.com/review/20210216/review_45b61f4c84dd4692ac660a4a7d1a5a6e.jpg" alt="팝업 이미지"></div>-->
<!--                </div>-->
                <div class="swiper-wrapper">
                  <div v-for="(image, imageIdx) in images" :key="imageIdx" class="swiper-slide"><img :src="image" alt="팝업 이미지"/></div>
                </div>
                <!-- 스와이프 좌우 버튼 -->
                <div id="photoSwiperPrev" class="swiper-button-prev"></div>
                <div id="photoSwiperNext" class="swiper-button-next"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--    </div>-->

  </div>
</template>

<script>

export default {

  name: "Review",
  data() {
    return {
      reviews: {
        shopName: '',
        list: [],
      },
      show: false,
      images: [],
      swiperObj: null,
      reviewAnswers: [],
      starImgAvg: '',
      askConfirmTxt: '답글을 삭제 하시겠습니까?',
      openModal: false,
      reviewIdx: 0,
      listIdx: 0,
    }
  },

  mounted() {
    this.getReview()
  },

  methods: {
    limitTxtLength(e) {
      let input = e.currentTarget

      let settings = {
        maxLen: 80,
      }

      let keys = {
        'backspace': 8,
        'shift': 16,
        'ctrl': 17,
        'alt': 18,
        'delete': 46,
        'leftArrow': 37,
        'upArrow': 38,
        'rightArrow': 39,
        'downArrow': 40,
      }

      let utils = {
        special: {},
        navigational: {},
        isSpecial(e) {
          return typeof this.special[e.keyCode] !== 'undefined';
        },
        isNavigational(e) {
          return typeof this.navigational[e.keyCode] !== 'undefined';
        }
      }

      utils.special[keys['backspace']] = true;
      utils.special[keys['shift']] = true;
      utils.special[keys['ctrl']] = true;
      utils.special[keys['alt']] = true;
      utils.special[keys['delete']] = true;

      utils.navigational[keys['upArrow']] = true;
      utils.navigational[keys['downArrow']] = true;
      utils.navigational[keys['leftArrow']] = true;
      utils.navigational[keys['rightArrow']] = true;

      input.addEventListener('keydown', function(event) {
        let len = event.target.innerText.trim().length;
        let hasSelection = false;
        let selection = window.getSelection();
        let isSpecial = utils.isSpecial(event);
        let isNavigational = utils.isNavigational(event);

        if (selection) {
          hasSelection = !!selection.toString();
        }

        if (isSpecial || isNavigational) {
          return true;
        }

        if (len >= settings.maxLen && !hasSelection) {
          event.preventDefault();
          return false;
        }
      });
    },

    confirmDeleteReview(reviewIdx, listIdx) {
      this.$axios_delete(this.$SHOP_REVIEW +'/'+ reviewIdx, 'reviewsDelete_review',  true,(result) => {
        console.log('delete', result)
        this.notify('success', '답글을 삭제했습니다.')
        this.getReview()
      }, (result) => {
        console.log(result)
        this.notify('success', result.error)
      })
      document.querySelectorAll('.review-box .review-content')[listIdx].innerHTML = ''
      document.querySelectorAll('.review-box .review-content')[listIdx].style.display = 'none'
      document.querySelectorAll('.confirm-modify')[listIdx].style.display = 'none'
      document.querySelectorAll('.modify-btns')[listIdx].style.display = 'none'
      this.openModal = false
    },

    deleteComment(reviewIdx, listIdx) {
      this.openModal = true
      this.reviewIdx = reviewIdx
      this.listIdx = listIdx
    },

    cancelPost(e) {
      let reviewContent = e.currentTarget.parentElement.parentElement.parentElement.querySelector('.review-content')
      reviewContent.style.display = 'none'
      reviewContent.innerHTML = ''
      e.currentTarget.parentElement.style.display = 'none'
      e.currentTarget.parentElement.parentElement.querySelector('.comment-box > button').style.display = 'block'
    },

    async cancelModify(e, ListIdx) {
      await this.getReview()

      let reviewContent = e.currentTarget.parentElement.parentElement.parentElement.querySelector('.review-content')
      reviewContent.classList.add('answer')
      reviewContent.setAttribute('contentEditable', false)
      reviewContent.style.backgroundColor = '#f9f9f9'
      reviewContent.style.border = 'none'
      e.currentTarget.parentElement.style.display = 'none'
      e.currentTarget.parentElement.parentElement.querySelector('.modify-btns').style.display = 'flex'
      reviewContent.innerHTML = this.reviews.list[ListIdx].answer.answer
    },

    openWrite(e) {
      let writeEle = e.currentTarget.parentElement.parentElement.querySelector('.review-content')
      writeEle.setAttribute('contentEditable', true)
      writeEle.style.display = 'block'
      writeEle.style.border = '1px solid #e9e9e9'
      writeEle.style.backgroundColor = '#fff'
      writeEle.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
      e.currentTarget.style.display = 'none'
      e.currentTarget.parentElement.querySelector('.confirm-post').style.display = 'flex'
    },

    openModify(e) {
      let reviewContent = e.currentTarget.parentElement.parentElement.parentElement.querySelector('.review-content')
      reviewContent.setAttribute('contentEditable', true)
      reviewContent.style.border = '1px solid #e9e9e9'
      reviewContent.style.backgroundColor = '#fff'
      e.currentTarget.parentElement.parentElement.querySelector('.confirm-modify').style.display = 'flex'
      e.currentTarget.parentElement.style.display = 'none'
    },

    modifyComment(e, reviewIdx) {
      let comment = e.currentTarget.parentElement.parentElement.parentElement.querySelector('.review-content').innerHTML
      if(comment === '') {
        this.notify('error', '답글을 입력해주세요.')
        return
      }

      let formData = new FormData
      formData.append('answer', comment)

      this.$put(this.$SHOP_REVIEW +'/'+ reviewIdx, 'reviewsPut_review', formData, true,(result) => {
        console.log('put review', result.data)
        this.notify('success', '답글을 수정하였습니다.')
        e.path[1].style.display = 'none'
        let reviewContent = e.target.parentElement.parentElement.parentElement.querySelector('.review-content')
        reviewContent.style.border = 'none'
        reviewContent.style.backgroundColor = '#f9f9f9'
        reviewContent.setAttribute('contentEditable', false)
        reviewContent.style.backgroundColor = '#f9f9f9'
        e.target.parentElement.parentElement.querySelector('.modify-btns').style.display = 'flex'
      }, (result) => {
        console.log(result)
      })
    },

    sendComment(e, reviewIdx) {
      let reviewContent = e.currentTarget.parentElement.parentElement.parentElement.querySelector('.review-content')
      let comment = reviewContent.innerHTML
      if(comment === '') {
        this.notify('error', '답글을 입력해주세요.')
        return
      }

      let formData = new FormData
      formData.append('answer', comment)

      this.$post(this.$SHOP_REVIEW +'/'+ reviewIdx, 'reviewsPost_review', formData, true,(result) => {
        console.log('post review', result.data)
        this.notify('success', '답글을 달았습니다.')
        this.getReview()
      }, (result) => {
        console.log(result)
      })

      reviewContent.setAttribute('contentEditable', false)
      reviewContent.style.backgroundColor = '#f9f9f9'
      reviewContent.style.border = 'none'
      e.currentTarget.parentElement.style.display = 'none'
    },

    getReview() {
      this.$get(this.$SHOP_REVIEWS, 'reviews_review', true, (result) => {
        console.log('reviews', result.data)
        this.reviews = result.data
        this.reviewAnswers = []
        this.reviews.list.forEach(answer => {
          if(answer.answer !== null) {
            this.reviewAnswers.push(answer.answer.answer)
          } else {
            this.reviewAnswers.push(answer.answer)
          }
        })
        this.starImgAvg = '/images/star_score_'+Math.ceil(this.reviews.ratingAvg * 1)+'.svg'
      }, (result) => {
        console.log(result)
      })
    },

    hideImg(e) {
      e.target.style.display = 'none'
    },

    close() {
      this.show = false
    },

    showImage(idx) {
      if(this.reviews.list[idx].images.length > 0) {
        this.images = this.reviews.list[idx].images.map(img => img.image)
        console.log('this.images', this.images)
        setTimeout(() => {
          this.initSwiper()
        }, 500)
        this.show = false
      }
    },

    initSwiper() {
      this.swiperObj = new window.Swiper('.swiper-container', {
        slidesPerView: "auto",
        speed: 400,
        autoplay: {
          delay : 5000,
          disableOnInteraction : false
        },
        pagination: {
          el: "#photoSwiperPagination",
          type: "fraction",
          renderFraction: function (currentClass, totalClass) {
            return '<div class="fr swiper-custom-pagination br-10 mr-10"><span class="' + currentClass + '"></span>' +
                '<span> / </span>' +
                '<span class="' + totalClass + '"></span></div>';
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    }
  }
}
</script>

<style scoped>
.overlay_popup {
  z-index: 1199;
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
</style>
