<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'MypageMain' })"><i class="icon-back"></i></button>
      <p>공지사항</p>
    </header>
<!--    공지사항 없을 때-->
    <noContent v-show="fixedNotice.length + notice.length === 0" title="공지사항이 없습니다!"></noContent>
<!--    공지사항 있을 때-->
    <div class="mypage-content bg-white notice-box" v-show="fixedNotice.length + notice.length > 0">
      <div v-for="item in fixedNotice" :key="item.idx">
        <div class="mypage-box" @click="openNotice($event)">
          <span class="badge board-badge">공지</span><span class="board-title">{{ item.title }}</span>
          <span class="btn-arrow-right"><i class="arrow icon-arrow-up"></i></span>
          <p class="board-date">{{ item.date }}</p>
        </div>
        <div class="mypage-box board-content">
          <p class="board-content-title">{{ item.title }}</p>
          <p class="board-content-text" v-html="item.content"></p>
        </div>
      </div>
      <div v-for="item in notice" :key="item.idx">
        <div class="mypage-box" @click="openNotice($event)">
          <span class="board-title">{{ item.title }}</span>
          <span class="btn-arrow-right"><i class="arrow icon-arrow-up"></i></span>
          <p class="board-date">{{ item.date }}</p>
        </div>
        <div class="mypage-box board-content">
          <p class="board-content-title">{{ item.title }}</p>
          <p class="board-content-text" v-html="item.content"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notice",

  data() {
    return {
      fixedNotice: [],
      notice: [],
    }
  },
  mounted() {
    this.$get(this.$SHOP_NOTICE, 'notice_notice', true, (result) => {
      console.log('get shop notice', result)
      this.fixedNotice = []
      this.notice = []
      for (let i=0; i<result.data.length; i++) {
        let item = result.data[i]
        if (item.noticeAt === '1') {
          this.fixedNotice.push(item)
        } else {
          this.notice.push(item)
        }
      }
    }, (result) => {
      console.log(result)
    })
  },

  methods: {
    openNotice(e) {
      let noticeContent = e.currentTarget.parentElement.querySelector('.board-content')
      let icon = e.currentTarget.querySelector('.arrow')

      if(noticeContent.style.display === 'block'){
        noticeContent.style.display = 'none'
      } else {
        noticeContent.style.display = 'block'
      }

      if(icon.classList.contains('icon-arrow-up')) {
        icon.classList.add('icon-arrow-down')
        icon.classList.remove('icon-arrow-up')
      } else {
        icon.classList.add('icon-arrow-up')
        icon.classList.remove('icon-arrow-down')
      }
    }
  },
}
</script>

<style scoped>

</style>
