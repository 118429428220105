<template>
  <header class="header-wrap">
    <button type="button" class="btn btn-lnb" @click="$emit('openLnb')">
      <img src="/images/jangboda_shop_btn_lnb.svg" />
    </button>
    <p>{{ title }}</p>
    <button type="button" class="btn btn-alram" @click="$emit('openAlarm')">
      <img v-show="noti === false" src="/images/jangboda_shop_btn_alram.svg"/>
      <img v-show="noti === true" src="/images/jangboda_shop_btn_alram_check.svg"/>
    </button>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      noti: false
    }
  },
  props: ['title'],
  methods: {
    getData() {
      this.$get(this.$SHOP_CHECK, 'check_header', true, (result) => {
        console.log('get shop check', result)
        this.noti = result.data.notification
      }, (result) => {
        console.log(result)
      })
    }
  },

  mounted() {
    this.getData()
    this.$root.$on('refreshNoti', () => {
      console.log('refreshNoti on')
      this.getData()
    })
  },
  beforeDestroy() {
    this.$root.$off('refreshNoti')
  },


  watch: {
    '$parent.isSalesListActive' : {
      handler() {
        this.getData()
      }
    },
    '$parent.isOrderActive' : {
      handler() {
        this.getData()
      }
    }

  }

}
</script>

<style scoped>

</style>
