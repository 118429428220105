<template>
  <div class="dashboard-wrap">
    <div class="list-none-wrap">
      <div class="list-none">
        <p>
          <img src="/images/jangboda_shop_character_sad.svg" alt="캐릭터" />
        </p>
        <p class="list-none-title">{{ title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoContent",
  props: {
    title: String
  }
}
</script>

<style scoped>

</style>