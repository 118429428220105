<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'MypageSetup' })"><i class="icon-back"></i></button>
      <p>휴대폰번호 변경 </p>
    </header>
    <div class="auth-form d-table">
      <p class="mb-20">
        * 회원님의 명의로 등록된 휴대폰으로 본인확인 후<br />
        &nbsp;&nbsp;&nbsp;휴대폰으로 매장코드를 전송합니다.
      </p>
      <button type="button" class="btn btn-pink" @click="$router.push({ name: 'Certification', params: { fromMypage : 'true' }})">본인명의 휴대폰으로 인증</button>
    </div>
  </div>

</template>

<script>
export default {
  name: "PhoneNumberChange"
}
</script>

<style scoped>

</style>