<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn btn-back" @click="$router.push({ name: 'Certification'})"><i class="icon-back"></i></button>
      <p>매장코드 찾기</p>
    </header>
    <div class="auth-form d-table">
      <p class="mb-20 result">
        회원님의 매장코드는<br />
        <strong class="shopCode">{{ $route.params.shopCode }}</strong> 입니다.
      </p>
      <button type="button" class="btn btn-pink" @click="$router.push({ name: 'Login'})">로그인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindCodeResult"
}
</script>

<style scoped>

</style>