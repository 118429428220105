<template>
  <div class="intro-login-wrap">
    <div class="intro-login-top">
      <img src="/images/jangboda_shop_bi.svg"  alt=""/>
    </div>
    <div class="intro-login-bottom">
        <router-link :to="{ name: routerLink }" class="btn btn-login">로그인</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroLogin",
  data() {
    return {
      routerLink: ''
    }
  },

  mounted() {
    this.routerLink = 'Login'
  }
}
</script>

<style scoped>

</style>